import {STORED_CLIENT_SET_DATA, STORED_CLIENT_SET_TRIGGERS, STORED_CLIENT_SET_VALID} from "../actionTypes";
import store from "../store";

const storageKey = 'storedClient';

function storedClientSetValid(isValid){
    return {
        type: STORED_CLIENT_SET_VALID,
        payload: isValid
    }
}

function storedClientSetData(data){
    return {
        type: STORED_CLIENT_SET_DATA,
        payload: data
    }
}

function storedClientSetTriggers(triggers){
    return {
        type: STORED_CLIENT_SET_TRIGGERS,
        payload: triggers
    }
}

export async function storedClientIsValid(){

    const StoredClientState = store.getState().StoredClient;
    const FormRenderState = store.getState().FormRender;

    if(StoredClientState.valid === null){
        let isValid = false;
        if(navigator.cookieEnabled) {
            const storedValue = window.localStorage.getItem(storageKey);
    
            if(storedValue){
                const currentTime = new Date() / 1000;
                const data = JSON.parse(storedValue);
    
                // Check the stored public key against the form config
                if(!data.data.paymentProvider || (data.data.paymentType !== 'auth' && data.data.paymentProvider.publicKey !== FormRenderState.paymentProviders[data.data.paymentProvider.type].publicKey)){
                    isValid = false;
                } else if(!data.data.paymentProvider || (data.data.paymentType === 'auth' && (data.data.paymentProvider.apiLoginID !== FormRenderState.paymentProviders[data.data.paymentProvider.type].apiLoginID || data.data.paymentProvider.clientKey !== FormRenderState.paymentProviders[data.data.paymentProvider.type].clientKey))){
                    isValid = false;
                }else{
                    isValid = currentTime < data.expire;
                    await store.dispatch(storedClientSetData(data.data));
                }
            } else {
                isValid = false;
            }
        }
        await store.dispatch(storedClientSetValid(isValid));
    }
    return StoredClientState.valid;
}

export function storedClientStore(data = {}, ttl = 2592000){
    storedClientStoreWithoutReset(data, ttl);
    storedClientReset();
}

export function storedClientStoreWithoutReset(data = {}, ttl = 2592000){
    if(navigator.cookieEnabled) {
        window.localStorage.setItem(storageKey, JSON.stringify({
            data,
            expire: (new Date().getTime() / 1000) + ttl
        }));
    }
}

export function storedClientClear(){
    storedClientClearWithoutReset();
    storedClientReset();
}

export function storedClientClearWithoutReset(){
    if(navigator.cookieEnabled) {
        window.localStorage.removeItem(storageKey);
    }
}

export async function storedClientReset(){
    await store.dispatch(storedClientSetValid(null));
    await storedClientIsValid();
    storedClientProcessTriggers();
}

export function storedClientProcessTriggers(){
    store.getState().StoredClient.triggers.forEach(trigger => {
        if(typeof trigger === 'function'){
            trigger();
        }
    })
}

export function storedClientRegisterTrigger(trigger){
    const triggers = [...store.getState().StoredClient.triggers];
    triggers.push(trigger);
    store.dispatch(storedClientSetTriggers(triggers));
    return triggers.length - 1;
}

export function storedClientDeregisterTrigger(index){
    const triggers = [...store.getState().StoredClient.triggers];
    triggers[index] = null;
    store.dispatch(storedClientSetTriggers(triggers));
}

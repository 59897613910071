import React from "react";
import { BootstrappedCheckbox as Checkbox } from "../BaseFields/Checkbox";
import { BootstrappedSelect as Select } from '../BaseFields/Select';
import { getParameterByName, isTruthy } from "../../lib";
import { setFormValue } from "../../Redux/actions/FormValueActions";
import { connect } from "react-redux";
import BaseField, { BaseFieldStateToProps, BaseFieldActionsToProps } from "../BaseFields/BaseField";

class CoverFeeOption extends BaseField {

    state = {
        checked: false
    }

    componentDidMount() {
        const options = this.getOptionsArray();
        window.rkd.form.on('change', (data) => {
            if (data.name === 'donationArrayBtn' || data.name === options.name) {
                this.addTransactionFee();
            }
        });

        if (getParameterByName('coverfee')) {
            const checked = isTruthy(getParameterByName('coverfee'));
            this.setState({
                checked
            }, () => {
                this.props.setFormValue(options.name, checked);
                this.setFieldAndTriggerEvent(options.name, checked);
            });
        } else {
            if (options.default === 'Yes') {
                this.setState({
                    checked: true
                }, () => {
                    this.props.setFormValue(options.name, true);
                    this.setFieldAndTriggerEvent(options.name, true);
                    this.addTransactionFee();
                });
            }
        }

        const { FormValueState, setFormValue } = this.props;
        let percent = options.percentage;
        if (typeof FormValueState.donationArrayBtn !== "undefined" && FormValueState.donationArrayBtn !== "" && percent !== "" && percent !== 0) {
            setFormValue('calculatedCoverFee', (FormValueState.donationArrayBtn * percent / 100).toFixed(2))
        }
    }

    componentWillReceiveProps(nextProps) {
        // Below code is implemented for calculate the coverFee amount for the donation amount.
        const { FormValueState, setFormValue } = this.props;
        const options = this.getOptionsArray();
        const oldAmount = nextProps.FormValueState.giftAmount;
        let percent = options.percentage;

        if(FormValueState.donationArrayBtn !== nextProps.FormValueState.donationArrayBtn){
            setFormValue('calculatedCoverFee', (parseFloat((1 + (percent / 100)) * nextProps.FormValueState.donationArrayBtn) - parseFloat(oldAmount)).toFixed(2));
        }
        // Ends Here.
    }

    addTransactionFee() {
        const { FormValueState, setFormValue } = this.props;
        const options = this.getOptionsArray();
        if (FormValueState.donationArrayBtn) {
            if (isTruthy(FormValueState[options.name])) {
                let percent = options.percentage;
                let feeAmount = ((percent / 100) * FormValueState.donationArrayBtn);
                let calculatedGiftAmount = ((1 + (percent / 100)) * FormValueState.donationArrayBtn).toFixed(2);
                setFormValue('giftAmount', calculatedGiftAmount);
                setFormValue('feeAdded', (parseFloat(feeAmount)).toFixed(2));
            } else {
                setFormValue('giftAmount', FormValueState.donationArrayBtn);
                setFormValue('feeAdded', 0.00);
            }
        }
    }

    renderSelect() {
        const selection = [{
            value: '0',
            label: 'No'
        }, {
            value: '1',
            label: 'Yes'
        }];
        return (<Select value={this.state.checked ? '1' : '0'} {...this.props} optionItems={selection} handleCheck={this.handleCheck} />);
    }

    handleCheck = () => {
        this.setState({ checked: this.state.checked ? false : true });
    }

    render() {
        const options = this.getOptionsArray();
        if (options.displayType === 'Dropdown') {
            return this.renderSelect();
        } else {
            return <Checkbox {...this.props} checked={this.state.checked} handleCheck={this.handleCheck} />
        }
    }
}

const mapStateToProps = (state) => ({
    ...BaseFieldStateToProps(state),
    FormValueState: state.FormValue
})

const mapActionsToProps = {
    ...BaseFieldActionsToProps,
    setFormValue
}

export default connect(mapStateToProps, mapActionsToProps)(CoverFeeOption);

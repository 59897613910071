import { Component } from "react";
import { forceRunTriggers } from "../Redux/actions/TriggerActions";
import { connect } from 'react-redux'

/*
This runs the triggers when a modal is opened as a bootstrap modals content is not mounted when it's not shown
 */
class FormModalTriggers extends Component {

    componentDidMount() {
        setTimeout(() => {
            forceRunTriggers()
        }, 30);
    }

    render() {
        return null;
    }

}

export default connect()(FormModalTriggers);
import React from "react";
import { getParameterByName, renderString } from "../../lib";

import {
    FormGroup,
    FormLabel,
    FormControl
} from "react-bootstrap";
import BaseField, { BaseFieldActionsToProps, BaseFieldStateToProps } from "./BaseField";
import { connect } from 'react-redux'

class Textbox extends BaseField {

    cursor;

    componentDidMount() {

        let options = this.getOptionsArray();
        if (typeof options.custom !== "undefined" && options.custom) {
            let fieldName = `dmc_custom_field_${options.name}`;
            setTimeout(() => {
                let tmpElement = document.querySelector('input[name="' + fieldName + '"]');
                this.triggerOnFieldLoadInForm(tmpElement.value);
            }, 1111);
            setTimeout(() => {
                if (getParameterByName(options.name) !== null || getParameterByName(fieldName) !== null) {
                    this.triggerOnFieldLoadInForm(getParameterByName(options.name));
                }
            }, 1222);

        } else {

            // This code will set the custom field value by default blank,
            // So it will pass for transaction without interact with custom field
            this.triggerOnFieldLoadInForm("");

        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.target) {
            this.state.target.selectionStart = this.cursor;
            this.state.target.selectionEnd = this.cursor;
        }
    }

    render() {
        const { FormValueState } = this.props;

        const errorMessage = this.getRenderedValidationMessage();
        let options = this.getOptionsArray();
        let fieldName = options.name;
        if (typeof this.props.customType !== 'undefined') {
            fieldName = `dmc_custom_field_${options.name}`;
        }
        const props = {
            type: this.props.type || 'text',
            onBlur: (event) => this.onChange(event),
            defaultValue: this.props.value || renderString(FormValueState[fieldName], this.props.FormRenderState.variables) || options.customFieldValue,
            name: renderString(fieldName, this.props.FormRenderState.variables),
            placeholder: renderString(options.placeholder, this.props.FormRenderState.variables),
            isInvalid: errorMessage !== ''
        };

        if (typeof this.fieldType !== 'undefined') {
            props.as = this.fieldType;
        }

        const randomNumber = Math.abs(Math.random() * Number.MAX_SAFE_INTEGER).toFixed(0);

        return (
            <div className={`${this.getTemplateClasses()} donation-form-textbox ${typeof options.isHidden !== "undefined" && options.isHidden === "No" ? "d-none" : ''}`} style={this.getGeneratedStyles()}>
                <FormGroup>
                    <FormLabel htmlFor={`textbox-${randomNumber}`} className={typeof options.validators !== 'undefined' ? options.validators.required ? 'required required-field' : '' : ''}>{renderString(options.label, this.props.FormRenderState.variables)}</FormLabel>
                    <FormControl
                        id={`textbox-${randomNumber}`}
                        {...props}
                        onChange={(event) => {
                            this.cursor = event.target.selectionStart;
                            this.setState({
                                target: event.target
                            });
                            if (props.onChange) {
                                props.onChange(event);
                            }
                        }}
                    />
                    <div style={{ color: '#F00' }} className="donation-form-field-error">{errorMessage}</div>
                </FormGroup>
            </div>
        );
    }

}

export const TextboxStateToProps = (state) => ({
    ...BaseFieldStateToProps(state),
    FormRenderState: state.FormRender,
    FormValueState: state.FormValue
})

export const TextboxActionsToProps = {
    ...BaseFieldActionsToProps
}


export const BootstrappedTextbox = connect(TextboxStateToProps, TextboxActionsToProps)(Textbox);
export default Textbox;

import React, {Component} from "react";
import {BootstrappedSelect as Select} from '../BaseFields/Select';
import {connect} from "react-redux";

class SelectField extends Component{

    render(){
        const options = {};
        this.props.options.forEach((option) => {
            options[option.field] = option.value || option.values || option.default;
        });

        const selection = options.options.map((value, index) => {
            return {
                value: value.value,
                label: value.label
            }
        });

        return (
            <Select {...this.props}  optionItems={selection} />
        );
    }

}

export default connect()(SelectField);

import React from "react";
import BaseField, { BaseFieldActionsToProps, BaseFieldStateToProps } from "../BaseFields/BaseField";
import { isFalsy, renderString } from "../../lib";
import { connect } from "react-redux";
import { FormGroup, FormLabel, FormCheck, FormControl } from "react-bootstrap";

class TributeNotificationType extends BaseField {

    componentDidMount() {
        const options = this.getOptionsArray();
        const { FormValueState } = this.props;

        if(typeof FormValueState.tribute !== 'undefined')
        {
            if (FormValueState.tribute && FormValueState.tributeNotificationType === 'email') {
                this.setFieldAndTriggerEvent(options.name, 'email');
            } else if (FormValueState.tribute && FormValueState.tributeNotificationType === 'mail') {
                this.setFieldAndTriggerEvent(options.name, 'mail');
            } else if (FormValueState.tribute && FormValueState.tributeNotificationType === 'no_none') {
                this.setFieldAndTriggerEvent(options.name, 'no_none');
            } else {
                this.setFieldAndTriggerEvent(options.name, 'none');
            }
        }
    }

    componentDidUpdate() {
        const options = this.getOptionsArray();
        const { FormValueState } = this.props;
        if(!FormValueState.tribute)
        {
            this.setFieldAndTriggerEvent(options.name, 'none');
        }
        else
        {
            if(FormValueState.tributeNotificationType === 'none')
            {
                if (!isFalsy(options.showEmailOption) && options.preselected === 'Email') {
                    this.setFieldAndTriggerEvent(options.name, 'email');
                } else if (!isFalsy(options.showMailOption) && options.preselected === 'Mail') {
                    this.setFieldAndTriggerEvent(options.name, 'mail');
                } else if (typeof options.showNoOption !== 'undefined' && !isFalsy(options.showNoOption) && options.preselected === 'No') {
                    this.setFieldAndTriggerEvent(options.name, 'no_none');
                } else {
                    this.setFieldAndTriggerEvent(options.name, 'none');
                }
            }
            else
            {
                this.setFieldAndTriggerEvent(options.name, FormValueState.tributeNotificationType);
            }
        }
    }

    renderButton(FormValueState, options) {
        return (<>
            {typeof options.showNoOption !== 'undefined' && !isFalsy(options.showNoOption) && <button
                className={`btn ${FormValueState[options.name] === 'no_none' ? 'btn-success' : 'btn-secondary'}`}
                style={{ marginRight: 10 }}
                onClick={(e) => {
                    e.preventDefault();
                    this.setFieldAndTriggerEvent(options.name, 'no_none')}}
            >{options.noText}</button>}
            {!isFalsy(options.showEmailOption) && <button
                className={`btn ${FormValueState[options.name] === 'email' ? 'btn-success' : 'btn-secondary'}`}
                style={{ marginRight: 10 }}
                onClick={(e) => {
                    e.preventDefault();
                    this.setFieldAndTriggerEvent(options.name, 'email')}}
            >{options.emailText}</button>}
            {!isFalsy(options.showMailOption) && <button
                className={`btn ${FormValueState[options.name] === 'mail' ? 'btn-success' : 'btn-secondary'}`}
                onClick={(e) => {
                    e.preventDefault();
                    this.setFieldAndTriggerEvent(options.name, 'mail')}}
            >{options.mailText}</button>}
        </>);
    }

    renderCheckBox(FormRenderState, options, type) {
        return (<>
            <FormGroup>
                {!isFalsy(options.showNoOption) &&
                    <>
                        <FormCheck
                            type={type}
                            id={`${type}_none`}
                            inline
                            onChange={() => this.setFieldAndTriggerEvent(options.name, 'no_none')}
                            className={FormRenderState.tributeNotificationType === 'no_none' ? "checkbox-checked" : ""}
                            checked={FormRenderState.tributeNotificationType === 'no_none' ? true : false}
                            name={renderString(options.noText, FormRenderState.variables)}
                            placeholder={renderString(options.placeholder, FormRenderState.variables)}
                            style={{ marginRight: 5 }}
                        />
                        <FormLabel htmlFor={`${type}_none`} style={{ marginRight: 10 }}>{renderString(options.noText, FormRenderState.variables)}</FormLabel>
                    </>
                }
                {!isFalsy(options.showEmailOption) &&
                    <>
                        <FormCheck
                            type={type}
                            id={`${type}_email`}
                            inline
                            onChange={() => this.setFieldAndTriggerEvent(options.name, 'email')}
                            className={FormRenderState.tributeNotificationType === 'email' ? "checkbox-checked" : ""}
                            checked={FormRenderState.tributeNotificationType === 'email' ? true : false}
                            name={renderString(options.emailText, FormRenderState.variables)}
                            placeholder={renderString(options.placeholder, FormRenderState.variables)}
                            style={{ marginRight: 5 }}
                        />
                        <FormLabel htmlFor={`${type}_email`} style={{ marginRight: 10 }}>{renderString(options.emailText, FormRenderState.variables)}</FormLabel>
                    </>
                }
                {!isFalsy(options.showMailOption) &&
                    <>
                        <FormCheck
                            type={type}
                            id={`${type}_mail`}
                            inline
                            onChange={() => this.setFieldAndTriggerEvent(options.name, 'mail')}
                            className={FormRenderState.tributeNotificationType === 'mail' ? "checkbox-checked" : ""}
                            checked={FormRenderState.tributeNotificationType === 'mail' ? true : false}
                            name={renderString(options.mailText, FormRenderState.variables)}
                            placeholder={renderString(options.placeholder, FormRenderState.variables)}
                            style={{ marginRight: 5 }}
                        />
                        <FormLabel htmlFor={`${type}_mail`} style={{ marginRight: 10 }}>{renderString(options.mailText, FormRenderState.variables)}</FormLabel>
                    </>
                }
            </FormGroup>
        </>);
    }

    renderSelection(FormRenderState, options) {

        let optionItems = [];
        if (!isFalsy(options.showNoOption)) {
            optionItems.push({
                value: 'no_none',
                label: options.noText
            })
        }
        if (!isFalsy(options.showEmailOption)) {
            optionItems.push({
                value: 'email',
                label: options.emailText
            })
        }
        if (!isFalsy(options.showMailOption)) {
            optionItems.push({
                value: 'mail',
                label: options.mailText
            })
        }

        return (<>
            <FormGroup>
                <FormControl
                    id={`form-control-${options.name}`}
                    as="select"
                    onChange={(event) => {
                        this.setFieldAndTriggerEvent(options.name, event.target.value)
                    }}
                    name={renderString(options.name, FormRenderState.variables)}
                    value={renderString(FormRenderState[options.name], FormRenderState.variables)}
                    placeholder={renderString(options.placeholder, FormRenderState.variables)}
                >
                    {optionItems.map((option, index) => (
                        <option
                            key={index}
                            value={renderString(option.value, FormRenderState.variables)}
                        >{renderString(option.label, FormRenderState.variables)}</option>
                    ))}
                </FormControl>
            </FormGroup>
        </>);
    }

    render() {
        const { FormValueState } = this.props;
        const options = this.getOptionsArray();
        let renderComponent = '';
        if (options.displayType === 'Dropdown') {
            renderComponent = this.renderSelection(FormValueState, options)
        } else if (options.displayType === 'Checkbox' || options.displayType === 'Radio') {
            renderComponent = this.renderCheckBox(FormValueState, options, options.displayType === 'Checkbox' ? 'checkbox' : 'radio');
        } else {
            renderComponent = this.renderButton(FormValueState, options);
        }
        return (
            <div className={this.getTemplateClasses()} style={this.getGeneratedStyles()}>
                <div className='row'>
                    <div className='col-12'>
                        {options.label}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 text-center'>
                        {renderComponent}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    ...BaseFieldStateToProps(state),
    FormValueState: state.FormValue,
    FormRenderState: state.FormRender
})

const mapActionsToProps = {
    ...BaseFieldActionsToProps
}

export default connect(mapStateToProps, mapActionsToProps)(TributeNotificationType);
import { FORM_VALUE_SET, FORM_SUBMIT_BUTTON_TEXT } from "../actionTypes";

const initialState = {
    customFields: [],
    giftArrayInfo: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FORM_VALUE_SET:
            const newState = { ...state };
            if (action.payload.key === "customFields") {
                newState[action.payload.key] = state.customFields.concat(typeof action.payload.value !== "undefined" ? action.payload.value : []);
            } else {
                newState[action.payload.key] = action.payload.value;
            }
            return newState;
        case FORM_SUBMIT_BUTTON_TEXT:
            let newStateVal = { ...state };
            newStateVal['giftArrayInfo'] = action.payload.key;
            return newStateVal;
        default:
            return state;
    }
}

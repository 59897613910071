import React, { Component } from 'react';
import './App.css';
import { Style } from "react-style-tag";
import ScriptTag from 'react-script-tag';
import MainView from "./Views/MainView";

import 'bootstrap/dist/css/bootstrap.min.css';
import { renderString } from "./lib";

import { connect } from 'react-redux';
import { initGlobalSettingsSocket } from "./Redux/actions/GlobalSettingsActions";

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            receiptData: {}
        };
    }

    async componentDidMount() {
        await initGlobalSettingsSocket();
        if(!navigator.cookieEnabled) {
            console.log("FB - Cookies are blocked. We noticed your browser doesn’t allow cookies.")
        }

        window.receiptData = {};
    }

    componentWillReceiveProps(nextProps) {
        if(JSON.stringify(this.props.ReceiptState) !== JSON.stringify(nextProps.ReceiptState)) {
            this.setState({ receiptData: nextProps.ReceiptState });
        }
    }

    getThemePath = () => {
        if (this.props.FormRenderState.theme) {
            switch (this.props.FormRenderState.theme.toLowerCase()) {
                case 'cerulean':
                    return '/themes/Cerulean.css';
                case 'cosmo':
                    return '/themes/Cosmo.css';
                case 'cyborg':
                    return '/themes/Cyborg.css';
                case 'darkly':
                    return '/themes/Darkly.css';
                case 'flatly':
                    return '/themes/Flatly.css';
                case 'journal':
                    return '/themes/Journal.css';
                case 'litera':
                    return '/themes/Litera.css';
                case 'lumen':
                    return '/themes/Lumen.css';
                case 'lux':
                    return '/themes/Lux.css';
                case 'materia':
                    return '/themes/Materia.css';
                case 'minty':
                    return '/themes/Minty.css';
                case 'pulse':
                    return '/themes/Pulse.css';
                case 'sandstone':
                    return '/themes/Sandstone.css';
                case 'simplex':
                    return '/themes/Simplex.css';
                case 'sketchy':
                    return '/themes/Sketchy.css';
                case 'slate':
                    return '/themes/Slate.css';
                case 'solar':
                    return '/themes/Solar.css';
                case 'spacelab':
                    return '/themes/Spacelab.css';
                case 'superhero':
                    return '/themes/Superhero.css';
                case 'united':
                    return '/themes/United.css';
                case 'yeti':
                    return '/themes/Yeti.css';
                default:
                    return '';
            }
        }
        return null;
    };

    render() {
        const themePath = this.getThemePath();
        return (
            <div id='rkd-render-root' className={(this.props.FormRenderState.submissionError !== "" ? 'form-submit-error' : '')}>
                <MainView />
                <div key='customCSS'>
                    <Style>{renderString(this.props.FormRenderState.customCSS)}</Style>
                </div>
                <div key='customJS'>
                    {this.props.FormRenderState.rerenderJS && <ScriptTag>{renderString(this.props.FormRenderState.customJS)}</ScriptTag>}
                </div>
                {themePath && <link rel="stylesheet" type="text/css" href={themePath} />}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    FormRenderState: state.FormRender,
    ReceiptState: state.Receipt
})

const matchActionsToProps = {

}

export default connect(mapStateToProps, matchActionsToProps)(App);

import TextArea, {TextAreaActionsToProps, TextAreaStateToProps} from "../BaseFields/TextArea";
import {connect} from "react-redux";

class TextAreaFields extends TextArea {

}

const mapStateToProps = (state) => ({
    ...TextAreaStateToProps(state)
})

const mapActionsToProps = {
    ...TextAreaActionsToProps
}

export default connect(mapStateToProps, mapActionsToProps)(TextAreaFields);
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React from "react";
import BaseField, { BaseFieldActionsToProps, BaseFieldStateToProps } from "../BaseFields/BaseField";
import { BootstrappedCheckbox as Checkbox } from "../BaseFields/Checkbox";
import { connect } from "react-redux";
import { storedClientClear } from "../../Redux/actions/StoredClientActions";

class RememberMe extends BaseField {

    alwaysUpdate = true

    render() {
        const options = this.getOptionsArray();
        if (this.props.storedClientState.valid) {
            const { billingFirstName, billingLastName, last4, paymentProvider } = this.props.storedClientState.data;
            const isCreditCard = paymentProvider.type === 'creditCard';
            return (<div className={this.getTemplateClasses()} style={{ ...this.getGeneratedStyles(), paddingTop: 10 }}>
                <h5 className="stored-payment-salutation">Welcome back {billingFirstName} {billingLastName}</h5>
                <div className="alert alert-info">
                    {options.rememberedClientText} {isCreditCard ? `credit card: ${last4}` : paymentProvider.type}<br />
                    <div className='text-danger'><a href="javascript:void(0)" onClick={() => { storedClientClear();}}>{options.forgetText}</a></div>
                </div>
            </div>);
        } else {
            return <Checkbox {...this.props} />;
        }
    }

}

const mapStateToProps = (state) => ({
    ...BaseFieldStateToProps(state),
    storedClientState: state.StoredClient
})

const mapActionsToProps = {
    ...BaseFieldActionsToProps
}

export default connect(mapStateToProps, mapActionsToProps)(RememberMe);

import React from "react";
import Textbox, {TextboxActionsToProps, TextboxStateToProps} from "../BaseFields/Textbox";
import {connect} from "react-redux";

class LightboxIfDonationErrorAfterNAttempts extends Textbox{
    componentDidMount () {
        const options = this.getOptionsArray();
        this.setFieldAndTriggerEvent(options.name, true);
    }
    render(){
        return (
            <div className={this.getTemplateClasses()} style={this.getGeneratedStyles()}>
                <></>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    ...TextboxStateToProps(state)
})

const mapActionsToProps = {
    ...TextboxActionsToProps
}

export default connect(mapStateToProps, mapActionsToProps)(LightboxIfDonationErrorAfterNAttempts);
import React from "react";
import BaseField, { BaseFieldActionsToProps, BaseFieldStateToProps } from "../BaseFields/BaseField";
import { connect } from "react-redux";
import { FormGroup, FormLabel, FormCheck, FormControl } from "react-bootstrap";
import { renderString } from "../../lib";

class TributeType extends BaseField {

    componentDidMount() {
        const options = this.getOptionsArray();
        const { FormValueState } = this.props;
        this.setFieldAndTriggerEvent(options.name, typeof FormValueState.tributeType !== 'undefined' ? FormValueState.tributeType : 'none');
    }

    componentDidUpdate() {
        const options = this.getOptionsArray();
        const { FormValueState } = this.props;
        if(!FormValueState.tribute)
        {
            this.setFieldAndTriggerEvent(options.name, 'none');
        }
        else
        {
            if(FormValueState.tributeType === 'none')
            {
                this.setFieldAndTriggerEvent(options.name, options.preselected === 'Honor' ? 'honor' : 'memorial');
            }
            else
            {
                this.setFieldAndTriggerEvent(options.name, FormValueState.tributeType);
            }
        }
    }

    renderButton(FormValueState, options) {
        return (<>
            <button
                className={`btn ${FormValueState[options.name] === 'honor' ? 'btn-success' : 'btn-secondary'}`}
                style={{ marginRight: 10 }}
                onClick={(e) => {
                    e.preventDefault();
                    this.setFieldAndTriggerEvent(options.name, 'honor')}}
            >{options.honorText}</button>
            <button
                className={`btn ${FormValueState[options.name] === 'memorial' ? 'btn-success' : 'btn-secondary'}`}
                onClick={(e) => {
                    e.preventDefault();
                    this.setFieldAndTriggerEvent(options.name, 'memorial')}}
            >{options.memorialText}</button>
        </>);
    }

    renderCheckBox(FormRenderState, options, type) {
        return (<>
            <FormGroup>
                <FormCheck
                    type={type}
                    id={`${type}_honor`}
                    inline
                    onChange={() => this.setFieldAndTriggerEvent(options.name, 'honor')}
                    className={FormRenderState.tributeType === 'honor' ? "checkbox-checked" : ""}
                    checked={FormRenderState.tributeType === 'honor' ? true : false}
                    name={renderString(options.honorText, FormRenderState.variables)}
                    placeholder={renderString(options.placeholder, FormRenderState.variables)}
                    style={{ marginRight: 5 }}
                />
                <FormLabel htmlFor={`${type}_honor`} style={{ marginRight: 10 }}>{renderString(options.honorText, FormRenderState.variables)}</FormLabel>
                <FormCheck
                    type={type}
                    id={`${type}_memorial`}
                    inline
                    onChange={() => this.setFieldAndTriggerEvent(options.name, 'memorial')}
                    className={FormRenderState.tributeType === 'memorial' ? "checkbox-checked" : ""}
                    checked={FormRenderState.tributeType === 'memorial' ? true : false}
                    name={renderString(options.memorialText, FormRenderState.variables)}
                    placeholder={renderString(options.placeholder, FormRenderState.variables)}
                    style={{ marginRight: 5 }}
                />
                <FormLabel htmlFor={`${type}_memorial`} style={{ marginRight: 10 }}>{renderString(options.memorialText, FormRenderState.variables)}</FormLabel>
            </FormGroup>
        </>);
    }

    renderSelection(FormRenderState, options) {
        let optionItems = [{
            value: 'honor',
            label: options.honorText
        }, {
            value: 'memorial',
            label: options.memorialText
        }];
        return (<>
            <FormGroup>
                <FormControl
                    id={`form-control-${options.name}`}
                    as="select"
                    onChange={(event) => {
                        this.setFieldAndTriggerEvent(options.name, event.target.value)
                    }}
                    name={renderString(options.name, FormRenderState.variables)}
                    value={renderString(FormRenderState[options.name], FormRenderState.variables)}
                    placeholder={renderString(options.placeholder, FormRenderState.variables)}
                >
                    {optionItems.map((option, index) => (
                        <option
                            key={index}
                            value={renderString(option.value, FormRenderState.variables)}
                        >{renderString(option.label, FormRenderState.variables)}</option>
                    ))}
                </FormControl>
            </FormGroup>
        </>);
    }

    render() {
        const { FormValueState } = this.props;
        const options = this.getOptionsArray();
        let renderComponent = '';
        if (options.displayType === 'Dropdown') {
            renderComponent = this.renderSelection(FormValueState, options)
        } else if (options.displayType === 'Checkbox' || options.displayType === 'Radio') {
            renderComponent = this.renderCheckBox(FormValueState, options, options.displayType === 'Checkbox' ? 'checkbox' : 'radio');
        } else {
            renderComponent = this.renderButton(FormValueState, options);
        }
        return (
            <div className={this.getTemplateClasses()} style={this.getGeneratedStyles()}>
                <div className='row'>
                    <div className='col-12'>
                        {options.label}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 text-center'>
                        {renderComponent}
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    ...BaseFieldStateToProps(state),
    FormValueState: state.FormValue
})

const mapActionsToProps = {
    ...BaseFieldActionsToProps
}

export default connect(mapStateToProps, mapActionsToProps)(TributeType);
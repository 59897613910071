import dot from "dot-object";
import _ from 'lodash'
import store from "../Redux/store";
import {setError, setPaymentFailure, setInvalidAmount, setGeneralErrors} from "../Redux/actions/FormRenderActions";
import {setCanSubmit} from "../Redux/actions/FormValidationActions";
import {setSubmitTriggers} from "../Redux/actions/TriggerActions";

class AbstractBase {

    /*
        This definition of validators serves as a placeholder and should be overridden by your abstraction if it needs to support
        custom validators.

        As different platforms validate different ways, this gives us the ability to support these different validations.
     */
    validators = {}

    /*
        This definition of fieldMaps serves as a placeholder and should be overridden by your abstraction.
        The format of this variable should be as following

        If mapping fields to an object, define it like so -- mapFieldsToObject() should be used in this situation
        fieldMaps = {
            'stateVariable': 'mappedToVariable', // Good For Mapping one key to another key, flat objects.
            'stateVariable': 'mappedToVariable1, mappedToVariable2' // Good for mapping one key to many other keys, flat objects.
            'stateVariable': 'mapped.sub.object', // Good for mapping one key to another key dot notation will be honored. Not Flat objects
            'stateVariable': 'mapped.sub.object1, mapped.sub.object2', // Good for mapping one key to many other keys dot notation will be honored. Not Flat objects.
        }

        If mapping fields to other fields within an underlying platform. define like so -- mapFieldsToFields() should be used in this situation
        fieldMaps = {
            'stateVariable': '.someCssQueryString' // Good for mapping one field to another,
            'stateVariable': '.someCssQueryString, .someCssQueryString2'  // Good for mapping one field to multiple other fields.
        }
     */
    fieldMaps = {};

    init() {
        // These abstractions can have an init function. Useful for hiding default functionality in 3rd party tools.
        // This function serves as a placeholder so we don't call an undefined function if it's not needed.
        // Your class should override this function if it needs an init() function.
    }

    // This method should be called within your submit() function if you need to map the
    // form state to an object that will be submitted through an API.
    mapFieldsToObject(baseObject = {}) {
        const FormValueState = store.getState().FormValue;
        console.log(`FB - Inside  mapFieldsToObject function: ${JSON.stringify(FormValueState)}`);
        Object.keys(this.fieldMaps).forEach(key => {
            if (typeof FormValueState[key] !== 'undefined') {
                if (this.fieldMaps[key].indexOf(',') === -1) {
                    baseObject[this.fieldMaps[key]] = FormValueState[key];
                } else {
                    const duplicateKeys = this.fieldMaps[key].split(',');
                    duplicateKeys.forEach(duplicateKey => {
                        baseObject[_.trim(duplicateKey)] = FormValueState[key];
                    });
                }
            }
        });
        dot.object(baseObject);
        return baseObject;
    }

    // This method should be called when translating data retrieved from somewhere else needs to be translated.
    // An Example of this would be gathering the data necessary to render a receipt.
    mapExternalObjectToInternal(data) {
        const receiptData = {};
        Object.keys(this.receiptFieldMaps).forEach(key => {
            if (typeof data[this.receiptFieldMaps[key]] !== 'undefined') {
                if (this.receiptFieldMaps[key].indexOf(',') === -1) {
                    receiptData[key] = data[this.receiptFieldMaps[key]];
                } else {
                    const duplicateKeys = this.receiptFieldMaps[key].split(',');
                    duplicateKeys.forEach(duplicateKey => {
                        receiptData[_.trim(duplicateKey)] = data[this.receiptFieldMaps[key]];
                    });
                }
            } else {

                // check standard custom field
                if (this.receiptFieldMaps[key] === 'anonymous') {
                    receiptData['anonymous'] = data.customFields[this.receiptFieldMaps[key]];
                }
                if (this.receiptFieldMaps[key] === 'OrgName') {
                    receiptData['companyMatchingName'] = data.customFields[this.receiptFieldMaps[key]];
                }
                if (this.receiptFieldMaps[key] === 'CompanyName') {
                    receiptData['companyDonorName'] = data.customFields[this.receiptFieldMaps[key]];
                }
                if (this.receiptFieldMaps[key] === 'gift_notes') {
                    receiptData['giftNotes'] = data.customFields[this.receiptFieldMaps[key]];
                }
                // End check standard custom field

            }
        });
        dot.object(receiptData);
        return receiptData;
    }

    // This method should be called within your submit() function if you need to map the
    // form state to some underlying fields within a 3rd party application, such as luminate.
    mapFieldsToFields() {
        // TODO: Write a function that can copy the contents mapped from state into underlying form fields. Useful for implementing with 3rd party tools
    }

    registerSubmitTrigger(name, callback) {
        const TriggerState = {...store.getState().Trigger.submit};
        TriggerState[name] = callback;
        store.dispatch(setSubmitTriggers(TriggerState));
    }

    deregisterSubmitTrigger(name) {
        const TriggerState = {...store.getState().Trigger.submit};
        delete TriggerState[name];
        store.dispatch(setSubmitTriggers(TriggerState));
    }

    async processSubmitTriggers() {
        const TriggerState = store.getState().Trigger;
        const promises = Object.keys(TriggerState.submit).map(async triggerName => {
            await TriggerState.submit[triggerName]();
        });
        await Promise.all(promises);
    }

    clearErrors() {
        store.dispatch(setCanSubmit(true)); // Sense this is tied to triggers, set to true.
        store.dispatch(setError(''));
    }

    setError(error) {
        store.dispatch(setError(error));
    }

    setPaymentFailure(error) {
        store.dispatch(setPaymentFailure(error));
    }

    setInvalidAmount(error) {
        store.dispatch(setInvalidAmount(error));
    }

    setGeneralErrors(error) {
        store.dispatch(setGeneralErrors(error));
    }

}

export default AbstractBase;

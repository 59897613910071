import React, {Component} from 'react';
import FormModal from "./FormModal";
import {connect} from 'react-redux'

class ModalRenderer extends Component{

    render(){
        const modals = this.props.FormRenderState.modals;
        return (
            <>
                {modals.map((modal, index) => (
                    <FormModal key={index} index={modal.id} modal={modal} />
                ))}
            </>
        );
    }

}

const mapStateToProps = (state) => ({
    FormRenderState: state.FormRender
})

export default connect(mapStateToProps)(ModalRenderer);
import React, { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ReactJson from 'react-json-view'

import {connect} from 'react-redux';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}



class DebugBar extends Component{

    state = {
        currentTab: 0
    };

    handleTabChange = (event, newValue) => {
        this.setState({
            currentTab: newValue
        });
    };

    render(){
        const {FormRenderState} = this.props;

        return (
            <>
                <div style={{height: 340}} />
                <Drawer anchor='bottom' open={true} variant='permanent'>
                    <Paper>
                        <AppBar position="static">
                            <Tabs value={this.state.currentTab} onChange={this.handleTabChange} aria-label="simple tabs example">
                                <Tab label="Value State" />
                                <Tab label="Render State"/>
                                <Tab label="Validation State"/>
                                <Tab label="Meta State"/>
                                <Tab label="Value ID State"/>
                                <Tab label="Global Settings State"/>
                            </Tabs>
                        </AppBar>
                        <TabPanel value={this.state.currentTab} index={0} style={{maxHeight: 300, overflow: 'auto'}}>
                            <ReactJson src={this.props.FormValueState} />
                        </TabPanel>
                        <TabPanel value={this.state.currentTab} index={1} style={{maxHeight: 300, overflow: 'auto'}}>
                            <ReactJson src={{
                                paymentProviders: FormRenderState.paymentProviders,
                                pages: FormRenderState.pages
                            }} />
                        </TabPanel>
                        <TabPanel value={this.state.currentTab} index={2} style={{maxHeight: 300, overflow: 'auto'}}>
                            <ReactJson src={this.props.FormValidationState} />
                        </TabPanel>
                        <TabPanel value={this.state.currentTab} index={3} style={{maxHeight: 300, overflow: 'auto'}}>
                            <ReactJson src={this.props.MetaState} />
                        </TabPanel>
                        <TabPanel value={this.state.currentTab} index={4} style={{maxHeight: 300, overflow: 'auto'}}>
                            <ReactJson src={this.props.FormIdValue} />
                        </TabPanel>
                        <TabPanel value={this.state.currentTab} index={5} style={{maxHeight: 300, overflow: 'auto'}}>
                            <ReactJson src={this.props.GlobalSettingsState} />
                        </TabPanel>
                    </Paper>
                </Drawer>
            </>
        );
    }

}

const mapStateToProps = (state) => ({
    FormIdValue: state.FormIdValue,
    FormRenderState: state.FormRender,
    FormValidationState: state.FormValidation,
    FormValueState: state.FormValue,
    GlobalSettingsState: state.GlobalSettings,
    MetaState: state.Meta
})

export default connect(mapStateToProps)(DebugBar);

import BaseSocket from "./BaseSocket";
import { getParameterByName } from "../lib";

const ComponentSocket = {
    sockets: [],
    init: (componentId) => {
        if (!BaseSocket.socket) {
            BaseSocket.init();
        }
        if (ComponentSocket.sockets[componentId]) {
            return ComponentSocket.sockets[componentId];
        } else {
            ComponentSocket.sockets[componentId] = BaseSocket.socket.subscribe('component:' + componentId + "|" + getParameterByName('cl'));
        }
        return ComponentSocket.sockets[componentId];
    }
};

export default ComponentSocket;
import {
    FORM_RENDER_INIT_SOCKET, FORM_RENDER_SET_CSS,
    FORM_RENDER_SET_ERROR,
    FORM_RENDER_SET_LOADING,
    FORM_RENDER_SET_PAYMENTPROVIDERS,
    FORM_RENDER_SET_PAGE, FORM_RENDER_SET_THEME,
    FORM_RENDER_SET_PAYMENTFAILURE,
    FORM_RENDER_SET_INVALIDAMOUNT,
    FORM_RENDER_SET_GENERALERRORS,
    FORM_RENDER_SET_SKIPPEDSTEPS
} from "../actionTypes";

const initialState = {
    activeStep: 0,
    theme: null,
    formName: null,
    pages: [],
    modals: [],
    thankYouPage: [],
    confirmationFields: [],
    uniqueComponents: [],
    paymentProviders: {},
    triggers: [],
    options: {},
    customCSS: '',
    customJS: '',
    rerenderCSS: false,
    rerenderJS: false,
    baseSocket: null,
    socket: null,
    variables: {},
    submissionError: '',
    isLoading: false,
    requestData: null,
    googleRecaptcha: false,
    recaptchaSiteKey: '',
    recaptcha: null,
    isLoaded: false,
    deleteMsg:false,
    paymentFailure: false,
    invalidAmount: false,
    generalErrors: false,
    skippedSteps: [],
    DISSetting: false,
    DISRules: []
};

export default function(state = initialState, action){
    switch (action.type){
        case FORM_RENDER_INIT_SOCKET:
            return {
                ...state,
                ...action.payload
            }
        case FORM_RENDER_SET_LOADING:
            return {
                ...state,
                isLoading: action.payload
            }
        case FORM_RENDER_SET_ERROR:
            return {
                ...state,
                submissionError: action.payload
            }
        case FORM_RENDER_SET_PAGE:
            return {
                ...state,
                activeStep: action.payload
            }
        case FORM_RENDER_SET_CSS:
            return {
                ...state,
                customCSS: action.payload
            }
        case FORM_RENDER_SET_THEME:
            return {
                ...state,
                theme: action.theme
            }
        case FORM_RENDER_SET_PAYMENTPROVIDERS:
            return {
                ...state,
                paymentProviders: action.payload
            }
        case FORM_RENDER_SET_PAYMENTFAILURE:
            return {
                ...state,
                paymentFailure: action.payload
            }
        case FORM_RENDER_SET_INVALIDAMOUNT:
            return {
                ...state,
                invalidAmount: action.payload
            }
        case FORM_RENDER_SET_GENERALERRORS:
            return {
                ...state,
                generalErrors: action.payload
            }

        case FORM_RENDER_SET_SKIPPEDSTEPS:
            return {
                ...state,
                skippedSteps: action.payload
            }
        default:
            return state;
    }
}

import React from "react";
import BaseField, { BaseFieldActionsToProps, BaseFieldStateToProps } from "../BaseFields/BaseField";
import { connect } from "react-redux";
import { renderString } from "../../lib";

class TitleText extends BaseField {

    constructor(props) {
        super(props);

        this.state = {
            titleText: ''
        };
    }

    componentDidMount() {
        const options = this.getOptionsArray();
        this.setState({ titleText: options.text })
    }

    componentWillReceiveProps() {
        const options = this.getOptionsArray();
        if (this.state.titleText !== options.text) {
            this.setState({ titleText: options.text });
        }
    }

    render() {
        return (
            <div className={this.getTemplateClasses()} style={this.getGeneratedStyles()}>
                <div className='row'>
                    <div className='col-md-12 text-center'>
                        <h3 className='title_text'>{renderString(this.state.titleText, this.props.FormRenderState.variables)}</h3>
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    ...BaseFieldStateToProps(state),
    FormRenderState: state.FormRender,
})

const mapActionsToProps = {
    ...BaseFieldActionsToProps
}

export default connect(mapStateToProps, mapActionsToProps)(TitleText);
import React, { Component } from 'react';
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class TributeSuccessModal extends Component {

    constructor() {
        super();
        this.state = {
            show: true
        }
    }

    render() {

        const { show } = this.state;
        const { data: ReceiptData } = this.props.ReceiptState;

        return (
            <Modal
                size="md"
                centered
                show={show}
            >
                <Modal.Header className="justify-content-center text-success border-bottom-0">
                    <Modal.Title className="font-weight-bold">
                        Thank You
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    Your donation has been dedicated to <span className="font-weight-bold">{ReceiptData.tributeFirstName + " " + ReceiptData.tributeLastName}</span>!
                </Modal.Body>
                <Modal.Footer className="justify-content-center border-top-0">
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            this.setState({ show: false });
                        }}
                    >
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

}

const mapStateToProps = (state) => ({
    ReceiptState: state.Receipt
})

export default connect(mapStateToProps)(TributeSuccessModal);
import React from "react";

// Import Components.
import FormField from "../../FormField";
import ModalRenderer from "../../../Components/ModalRenderer";

function InputFields({ section }) {
    return (
        <>
            {section.components.map((component, index) => {
                return (
                    <div
                        className={`row TYP_INPUT_${component.id}`}
                        style={{ paddingLeft: 10, paddingRight: 10 }}
                        key={index}>
                        <FormField field={component} />
                    </div>
                )
            })}
            <ModalRenderer />
        </>
    );
}

export default InputFields;
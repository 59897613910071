import React from "react";

function ThankYouPageCustomHTML({ component, options, ReceiptState }) {
    return (
        <div className={'row TYP_' + (component.dataId)} style={{ padding: 10 }}>
            <div className={'col-12 TYC_' + (component.dataId)} dangerouslySetInnerHTML={{ __html: options.html }}>
            </div>
        </div>
    );
}

export default ThankYouPageCustomHTML;
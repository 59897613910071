import React, { Component } from 'react';
import FormStep from "./FormStep";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { Alert, Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import ReCAPTCHA from "react-google-recaptcha";
import { setFormValue } from "../Redux/actions/FormValueActions";
import { setSkippedSteps } from "../Redux/actions/FormRenderActions";
import { getParameterByName, renderString } from '../lib';

import { connect } from 'react-redux'

import moment from "moment";
import 'moment-timezone';

class StepRenderer extends Component {

    constructor(props) {
        super(props);
        window.rkd.form.on('internal_event', (event) => this.triggerListener(event));
    }

    triggerListener(event) {
        const { pages, skippedSteps } = this.props.FormRenderState;
        pages.map((page, index) => {
            const { triggers } = page;
            if (triggers && triggers.includes(event.triggerIndex)) {
                if (['hide', 'show'].includes(event.action)) {
                    if(event.action === 'hide') {
                        if(!skippedSteps.includes(index)) {
                            skippedSteps.push(index);
                        }
                    } else {
                        const index1 = skippedSteps.indexOf(index);
                        if (index > -1) { // only splice array when item is found
                            skippedSteps.splice(index1, 1); // 2nd parameter means remove one item only
                        }
                    }
                }
            }
        });
        setSkippedSteps('skippedSteps', skippedSteps);
    }

    getSteps = () => {
        const steps = [];
        this.props.FormRenderState.pages.forEach((step) => {
            steps.push(step.name);
        });
        return steps;
    };

    getStepContent = (step) => {
        return (<FormStep index={step} />);
    };

    handleNext() {
        window.rkd.form.nextStep();
    }

    handleBack() {
        this.props.setFormValue('paymentType', '');
        window.rkd.form.previousStep();
    }

    handleChange = value => {
        const { FormValueState } = this.props;

        console.log("onChange prop - Captcha value:", value);
        FormValueState.reCaptchaResponse = value
        this.handleSubmit();
    };

    handleSubmit = async () => {
        const { FormRenderState, FormValueState } = this.props;

        console.log(`FB - handleSubmit - Call the function submit Form. Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);
        if(FormRenderState.googleRecaptcha) {
            console.log("FB - handleSubmit():FormValueState.reCaptchaResponse", FormValueState.reCaptchaResponse);
            if (!FormValueState.reCaptchaResponse) {
                try {
                    // // eslint-disable-next-line
                    console.log("FB - handleSubmit():grecaptcha?.getResponse()", FormRenderState.recaptcha?.props?.grecaptcha?.getResponse());
                    // eslint-disable-next-line
                    FormValueState.reCaptchaResponse = (FormRenderState.googleRecaptcha && FormValueState.reCaptchaResponse !== '') 
                    ? FormValueState.reCaptchaResponse : FormRenderState.recaptcha?.props?.grecaptcha?.getResponse();
                } catch (error) {
                    console.log(error);
                }
            }
            else {
                window.rkd.form.submitForm();
            }
        } else {
            window.rkd.form.submitForm();
        }
    }

    checkRecaptcha = async () => {
        console.log(`FB - checkRecaptcha - Checking the reCaptcha. Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);
        const { FormRenderState, FormValueState } = this.props;
        
        if (FormValueState.paymentType !== 'generic_credit_card') {
            console.log(`FB - checkRecaptcha - This is not a generic cc. Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);
            console.log(`FB - checkRecaptcha - Check the googleRecaptcha object. Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);
            console.log(FormRenderState.googleRecaptcha);
            
            console.log(`FB - checkRecaptcha - Check the googleRecaptcha response. Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);
            if(FormRenderState.googleRecaptcha) {
                try {
                    // eslint-disable-next-line
                    console.log("FB - checkRecaptcha():grecaptcha", FormRenderState.recaptcha?.props?.grecaptcha);    
                    // eslint-disable-next-line
                    console.log("FB - checkRecaptcha():grecaptcha.getResponse()", FormRenderState.recaptcha?.props?.grecaptcha?.getResponse());    
                } catch (error) {
                    console.log(error);
                }

            } else {
                console.log(`FB - checkRecaptcha - Google reCaptcha is not set. Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);    
            }

            if((FormRenderState.googleRecaptcha && (!FormValueState.reCaptchaResponse || FormValueState.reCaptchaResponse === ''))) {
                // eslint-disable-next-line
                FormRenderState.recaptcha?.props?.grecaptcha.execute(); 
                console.log("FB - checkRecaptcha():grecaptcha.execute()");
            }
            this.handleSubmit();
        }
        else
        {
            console.log(`FB - checkRecaptcha - This is a generic cc. Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);
        }
    }

    handleReCaptchaExpired = () => {
        console.log("FB - handleReCaptchaExpired");
        const { FormRenderState } = this.props;
        // eslint-disable-next-line
        FormRenderState.recaptcha?.props?.grecaptcha.reset();
        // eslint-disable-next-line
        FormRenderState.recaptcha?.props?.grecaptcha.execute();
    }

    getSubmitText() {
        const { FormValueState } = this.props;
        let submitTextLable = "Submit {amount}";
        if (typeof FormValueState.recurring_gift !== "undefined") {
            if (typeof FormValueState.giftArrayInfo.SubmitButtonOneTimeText !== "undefined" && FormValueState.giftArrayInfo.SubmitButtonOneTimeText !== "" && FormValueState.recurring_gift === "single") {
                submitTextLable = FormValueState.giftArrayInfo.SubmitButtonOneTimeText;
            } else if (typeof FormValueState.giftArrayInfo.SubmitButtonMonthlyText !== "undefined" && FormValueState.giftArrayInfo.SubmitButtonMonthlyText !== "" && FormValueState.recurring_gift === "recurring") {
                submitTextLable = FormValueState.giftArrayInfo.SubmitButtonMonthlyText;
            }
        }

        if (typeof FormValueState.giftAmount === 'undefined' || !FormValueState.giftAmount) {
            return renderString(submitTextLable.replace(/\{amount}/g, ''));
        } else {
            return renderString(submitTextLable.replace(/\{amount}/g, `$${parseFloat(FormValueState.giftAmount).toFixed(2)}`))
        }
    }

    render() {
        const { FormRenderState, FormValueState } = this.props;
        return (<>
            <div className="d_stepper">
                <Stepper activeStep={FormRenderState.activeStep}>
                    {this.getSteps().map((label, index) => {
                        const stepProps = {};
                        stepProps.completed = FormRenderState.activeStep > index;
                        return (<Step key={label} {...stepProps}>
                            <StepLabel>{label}</StepLabel>
                        </Step>);
                    })}
                </Stepper>
            </div>
            <div>
                {FormRenderState.activeStep >= FormRenderState.pages.length ? ( // all steps completed
                    <div>
                        <Alert variant='success'>All steps have been completed</Alert>
                    </div>
                ) : ( //render current step
                        <div className="multi-step-donation-form">
                            <div className={'step-' + FormRenderState.activeStep}>
                                <FormStep renderSubmit={false} index={FormRenderState.activeStep} />
                            </div>
                            {FormRenderState.isLoading && <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>}
                            {!FormRenderState.isLoading && <>
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.handleBack()}
                                    }
                                    disabled={FormRenderState.activeStep === 0}
                                    variant='danger'
                                    className='pr-2 form-button-back'
                                    id={`form-back-${FormRenderState.activeStep + 1}`}
                                >Back</Button>
                                {(FormValueState.paymentType !== 'braintree_paypal' && FormValueState.paymentType !== 'stripe_applepay') &&
                                    <Button
                                        className='form-button-next'
                                        id={`form-next-${FormRenderState.activeStep + 1}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            FormRenderState.activeStep === (FormRenderState.pages.length - 1) ? this.checkRecaptcha() : this.handleNext()}
                                        }
                                        variant={FormRenderState.activeStep === (FormRenderState.pages.length - 1) ? 'success' : 'primary'}>
                                        {FormRenderState.activeStep === (FormRenderState.pages.length - 1) ? this.getSubmitText() : 'Next'}
                                    </Button>
                                }
                                {FormRenderState.googleRecaptcha && FormRenderState.recaptchaSiteKey !== '' &&
                                    <ReCAPTCHA
                                        size="invisible"
                                        sitekey={FormRenderState.recaptchaSiteKey}
                                        onChange={this.handleChange}
                                        onExpired={this.handleReCaptchaExpired}
                                        ref={ref => FormRenderState.recaptcha = ref}
                                    />
                                }
                            </>}
                        </div>
                    )}
            </div>
        </>);
    }

}

const mapStateToProps = (state) => ({
    FormRenderState: state.FormRender,
    FormValueState: state.FormValue
});

const mapActionsToProps = {
    setFormValue,
    setSkippedSteps
};

export default connect(mapStateToProps, mapActionsToProps)(StepRenderer);

import CheckboxInput, { CheckboxInputActionsToProps, CheckboxInputStateToProps } from "../BaseFields/CheckboxInput";
import { connect } from "react-redux";

class CheckboxInputField extends CheckboxInput {

}

const mapStateToProps = (state) => ({
    ...CheckboxInputStateToProps(state)
})

const mapActionsToProps = {
    ...CheckboxInputActionsToProps
}

export default connect(mapStateToProps, mapActionsToProps)(CheckboxInputField);
import React from "react";
import { renderString } from "../../lib";
import { FormGroup, FormLabel, FormCheck } from "react-bootstrap";
import BaseField, {BaseFieldActionsToProps, BaseFieldStateToProps} from "./BaseField";
import {connect} from "react-redux";

class RadioButton extends BaseField {

    state = {
        optionItems: []
    }

    componentDidMount() {
        const options = this.getOptionsArray();

        // get options for Radio button with label and value array of object
        let customFieldItems = this.props.options.find((opt) => { return opt.field === 'options'; });
        this.setState({ optionItems: [...customFieldItems.values] });

        const { FormValueState } = this.props;
        let fieldName = options.name;
        if (options.custom) {
            fieldName = `dmc_custom_field_${options.name}`;
        }
        this.triggerOnFieldLoadInForm(typeof FormValueState[fieldName] !== 'undefined' ? FormValueState[fieldName] : customFieldItems.values[0].value);
        this.setFieldAndTriggerEvent(fieldName, typeof FormValueState[fieldName] !== 'undefined' ? FormValueState[fieldName] : customFieldItems.values[0].value);
    }

    render() {
        const {FormValueState} = this.props;

        const errorMessage = this.getRenderedValidationMessage();
        const options = this.getOptionsArray();
        let fieldName = options.name;
        if (options.custom) {
            fieldName = `dmc_custom_field_${options.name}`;
        }

        return (
            <div className={this.getTemplateClasses()} style={this.getGeneratedStyles()}>
                <FormGroup>
                    <FormLabel className={typeof options.validators !== 'undefined' ? options.validators.required ? 'required required-field' : '' : ''}>{options.label}</FormLabel>
                    {this.state.optionItems.map((option, index) => (
                        <FormCheck
                            inline
                            key={index}
                            id={`radio_${option.label}`}
                            value={renderString(option.value, this.props.FormRenderState.variables)}
                            type="radio"
                            label={renderString(option.label, this.props.FormRenderState.variables)}
                            onChange={(event) => this.onChange(event)}
                            checked={FormValueState[fieldName] === option.value}
                        />
                    ))}
                    <div style={{ color: '#F00' }} className="donation-form-field-error">{errorMessage}</div>
                </FormGroup>
            </div>
        );
    }
}

export const RadioButtonStateToProps = (state) => ({
    ...BaseFieldStateToProps(state),
    FormRenderState: state.FormRender,
    FormValueState: state.FormValue
})

export const RadioButtonActionsToProps = {
    ...BaseFieldActionsToProps
}

export const BootstrappedGiftRadioButton = connect(RadioButtonStateToProps, RadioButtonActionsToProps)(RadioButton);
export default RadioButton;

import {COMPONENT_INIT_SOCKET} from "../actionTypes";
import {getParameterByName, isIE, isPreview} from "../../lib";
import ComponentSocket from "../../Sockets/ComponentSocket";
import axios from "axios";
import store from "../store";


// eslint-disable-next-line no-unused-vars
function parseData(data, componentId){
    const payload  = {
        state: [...store.getState().Component.state],
    }
    payload.state[componentId] = data;
    return payload
}

export const initComponentSocket = (componentId) => {
    return async function(dispatch){
        if (isPreview() && !isIE()) {
            if (!ComponentSocket.sockets[componentId]) {
                ComponentSocket.init(componentId);
                ComponentSocket.sockets[componentId].on('serverUpdate', (data) => {
                    dispatch({
                        type: COMPONENT_INIT_SOCKET,
                        payload: {
                            componentId: componentId,
                            state: data,
                            isLoaded: true
                        }
                    });
                });
            }
        } else {
            const protocol = process.env.NODE_ENV === 'development' ? 'http://' : 'https://';
            const showPreview = isPreview() ? "true" : "false";
            const requestData = await axios.get(`${protocol}${process.env.REACT_APP_API_HOST}/component/${componentId}?preview=${showPreview}&cl=${getParameterByName('cl')}`);
            
            dispatch({
                type: COMPONENT_INIT_SOCKET,
                payload: {
                    componentId: componentId,
                    state: requestData.data,
                    isLoaded: true
                }
            });
        }
    }
}

export const getComponentIndex = (FormRenderState) => {
    const index = {};
    FormRenderState.pages.forEach(page => {
        page.components.forEach(component => {
            index[component.id === "thankYouPageCustomHTML" ? component.dataId : component.id] = component;
        });
    });
    FormRenderState.modals.forEach(page => {
        page.components.forEach(component => {
            index[component.id === "thankYouPageCustomHTML" ? component.dataId : component.id] = component;
        });
    });
    return index;
}

export const getComponentOptions = (component) => {
    let options = {};
    component.options.forEach((option) => {
        options[option.field] = option.value || option.default;
        if (typeof component.custom !== "undefined" && component.custom) {
            options["custom"] = component.custom;
        }
    });
    return options;
}


import React from "react";
import { renderString } from "../../lib";

import {
    FormGroup,
    FormLabel,
    FormControl
} from "react-bootstrap";
import BaseField, {BaseFieldActionsToProps, BaseFieldStateToProps} from "./BaseField";
import {connect} from "react-redux";

class DatePicker extends BaseField {

    cursor;

    componentDidUpdate(prevProps, prevState, snapshot){

    }

    componentDidMount() {
        // This code will set the custom field value by default blank,
        // So it will pass for transaction without interact with custom field
        const { FormValueState } = this.props;
        const options = this.getOptionsArray();
        let fieldName = options.name;
        if (options.custom) {
            fieldName = `dmc_custom_field_${options.name}`;
        }
        this.triggerOnFieldLoadInForm(typeof FormValueState[fieldName] !== 'undefined' ? FormValueState[fieldName] : "");
    }

    render() {
        const {FormRenderState} = this.props;
        const errorMessage = this.getRenderedValidationMessage();
        let options = this.getOptionsArray();
        let fieldName = options.name;
        if (options.custom) {
            fieldName = `dmc_custom_field_${options.name}`;
        }
        const props = {
            type: 'date',
            onChange: (event) => this.onChange(event),
            value: this.props.value || renderString(this.props.FormValueState[fieldName], FormRenderState.variables),
            name: renderString(fieldName, FormRenderState.variables),
            placeholder: renderString(options.placeholder, FormRenderState.variables),
            isInvalid: errorMessage !== '',
        };

        if (typeof this.fieldType !== 'undefined') {
            props.as = this.fieldType;
        }

        const randomNumber = Math.abs(Math.random() * Number.MAX_SAFE_INTEGER).toFixed(0);

        return (
            <div className={this.getTemplateClasses()} style={this.getGeneratedStyles()}>
                <FormGroup>
                    <FormLabel htmlFor={`date-picker-${randomNumber}`} className={typeof options.validators !== 'undefined' ? options.validators.required ? 'required required-field' : '' : ''}>{renderString(options.label, FormRenderState.variables)}</FormLabel>
                    <FormControl
                        id={`date-picker-${randomNumber}`}
                        {...props}
                        onChange={(event) => {

                            if(props.onChange){
                                props.onChange(event);
                            }
                        }}
                    />
                     <div style={{ color: '#F00' }} className="donation-form-field-error">{errorMessage}</div>
                </FormGroup>
            </div>
        );
    }

}

export const DatePickerStateToProps = (state) => ({
    ...BaseFieldStateToProps(state),
    FormRenderState: state.FormRender,
    FormValueState: state.FormValue
})

export const DatePickerActionsToProps = {
    ...BaseFieldActionsToProps
}

export const BootstrappedDatePicker = connect(DatePickerStateToProps, DatePickerActionsToProps)(DatePicker);
export default DatePicker;

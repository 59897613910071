import {PAGE_INIT_SOCKET, PAGE_SET_GA} from "../actionTypes";

const initialState = {
    state: [],
    requestData: [],
    isGAInitialized: false,
    isDeleted: 0,
    isLoaded: false,
    pageId: null
};

export default function(state = initialState, action){
    switch (action.type){
        case PAGE_INIT_SOCKET:
            return {
                ...state,
                ...action.payload
            }
        case PAGE_SET_GA:
            return {
                ...state,
                isGAInitialized: action.payload
            }
        default:
            return state;
    }
}
import React from "react";
import { getParameterByName, renderString, isTruthy, getDynamicErrorMessage } from "../../lib";
import BaseField, { BaseFieldActionsToProps, BaseFieldStateToProps } from "./BaseField";
import { setFormValue, setSubmitButtonText } from "../../Redux/actions/FormValueActions";
import { setInvalidAmount, setError } from "../../Redux/actions/FormRenderActions";
import { connect } from 'react-redux';
import { FormGroup, FormLabel, FormCheck } from "react-bootstrap";

var outlined = "outlined";
var contained = "contained";

class GiftArrayButton extends BaseField {

    constructor(props) {
        super(props);
        this.state = {
            inverted: true,
            giftArrayState: [],
            giftArrayActive: [],
            didMount: false,
            otherAmount: "",
            minAmount: 0,
            errorMessage: "",
        }
        this.handleGiftClick = this.handleGiftClick.bind(this);
        //  this.toggleInverted = this.toggleInverted.bind(this);
    }


    componentDidMount() {
        let chosenCustom = 0;
        const { FormValueState } = this.props;
        
        this.setState({
            giftArrayState: [...this.props.optionItems],
            didMount: true,
            minAmount: this.props.optionsArray.Validators && this.props.optionsArray.Validators.minAmount && this.props.optionsArray.Validators.minimumAmountValue ? this.props.optionsArray.Validators.minimumAmountValue : 0
        }, () => {

            this.props.setFormValue('giftMinAmount', this.props.optionsArray.Validators && this.props.optionsArray.Validators.minAmount ? true : false);
            this.props.setFormValue('giftMinAmountValue', this.props.optionsArray.Validators && this.props.optionsArray.Validators.minAmount ? this.props.optionsArray.Validators.minimumAmountValue : 0);

            if (getParameterByName('customamt')) {
                chosenCustom = (isTruthy(getParameterByName('customamt'))) ? this.customAmtFieldInput.focus() : getParameterByName('customamt');
            } else if (getParameterByName('chosen')) {
                chosenCustom = getParameterByName('chosen');
            }

            if (typeof this.props.FormValueState.giftArrayOther === 'undefined' && chosenCustom) {
                this.setFieldAndTriggerEvent(this.props.name, chosenCustom);
                this.props.setFormValue('giftArrayOther', chosenCustom);
                if (typeof FormValueState.giftAmount === 'undefined' || FormValueState.giftAmount === '' || FormValueState.giftAmount === 0) {
                    this.handleGiftClick(99, chosenCustom);
                }
            }

            this.setDefaultSelected();
        });
        this.props.setSubmitButtonText(this.props.optionsArray);
    }

    componentWillReceiveProps(nextProps) {

        if (this.props.optionItems.length !== nextProps.optionItems.length) {
            this.setState({
                giftArrayState: [...nextProps.optionItems],
            }, () => {
                this.setDefaultSelected();
            });
        }
        let filterOutActive = [];
        if (nextProps.optionItems.length > 0) {
            filterOutActive = nextProps.optionItems.filter((e) => e.active);
        } 
        if (filterOutActive.length > 0) {
            this.setState({ otherAmount: "" });
        } else if ((this.state.otherAmount !== nextProps.FormValueState.giftArrayOther)) {
            this.setState({ otherAmount: nextProps.FormValueState.giftArrayOther });
        }        
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const superUpdate = super.shouldComponentUpdate(nextProps, nextState, nextContext);
        if (superUpdate) {
            return true;
        }
        if (nextProps.FormValueState.recurring_gift !== this.props.FormValueState.recurring_gift) {
            return true;
        }
        return false;
    }

    setDefaultSelected() {
        for (let i = 0; i < this.state.giftArrayState.length; i++) {
            if (this.props.optionItems[i].active) {
                this.setFieldAndTriggerEvent(this.props.name, this.props.optionItems[i].value);
                this.handleGiftClick(i, this.props.optionItems[i].value);
                return;
            }
        }
    }

    handleGiftClick = (arrayArgument, e = "") => {
        const { setFormValue, FormRenderState } = this.props;

        if (this.state.didMount) {
            const arrayUpdate = this.state.giftArrayState.map((giftArrayButton, arraysIndex) => {
                return arrayArgument === arraysIndex ? giftArrayButton.active = true : giftArrayButton.active = false
            })


            this.setState({
                giftArrayActive: arrayUpdate
            });

            if (arrayArgument !== 99) {
                setFormValue('giftArrayOther', '');
                setFormValue('giftAmount', e);
                this.props.setInvalidAmount(false);
                if (FormRenderState.generalErrors) {
                    this.props.setError(getDynamicErrorMessage());
                }
                else {
                    this.props.setError('');
                }
            } else {
                if (e === "") {
                    setFormValue('giftAmount', 0);
                    setFormValue('donationArrayBtn', '');
                    setFormValue('giftArrayOther', '');
                } else {
                    setFormValue('giftAmount', e);
                    setFormValue('giftArrayOther', e);
                    this.props.setInvalidAmount(false);
                    if (FormRenderState.generalErrors) {
                        this.props.setError(getDynamicErrorMessage());
                    }
                    else {
                        this.props.setError('');
                    }
                }
            }
            setFormValue('giftArrayActiveIndex', arrayArgument);

            let errorDefaultMessage = "";
            if (e && Number(e) < Number(this.state.minAmount) && Number(e) > 0) {
                errorDefaultMessage = this.props.optionsArray.standardErrorMessage ? this.props.optionsArray.standardErrorMessage.replace(/\{minAmount}/g, this.state.minAmount) : `Please enter an amount of ${this.state.minAmount} or above`;
            } else if(e && Number(e) <= 0) {
                errorDefaultMessage = "Donation amount must be greater than 0";
            }
            this.setState({
                errorMessage: errorDefaultMessage
            })            
        }
    }


    async set(name, value) {
        this.props.setFormValue('giftAmount', value);
        this.setFieldAndTriggerEvent(name, value);
    }

    renderButton(item, index) {
        const { FormValueState, FormRenderState } = this.props;
        return (<>
            <button
                className={`btn btn-giftArray ${item.active ? "btn-primary btn-giftArray-contain" : "btn-outline-primary"}`}
                onClick={(e) => {
                    e.preventDefault();
                    this.handleGiftClick(index, e.target.value);
                    this.set(this.props.name, item.value);
                }}
                variant={FormValueState.giftArrayActiveIndex === index ? contained : outlined}
                size={"large"} value={item.value} style={{ fontSize: '28px' }} key={index}>
                {this.state.didMount && this.props.optionItems[index].active}
                {renderString(item.label, FormRenderState.variables)}
            </button>
        </>);
    }

    renderRadio(item, index) {
        const { FormRenderState } = this.props;
        return (<>
            <FormGroup>
                <FormCheck
                    type="radio"
                    id={item.label}
                    inline
                    onChange={(e) => {
                        this.handleGiftClick(index, e.target.value);
                        this.set(this.props.name, item.value);
                    }}
                    checked={item.active}
                    className={item.active ? 'amt-radio-active' : ''}
                    name={renderString(item.label, FormRenderState.variables)}
                    placeholder={renderString(item.placeholder, FormRenderState.variables)}
                    // size={"large"} value={item.value} style={{ fontSize: '28px' }} key={index}
                    style={{ marginRight: 5 }}
                />
                <FormLabel htmlFor={item.label} style={{ marginRight: 10 }}>{renderString(item.label, FormRenderState.variables)}</FormLabel>
            </FormGroup>
        </>);
    }

    handleChang = (e) => {
        this.setState({otherAmount: e.target.value});
    }

    render() {
        const { FormValueState, FormRenderState, setFormValue } = this.props;
        const options = this.getOptionsArray();
        let multiplier = this.props.optionsArray.copyTextMultiplier;
        if (getParameterByName('multiplier')) {
            multiplier = getParameterByName('multiplier');
        }

        return (
            <>
                <div className="row">
                    {this.props.optionItems.map((item, index) => (
                        <div className="col-sm" key={index} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
                            {(options.displayType === 'Radio') ? this.renderRadio(item, index) : this.renderButton(item, index)}
                            <div style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{
                                __html: renderString(item.copyText.replace(/\{gift.amount}/g, item.value).replace(/\{gift.product}/g, Math.round(parseFloat(multiplier) * parseFloat(item.value))), FormRenderState.variables)
                            }} />
                        </div>
                    ))}
                    <div className="col other-amount-input">
                        <div className="input-group other-amt-btn btn-giftArray" id="otherAmount">
                            <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                            </div>
                            <input
                                type="number"
                                className="form-control"
                                aria-label="Other Amount"
                                onFocus={(e) => { this.handleGiftClick(99, e.target.value) }}
                                ref={(input) => { this.customAmtFieldInput = input; }}
                                onChange={this.handleChang}
                                onBlur={(e) => {
                                    this.set(this.props.name, e.target.value);
                                    setFormValue('giftArrayOther', e.target.value);
                                    this.props.setInvalidAmount(false);
                                    if (FormRenderState.generalErrors) {
                                        this.props.setError(getDynamicErrorMessage());
                                    }
                                    else {
                                        this.props.setError('');
                                    }
                                    this.handleGiftClick(99, e.target.value);
                                }}
                                value={this.state.otherAmount}
                                placeholder={renderString((this.props.optionsArray.OtherAmountPlaceholder !== undefined ? this.props.optionsArray.OtherAmountPlaceholder : ""), FormRenderState.variables)} />
                        </div>
                        <div className="other-amount-text" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} dangerouslySetInnerHTML={{
                            __html: renderString(this.props.optionItems.otherCopyText.replace(/\{gift.amount}/g, FormValueState.giftArrayOther).replace(/\{gift.product}/g, Math.round(parseFloat(multiplier) * parseFloat(FormValueState.giftArrayOther || 0))), FormRenderState.variables)
                        }} />
                    </div>
                </div>
                <div style={{ color: '#F00', textAlign: 'center', marginTop: '10px' }} className="donation-form-field-error">{this.state.errorMessage}</div>
            </>
        );
    }
}

export const GiftArrayButtonStateToProps = (state) => ({
    ...BaseFieldStateToProps(state),
    FormRenderState: state.FormRender,
    FormValueState: state.FormValue
})

export const GiftArrayButtonActionsToProps = {
    ...BaseFieldActionsToProps,
    setFormValue,
    setSubmitButtonText,
    setInvalidAmount,
    setError
}


export const BootstrappedGiftArrayButton = connect(GiftArrayButtonStateToProps, GiftArrayButtonActionsToProps)(GiftArrayButton);
export default GiftArrayButton;

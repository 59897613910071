import React from "react";
import { renderString } from "../../lib";

import {
    FormGroup,
    FormLabel,
    FormCheck
} from "react-bootstrap";
import BaseField, {BaseFieldActionsToProps, BaseFieldStateToProps} from "./BaseField";
import {setFormValue} from "../../Redux/actions/FormValueActions";
import {connect} from "react-redux";

class CheckboxField extends BaseField {

    state = {
        checked: false,
        checkedValue: ''
    }

    componentDidMount() {
        const options = this.getOptionsArray();
        const { FormValueState } = this.props;
        let checked = false;
        let checkedValue = options.unchecked;
        let fieldName = options.name;
        if (options.custom) {
            fieldName = `dmc_custom_field_${options.name}`;
        }

        if(typeof FormValueState[fieldName] === 'undefined')
        {
            if(options.default === 'Yes')
            {
                checkedValue = options.checked;
                checked = true;
            }
        }
        else if (FormValueState[fieldName] === options.checked) {
            checkedValue = options.checked;
            checked = true;
        }
        
        this.setState({ checked });
        this.setFieldAndTriggerEvent(fieldName, checkedValue);
    }

    onCheck(event) {
        const options = this.getOptionsArray();
        let fieldName = this.getFieldCustomFieldValue(options);
        let checkedValue = event.target.checked;
        this.setState({ checked: checkedValue });
        this.setFieldAndTriggerEvent(fieldName, checkedValue ? options.checked : options.unchecked);
    }

    getFieldCustomFieldValue(options) {
        const {FormValueState, setFormValue} = this.props;
        let fieldName = options.name;
        if (options.custom) {
            fieldName = `dmc_custom_field_${options.name}`;            
            if (!FormValueState.customFields) {
                setFormValue('customFields', []);
            }
            if (!FormValueState.customFields.includes(fieldName)) {
                const customFields = [...FormValueState.customFields];
                customFields.push(fieldName);
                setFormValue('customFields', customFields)
            }
        }
        return fieldName;
    }

    render() {
        const {FormRenderState} = this.props;
        const errorMessage = this.getRenderedValidationMessage();
        let options = this.getOptionsArray();

        let fieldName = options.name;
        if (options.custom) {
            fieldName = `dmc_custom_field_${options.name}`;
        }

        if (this.props.formattedOptions) {
            options = this.props.formattedOptions;
        } else {
            this.props.options.forEach((option) => {
                options[option.field] = option.value || option.default;
            });
        }

        const fieldRand = Math.ceil(Math.random() * 100000);

        return (
            <div className={this.getTemplateClasses() + " donation-form-checkbox"} style={this.getGeneratedStyles()}>
                <FormGroup>
                    <FormCheck
                        type='checkbox'
                        id={`checkbox_${fieldRand}`}
                        inline
                        onChange={(event) => this.onCheck(event)}
                        className={this.state.checked ? "checkbox-checked" : ""}
                        checked={this.state.checked}
                        name={renderString(fieldName, FormRenderState.variables)}
                        placeholder={renderString(options.placeholder, FormRenderState.variables)}
                        isInvalid={errorMessage !== ''}
                        style={{ marginRight: 0 }}
                    />
                    <FormLabel htmlFor={`checkbox_${fieldRand}`} className={typeof options.validators !== 'undefined' ? options.validators.required ? 'required required-field' : '' : ''}>{renderString(options.label, FormRenderState.variables)}</FormLabel>
                    <div style={{ color: '#F00' }} className="donation-form-field-error">{errorMessage}</div>
                </FormGroup>
            </div>
        );
    }

}

export const CheckboxFieldStateToProps = (state) => ({
    ...BaseFieldStateToProps(state),
    FormRenderState: state.FormRender,
    FormValueState: state.FormValue
})

export const CheckboxFieldActionsToProps = {
    ...BaseFieldActionsToProps,
    setFormValue
}


export const BootstrappedCheckboxField = connect(CheckboxFieldStateToProps, CheckboxFieldActionsToProps)(CheckboxField);
export default CheckboxField;

import React, { Component } from 'react';
import Toast from "react-bootstrap/Toast";
import { isStage } from "../lib";
import {connect} from 'react-redux'

class StagingAlert extends Component {

    state = {
        show: true
    }

    handleClose() {
        this.setState({
            show: false
        })
    }

    render() {
        if (isStage()) {
            return (
                <div style={{ position: 'absolute', top: 10, right: 10, display: this.state.show ? 'block' : 'none' }}>
                    <Toast show={this.state.show} onClose={() => this.handleClose()}>
                        <Toast.Header>
                            <strong className='mr-auto'>Test Mode</strong>
                        </Toast.Header>
                        <Toast.Body>
                            This form is in test mode and will not process live transactions.
                        </Toast.Body>
                    </Toast>
                </div>
            );
        }

        return null;
    }

}

export default connect()(StagingAlert);
import { FORM_VALUE_SET, FORM_SUBMIT_BUTTON_TEXT } from "../actionTypes";

export function setFormValue(key, value) {
    return {
        type: FORM_VALUE_SET,
        payload: {
            key, value
        }
    }
}

export function setSubmitButtonText(key) {
    return {
        type: FORM_SUBMIT_BUTTON_TEXT,
        payload: { key }
    }
}


import RadioButton, { RadioButtonActionsToProps, RadioButtonStateToProps } from "../BaseFields/RadioButton";
import { connect } from "react-redux";

class RadioFields extends RadioButton {

}

const mapStateToProps = (state) => ({
    ...RadioButtonStateToProps(state)
})

const mapActionsToProps = {
    ...RadioButtonActionsToProps
}

export default connect(mapStateToProps, mapActionsToProps)(RadioFields);
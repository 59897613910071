import React, { Component } from 'react';
import { client, applePay } from 'braintree-web'
import {connect} from 'react-redux';
import {setCanSubmit} from "../../Redux/actions/FormValidationActions";
import {setFormValue} from "../../Redux/actions/FormValueActions";

class BraintreeApplePayForm extends Component {

    state = {
        braintreeClient: null,
        paymentToken: null,
        showError: false,
        errorMessage: null,
        showApplePayButton: false,
        applePayInstance: null
    };

    componentDidMount() {
        const abstraction = window.rkd.form.getAbstractionLayer();
        let currentComponent = this;
        abstraction.registerSubmitTrigger('braintree_applepay', () => this.submit_braintree_applepay())
        if (!window.ApplePaySession) {
            this.setState({errorMessage:'This device does not support Apple Pay'});
            return;
        }
          
        if (!window.ApplePaySession.canMakePayments()) {
            this.setState({errorMessage:'This device is not capable of making Apple Pay payments'});
            return;
        }
        this.setState({
            braintreeClient: client.create({
                authorization: this.props.token
            }, (clientErr, clientInstance) => {
                console.log("clientInstance", clientInstance);
                if (clientErr) {
                    console.error('Error creating client', clientErr);
                    return;
                }
              
                applePay.create({
                    client: clientInstance
                  }, function (applePayErr, applePayInstance) {
                      console.log("applePayInstance", applePayInstance);
                    if (applePayErr) {
                      console.error('Error creating applePayInstance:', applePayErr);
                      return;
                    }
                
                    // Set up your Apple Pay button here
                    currentComponent.setState({showApplePayButton: true, applePayInstance: applePayInstance});
                  });

            })
        });
    }

    componentWillUnmount() {
        const abstraction = window.rkd.form.getAbstractionLayer();
        abstraction.deregisterSubmitTrigger('braintree_applepay');
    }

    submit_braintree_applepay() {
        if(this.state.paymentToken && !this.state.errorMessage){
            console.log('applepay',this.state.paymentToken);
            this.props.setFormValue('applepay_token', this.state.paymentToken.nonce);
        }else{
            this.setState({errorMessage: 'You must complete your payment method for Apple Pay.'}, () => {
                this.props.setCanSubmit(false);
            });
        }
    }

    create_token_apple = () => {

        //set payment amount
        const paymentRequest = this.state.applePayInstance.createPaymentRequest({
          total: {
            label: 'Donation',
            amount: this.props.FormValueState.giftAmount
          }
        });

        console.log("paymentRequest", paymentRequest);
    
        //create a payment session
        console.log("Creating Payment Session");
        let session = new window.ApplePaySession(2, paymentRequest);
    
        //Validate Merchant
        console.log("Validating Merchant");
        session.onvalidatemerchant = (event) => {
          this.state.applePayInstance.performValidation({
            validationURL: event.validationURL,
            displayName: 'Donation'
          }, (validationErr, merchantSession) => {
    
            if (validationErr) {
              // You should show an error to the user, e.g. 'Apple Pay failed to load.'
              console.error('Error validating merchant:', validationErr);
              session.abort();
              return;
            }
    
            session.completeMerchantValidation(merchantSession);
          });
        };
    
        //Autorize Payment
        console.log("Authorizing Payment");
        session.onpaymentauthorized = (event) => {
    
          this.state.applePayInstance.tokenize({
            token: event.payment.token
          }, (tokenizeErr, payload) => {
            if (tokenizeErr) {
              console.error('Error tokenizing Apple Pay:', tokenizeErr);
              session.completePayment(window.ApplePaySession.STATUS_FAILURE);
              return;
            }
    
            session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
    
            // Send payload.nonce to your server.
            this.setState({ paymentToken: payload });
            setTimeout(() => { window.rkd.form.submitForm(); }, 100);
            return;
          });
        };
    
        //start a payment session
        console.log("Starting payment session.");
        session.begin();
    
    }

    render() {

        console.log("this.state.applePayInstance",this.state.applePayInstance);
        const { paymentToken, errorMessage } = this.state;
        const applePayStyle = {
            display: "inline-block",
            borderRadius: "0pt",
            WebkitAppearance: "-apple-pay-button",
            ApplePayButtonType: "donate",
            ApplePayButtonStyle: "black-outline",
            width: "750px",
            height: "55px"
        }

        return (
            <React.Fragment>
                {paymentToken &&
                    <div className='row'>
                        <div className='col-12 mt-3'>
                            <div className='alert alert-success'>We will use Apple Pay to process your payment. You still need to complete the form to process your donation.</div>
                        </div>
                    </div>
                } 
                {!paymentToken && errorMessage &&
                    
                    <React.Fragment>
                        <div className='row'>
                            <div className='col-12 mt-3'>
                                <div className='alert alert-danger'>{errorMessage}</div>
                            </div>
                        </div>
                        <br />
                        <div className="braintree-applePay-amount-text" style={{ textAlign: 'center' }}>Your donation will be in the amount of: ${this.props.FormValueState.giftAmount}</div><br />
                        <div className="text-center">
                            <div id="appleHolder" onClick={this.create_token_apple} className="apple-pay-donate-button apple-pay-donate-button-white-with-line" style={applePayStyle}></div>
                        </div>

                        
                    </React.Fragment>
                    
                } 
                {!paymentToken && !errorMessage &&
                    <React.Fragment>
                        <div className="braintree-applePay-amount-text" style={{ textAlign: 'center' }}>Your donation will be in the amount of: ${this.props.FormValueState.giftAmount}</div><br />
                        <div className="text-center">
                            <div id="appleHolder" onClick={this.create_token_apple} className="apple-pay-donate-button apple-pay-donate-button-white-with-line" style={applePayStyle}></div>
                        </div>
                    </React.Fragment>
                }
        
            </React.Fragment>);
    }

}

const mapStateToProps = state => ({
    FormValueState: state.FormValue
})

const mapActionsToProps = {
    setCanSubmit,
    setFormValue
}

export default connect(mapStateToProps, mapActionsToProps)(BraintreeApplePayForm);
import BaseSocket from "./BaseSocket";
import { getParameterByName } from "../lib";

const FormSocket = {
    socket: null,
    formId: 0,
    init: (formId) => {
        if (!BaseSocket.socket) {
            BaseSocket.init();
        }
        if (FormSocket.socket) {
            if (formId === FormSocket.formId) {
                return FormSocket.socket;
            } else {
                FormSocket.deinit();
            }
        }

        if (!FormSocket.socket) {
            FormSocket.formId = formId;
            FormSocket.socket = BaseSocket.socket.subscribe('form:' + formId + "|" + getParameterByName('cl'));
        }

        return FormSocket.socket;
    },
    deinit: () => {
        if (FormSocket.socket) {
            FormSocket.formId = 0;
            BaseSocket.socket.unsubscribe();
        }
    }
};

export default FormSocket;
import ReactGA from "react-ga";
import TagManager from 'react-gtm-module';
import { setPageGA } from "./Redux/actions/PageActions";
import store from "./Redux/store";
import axios from "axios";

export function getParameterByName(name, url) {
    if (isFalsy(name)) return null;
    let embedParam = null;
    let configParam = null;

    if (window.rkdFormEmbedTag && window.rkdFormEmbedTag.getAttribute(`data-${name}`)) {
        embedParam = window.rkdFormEmbedTag.getAttribute(`data-${name}`);
    }

    if (window.rkdFormConfig && window.rkdFormConfig[name]) {
        configParam = window.rkdFormConfig[name];
    }

    if (!url) url = window.location.href;
    // eslint-disable-next-line no-useless-escape
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return embedParam || configParam || null;
    if (!results[2]) return embedParam || configParam || '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function isIE() {
    var isIE11 = navigator.userAgent.indexOf(".NET CLR") > -1;
    var isIE11orLess = isIE11 || navigator.appVersion.indexOf("MSIE") !== -1;
    return isIE11orLess;
}

export function isTruthy(value) {
    if (typeof value === 'string') {
        value = value.toLowerCase();
    }

    if (typeof value === 'undefined') {
        return false;
    }

    return (
        value === 'on' ||
        value === 'true' ||
        value === true ||
        value === '1' ||
        value === 1 ||
        value === 'recurring' ||
        value === 'yes'
    )
}

export function isFalsy(value) {
    if (typeof value === 'string') {
        value = value.toLowerCase();
    }

    if (typeof value === 'undefined') {
        return true;
    }

    return (
        value === 'off' ||
        value === 'false' ||
        value === false ||
        value === '0' ||
        value === 0 ||
        value === 'single' ||
        value === 'no'
    )
}

export function isPreview() {
    return isTruthy(getParameterByName('preview'));
}

export function isStage() {
    if (process.env.NODE_ENV === 'development' || (process.env.REACT_APP_STAGE && isTruthy(process.env.REACT_APP_STAGE))) {
        return true;
    }

    return isTruthy(getParameterByName("test")) ||
        isTruthy(getParameterByName("preview"));
}

export function renderString(string = '', componentVariables) {
    const FormValueState = store.getState().FormValue;
    if (typeof string !== 'string') {
        return string;
    }
    const VariableState = store.getState().Variable;
    let variables = {};
    if (typeof componentVariables !== 'undefined') {
        if (typeof componentVariables === 'object' && componentVariables instanceof Array) {
            variables = { ...VariableState.globalVariables, ...VariableState.fieldValues };
            componentVariables.forEach(variable => {
                if (variable.key && variable.value) {
                    variables[variable.key] = variable.value;
                }
            });
        } else {
            variables = { ...VariableState.globalVariables, ...componentVariables, ...VariableState.fieldValues };
        }
    }
    else {
        variables = { ...VariableState.globalVariables, ...VariableState.fieldValues };
    }

    const keys = Object.keys(VariableState.variables);
    keys.forEach((key) => {
        variables[key] = VariableState.variables[key];
    });

    const variableKeys = Object.keys(variables);
    variableKeys.forEach((key) => {
        // eslint-disable-next-line no-useless-escape
        string = string.replace(new RegExp("\{" + key + "\}", 'g'), variables[key]);
    });

    // Below line is implemented for replace the {processing-amount} variable with actual value.
    string = string.replace("{processing-amount}", ((typeof FormValueState.calculatedCoverFee !== 'undefined' && FormValueState.calculatedCoverFee !== null && FormValueState.calculatedCoverFee !== "NaN" && FormValueState.calculatedCoverFee !== "") ? "$" + parseFloat(FormValueState.calculatedCoverFee).toFixed(2) : "$" + 0.00));
    return string;
}

// Google analytics funaction
export const isGoogleAnalyticsEnabled = () => {
    const PageState = store.getState().Page;

    return typeof PageState.state[getParameterByName('page')] !== 'undefined'
        && PageState.state[getParameterByName('page')].config.googleAnalytics
        && PageState.state[getParameterByName('page')].config.googleAnalytics !== null
        && PageState.state[getParameterByName('page')].config.googleAnalytics !== ''
        && !isPreview();
}

// Initialize Google analytics with ID
export function initGA(GAId) {
    const PageState = store.getState().Page;
    // if (isGoogleAnalyticsEnabled() && !PageState.isGAIntialized) {
    if (!PageState.isGAIntialized) {
        ReactGA.initialize(GAId);
        store.dispatch(setPageGA(true));
    }
}

export function TriggerGAPageView() {
    const PageState = store.getState().Page;
    if (PageState.isGAIntialized) {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }
}

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
export function TriggerGAEvent(category, action, label) {
    const PageState = store.getState().Page;
    if (PageState.isGAIntialized) {
        ReactGA.event({ category: category, action: action, label: label });
    }
}

// End Google funaction

export function isGoogleTagManagerLoaded(GTMId) {
    if (typeof window.google_tag_manager !== 'undefined' && typeof window.google_tag_manager[GTMId] !== 'undefined') {
        return true;
    }
    return false
}

export function isZTMLoaded() {
    if (typeof window.zGetGoogleId == 'function' || document.getElementById('zeustagman')) {
        return true;
    }
    return false
}

export function initZTM(client) {
    let environment = 'production'
    if (isTruthy(getParameterByName('ztm-debug'))) {
        console.log('--ZeusTagManager-- development');
        environment = 'staging';
    }
    (function (d, m, cl, env) {
        var h = d.getElementsByTagName('head')[0];
        var script = d.createElement(m); script.src = `https://public.charitable.one/assets/scripts/tag_manager/${env}/zeus-tagman.js?tagmanid=${cl}&cl=${cl}&env=${env}`;
        script.defer = true; script.async = true; script.id = 'zeustagman';
        h.appendChild(script);
    })(document, 'script', client.toUpperCase(), environment);
}

export function renderEcommerceJson(ReceiptState, FormRenderState) {
    const { data } = ReceiptState;
    const variant = (typeof getParameterByName('type') !== 'undefined' && getParameterByName('type') === 'recurring') ? 'monthly' : 'one-time';
    const formId = (typeof getParameterByName('df') !== 'undefined') ? getParameterByName('df') : null;
    const campaign_name = FormRenderState.options && FormRenderState.options.campaign ? FormRenderState.options.campaign : null;
    const amount = parseFloat(data.giftAmount);
    window.ecommerceJson = null;
    if (Object.keys(data).length > 0) {
        try {
            window.ecommerceJson = {
                transactionId: data.transactionId,
                variant,
                name: FormRenderState.formName,
                formId,
                campaign_name,
                amount,
                paymentMethod: data.paymentType
            }
        } catch (err) {
            console.log('error ', err);
        }

    }
}

export function getFormIdFromPage() {
    let formId = 'none';
    if (typeof getParameterByName('df') !== 'undefined' && getParameterByName('df') !== null) {
        return String(getParameterByName('df'));
    }
    const PageState = store.getState().Page.state;
    let resultSearch = PageState.filter(element => element.config && element.config.form);
    if (resultSearch.length > 0) {
        formId = resultSearch[0].config.form;
    }
    return String(formId);

}

export function renderEcommerceDatalayer(ReceiptState, FormRenderState) {
    const { data } = ReceiptState;
    const variant = (typeof getParameterByName('type') !== 'undefined' && getParameterByName('type') === 'recurring') ? 'monthly' : 'one-time';
    const formId = getFormIdFromPage();
    const campaign_name = FormRenderState.options && FormRenderState.options.campaign ? FormRenderState.options.campaign : '';
    const amount = parseFloat(data.giftAmount);
    window.dataLayer = window.dataLayer || [];
    if (Object.keys(data).length > 0) {
        try {
            let formName = FormRenderState.formName ? FormRenderState.formName : 'none';
            // UA Ecommerce dataLayer
            window.dataLayer.push({
                'transactionId': data.transactionId,
                'transactionTotal': amount,
                'transactionProducts': [{
                    'sku': formId,
                    'name': `FBC-${formName}`,
                    'category': campaign_name,
                    'price': amount,
                    'quantity': 1
                }]
            });
            // GA4 Ecommerce dataLayer
            window.dataLayer.push({
                'event': 'purchase',
                'ecommerce': {
                    'transaction_id': data.transactionId,
                    'value': amount,
                    'currency': 'USD',
                    'items': [{
                        'item_name': `FBC-${formName}`,
                        'item_id': formId,
                        'price': amount,
                        'item_category': campaign_name,
                        'item_variant': variant,
                        'quantity': 1
                    }]
                }
            });

            try {
                // ZTM ABS
                window.ztmTargetProxy.dmc_success = {
                    'id': data.transactionId,
                    'donationType': variant,
                    'pid': formId,
                    'df_name': campaign_name,
                    'amount': amount,
                    'method': data.paymentType,
                };
            } catch (error) {
                console.log('error:ztmTargetProxy.dmc_success', error);
            }

        } catch (err) {
            console.log('error ', err);
        }

    }
}

// Google Tag Manager
export function initTagManager(GTMId) {
    TagManager.initialize({ gtmId: GTMId })
}
// End Google Tag Manager

export const isFullWidth = (label) => {
    const PageState = store.getState().Page;

    return typeof PageState.state[getParameterByName('page')] !== 'undefined'
        && typeof PageState.state[getParameterByName('page')].config !== 'undefined'
        && PageState.state[getParameterByName('page')].config[label]
        && PageState.state[getParameterByName('page')].config[label] !== null
        && PageState.state[getParameterByName('page')].config[label] !== '';
}

// Function will replace the space with the underscore
export function spaceWithUnderscore(str) {
    return (str !== undefined && str !== "") ? str.replace(/ /g, "_").toLowerCase() : str;
}

// Function will check that enter amount is valid or not
export function checkValidAmount(amount) {
    let regex = /^\d+(\.\d{1,2})?$/
    return (typeof amount === "undefined" || amount === 0 || amount === "0" || amount.trim() === "" || !parseFloat(amount) > 0 || !regex.test(amount)) ? false : true;
}

// Function will replace the {label} with value
export function replaceLabelWithValue(str, value) {
    return (str !== undefined && str !== "") ? str.replace(/\{label}/g, value) : str;
}

// Function will set the error message.
export function getErrorMessage(errMsg, params, isRequired = false) {
    if (isRequired) {
        return typeof params.options.requiredErrorMessage !== 'undefined' ? replaceLabelWithValue(params.options.requiredErrorMessage, params.options.label) : `${params.options.label} ${errMsg}`;
    }
    else {
        return typeof params.options.standardErrorMessage !== 'undefined' ? replaceLabelWithValue(params.options.standardErrorMessage, params.options.label) : `${params.options.label}  ${errMsg}`;
    }
}

// Function will return the default validation message
export function getDynamicErrorMessage(type = "") {
    const GS = store.getState().GlobalSettings;
    let str = "";
    if (typeof GS.errorMessage !== "undefined" && GS.errorMessage !== "" && GS.errorMessage !== "{}") {
        let errors = JSON.parse(GS.errorMessage);
        str = typeof errors.general !== "undefined" && errors.general !== "" ? errors.general : "Please see the areas highlighted. We greatly appreciate your donation and apologize for this inconvenience!";
        if (type === "amount") {
            str += "<br />";
            str += typeof errors.amount !== "undefined" && errors.amount !== "" ? errors.amount : "Please enter a valid donation amount.";
        } else if (type === "payment") {
            str += "<br />";
            str += typeof errors.payment !== "undefined" && errors.payment !== "" ? errors.payment : "Please enter valid payment information.";
        }
    } else {
        str = 'Please see the areas highlighted. We greatly appreciate your donation and apologize for this inconvenience!';
        if (type === "amount") {
            str = str + "<br /> Please enter a valid donation amount.";
        } else if (type === "payment") {
            str += "<br /> Please enter valid payment information.";
        }
    }
    return str;
}

export  async function getIdByPageName(){
    if(!getParameterByName('page')){
        let pageName =  getParameterByName('pg')
        const protocol = process.env.NODE_ENV === 'development' ? 'http://' : 'https://'; 
        const encodedPageName = encodeURIComponent(pageName);
        const requestData = await axios.get(`${protocol}${process.env.REACT_APP_API_HOST}/page/getIdByPageName/${encodedPageName}`);
        console.log("requestdata",requestData.data.id);
        return requestData.data.id
      }
      return getParameterByName('page');
}
import React from "react";
import { renderString } from "../../lib";
import {connect} from 'react-redux'

import {
    FormGroup,
    FormLabel,
} from "react-bootstrap";
import BaseField, {BaseFieldActionsToProps, BaseFieldStateToProps} from "./BaseField";

class ChildField extends BaseField{

    constructor(props) {
        super(props);
        this.state = {
            errorMessage: props.error,
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({errorMessage: nextProps.error});
    }

    render(){
        const errorMessage = this.getRenderedValidationMessage();
        let options = {};
        if(this.props.formattedOptions){
            options = this.props.formattedOptions;
        }else {
            this.props.options.forEach((option) => {
                options[option.field] = option.value || option.default;
            });
        }

        return (
            <div className={this.getTemplateClasses()} style={this.getGeneratedStyles()}>
                <FormGroup>
                    <FormLabel className={options.required ? 'required required-field' : ''}>{renderString(options.label, this.props.FormRenderState.variables)}</FormLabel>
                    {this.props.children}
                    <div style={{color: '#F00'}} className="donation-form-field-error">{errorMessage || this.props.error || this.state.errorMessage}</div>
                </FormGroup>
            </div>
        );
    }

}

export const ChildFieldStateToProps = (state) => ({
    ...BaseFieldStateToProps(state),
    FormRenderState: state.FormRender
})

export const ChildFieldActionsToProps = {
    ...BaseFieldActionsToProps
}

export const BootstrappedChildField = connect(ChildFieldStateToProps, ChildFieldActionsToProps)(ChildField)
export default ChildField;


// Component Actions
export const COMPONENT_INIT_SOCKET = "componentInitSocket";

//Form ID Value Actions
export const FORM_ID_VALUE_SET = "formIdValueSet";

// Form Render Actions
export const FORM_RENDER_INIT_SOCKET = "formRenderInitSocket";
export const FORM_RENDER_SET_LOADING = "formRenderSetLoading";
export const FORM_RENDER_SET_ERROR = "formRenderSetError";
export const FORM_RENDER_SET_PAGE = "formRenderSetPage";
export const FORM_RENDER_SET_CSS = "formRenderSetCSS";
export const FORM_RENDER_SET_THEME = "formRenderSetTheme";
export const FORM_RENDER_SET_PAYMENTPROVIDERS = 'formRenderSetPaymentProviders';
export const FORM_RENDER_SET_PAYMENTFAILURE = "formRenderSetPaymentFailure";
export const FORM_RENDER_SET_INVALIDAMOUNT = "formRenderSetInvalidAmount";
export const FORM_RENDER_SET_GENERALERRORS = "formRenderSetGeneralErrors";
export const FORM_RENDER_SET_SKIPPEDSTEPS = "formRenderSetSkippedSteps";

// Form Validation Actions
export const FORM_VALIDATION_SET_ITEM = 'formValidationSetItem';
export const FORM_VALIDATION_SET_CAN_SUBMIT = 'formValidationSetCanSubmit';
export const FORM_VALIDATION_SET_VALIDATOR = 'formValidationSetValidator';
export const FORM_VALIDATION_SET_INVALIDELEMENTS = 'formValidationSetInvalidElements';

// Form Value Actions
export const FORM_VALUE_SET = 'formValueSet';
export const FORM_SUBMIT_BUTTON_TEXT = 'formSubmitButtonTextSet';


// Global Settings Actions
export const GLOBAL_SETTINGS_INIT = 'globalSettingsInit';
export const GLOBAL_SETTINGS_SET_PAYMENTPROVIDERS = 'globalSettingsSetPaymentProviders';
export const GLOBAL_SETTINGS_SET_ERRORNATTEMPTS = 'globalSettingsSetErrorNAttempts';

// Meta Actions
export const META_SET_CLIENT = 'metaSetClient';

// Page Actions
export const PAGE_INIT_SOCKET = 'pageInitSocket';
export const PAGE_SET_GA = 'pageSetGA';

// Receipt Actions
export const RECEIPT_SET_DATA = 'receiptSetData';

// Stored Client Actions
export const STORED_CLIENT_SET_VALID = 'storedClientSetValid';
export const STORED_CLIENT_SET_DATA = 'storedClientSetData';
export const STORED_CLIENT_SET_TRIGGERS = 'storedClientSetTriggers';

// Trigger Actions
export const TRIGGERS_SET_TRIGGERS = 'triggersSetTriggers';
export const TRIGGER_SET_TEMP_NAME = 'triggerSetTempName';
export const TRIGGER_UPDATE_STATE = 'triggerUpdateState';
export const TRIGGER_SUBMIT_SET = 'triggerSubmitSet';

// Variable Actions
export const VARIABLES_INIT = 'variablesInit';
export const VARIABLES_SET_FIELD_VALUES = 'variablesSetFieldValues';
export const VARIABLES_SET_VALUE = 'variablesSetValue';
export const VARIABLES_SET_ALL = 'variablesSetAll';

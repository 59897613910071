import {META_SET_CLIENT} from "../actionTypes";

const initialState = {
    client: []
};

export default function(state = initialState, action){
    switch (action.type){
        case META_SET_CLIENT:
            return {
                ...state,
                client: action.payload
            }
        default:
            return state;
    }
}
import DMC from "../Abstractions/DMC";
import store from "../Redux/store";
import { setFormCSS, setFormLoading, setFormPage, setFormTheme, setError, setInvalidAmount, setGeneralErrors } from "../Redux/actions/FormRenderActions";
import { setFormValue } from "../Redux/actions/FormValueActions";
import { GLOBAL_SETTINGS_SET_ERRORNATTEMPTS, FORM_RENDER_SET_ERROR } from "../Redux/actionTypes";
import { checkValidAmount, getDynamicErrorMessage, getParameterByName } from "../lib";
import moment from "moment";
import 'moment-timezone';


window.rkd = window.rkd || {};

window.rkd.form = window.rkd.form || {
    eventHandlers: {},
    platform: 'DMC',

    // When new platforms become available, add them to this object.
    platforms: {
        'DMC': new DMC()
    },

    setPlatform: (platform) => {
        if (typeof window.rkd.form.platforms[platform] !== 'undefined') {
            window.rkd.form.platform = platform;
            window.rkd.form.platforms[window.rkd.form.platform].init();
        } else {
            throw new Error(`Unknown platform '${platform}', keeping current '${window.rkd.form.platform}'`);
        }
    },

    getAbstractionLayer: () => {
        return window.rkd.form.platforms[window.rkd.form.platform];
    },

    // Checks to make sure either the whole form is valid or a subset of items are valid (Can be called globally)
    isValid: (keys = []) => {
        const FormValidationState = store.getState().FormValidation;
        const FormValueState = store.getState().FormValue;
        let validations;

        if (keys.length === 0) {
            validations = Object.values(FormValidationState.validators);
            let key = 0;
            let isValid = true;
            while (key < validations.length) {
                if (!validations[key]()) {
                    isValid = false;
                }
                key++;
            }
            return isValid;
        } else {
            let key = 0;
            let isValid = true;
            // there is some issue with giftAmount update, here we've used donationArrayBtn value
            let isValidAmount = (Number(FormValueState.donationArrayBtn) >= Number(FormValueState.giftMinAmountValue)) ? true : false;            
            while (key < keys.length) {
                if (keys[key] === "donationArrayBtn" && (!checkValidAmount(FormValueState.donationArrayBtn) || !isValidAmount)) {
                    isValid = false;
                   let errorMessage = getDynamicErrorMessage();
                    window.rkd.form.setError(errorMessage);
                    if (document.getElementsByClassName('donation-form-body').length > 0) {
                        setTimeout(() => {
                            document.getElementsByClassName('donation-form-body')[0].scrollIntoView(true);
                        }, 100);
                    }
                    else {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }

                    store.dispatch(setInvalidAmount(true));
                }
                else if (typeof FormValidationState.validators[keys[key]] !== 'undefined' && !FormValidationState.validators[keys[key]]()) {
                    isValid = false;
                    let errorMessage = getDynamicErrorMessage();
                    window.rkd.form.setError(errorMessage);
                    if (document.getElementsByClassName('donation-form-body').length > 0) {
                        setTimeout(() => {
                            document.getElementsByClassName('donation-form-body')[0].scrollIntoView(true);
                        }, 100);
                    }
                    else {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }

                    store.dispatch(setInvalidAmount(false));
                    store.dispatch(setGeneralErrors(true));
                }
                key++;
            }

            if (isValid) {
                window.rkd.form.setError('');
                store.dispatch(setInvalidAmount(false));
                store.dispatch(setGeneralErrors(false));
            }
            return isValid;
        }
    },
    // Gets the value of the specified form item (Can be called globally)
    getValue: (key) => {
        return store.getState().FormValue[key] || null;
    },
    // Gets the value of the multiple form items (Can be called globally)
    getValues: (keys = []) => {
        const newValues = [];
        keys.forEach((key) => {
            newValues[key] = store.getState().FormValue[key] || null;
        });
        return newValues
    },
    // Sets the value of the specified form item (Can be called globally)
    setValue: (key, value) => {
        store.dispatch(setFormValue(key, value));
    },
    // Sets values to the specified object (Can be called globally)
    setValues: (values = {}) => {
        const keys = Object.keys(values);
        keys.forEach((key) => {
            store.dispatch(setFormValue(key, values[key]));
        });
    },
    // Proceeds to the next step of the form if there is a next step, will validate the step if it needs to (Can be called globally)
    nextStep: (ignoreValidation = false) => {
        const FormRenderState = store.getState().FormRender;
        const formItems = FormRenderState.pages[FormRenderState.activeStep].components.map((component) => {
            let options = {};
            component.options.forEach((option) => {
                options[option.field] = option.value || option.default;
            });
            return options.name;
        });

        let isValid;
        if (ignoreValidation) {
            isValid = true;
        } else {
            isValid = window.rkd.form.isValid(formItems);
        }

        if (isValid && FormRenderState.activeStep < FormRenderState.pages.length - 1) {
            const prevStep = FormRenderState.activeStep;
            let tmpStep = FormRenderState.activeStep + 1;
            const skippedSteps = FormRenderState.skippedSteps;
            while(skippedSteps.includes(tmpStep)) {
                tmpStep++;
            }
            FormRenderState.activeStep = tmpStep;
            store.dispatch(setFormPage(FormRenderState.activeStep));
            window.rkd.form.triggerEvent('stepChange', {
                previousStep: prevStep,
                step: FormRenderState.activeStep
            });
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    },
    // Proceeds to the next step of the form if there is a next step (Can be called globally)
    previousStep: () => {
        const FormRenderState = store.getState().FormRender;
        if (FormRenderState.activeStep > 0) {
            const prevStep = FormRenderState.activeStep;
            let tmpStep = FormRenderState.activeStep - 1;
            const skippedSteps = FormRenderState.skippedSteps;
            while(skippedSteps.includes(tmpStep)) {
                tmpStep--;
            }
            FormRenderState.activeStep = tmpStep;
            store.dispatch(setFormPage(FormRenderState.activeStep));
            window.rkd.form.triggerEvent('stepChange', {
                previousStep: prevStep,
                step: FormRenderState.activeStep
            });
            window.rkd.form.setError('');
            store.dispatch(setGeneralErrors(false));
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    },
    // Submits the form, will usually be called internally
    submitForm: async (ignoreValidation = false) => {
        const FormRenderState = store.getState().FormRender;
        const FormValueState = store.getState().FormValue;
        console.log(`FB - submitForm - Set the loader to 'true'. Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);
        store.dispatch(setFormLoading(true));
        let formItems = [];
        let formItemsStoredPayment = [];
        FormRenderState.pages.forEach(page => {
            page.components.forEach(component => {
                let options = {};
                options['custom'] = component.custom ? component.custom : false;
                component.options.forEach((option) => {
                    options[option.field] = option.value || option.default;
                });

                if (options.hideOnStoredClient === 'No') {
                    formItemsStoredPayment.push(options.name);
                }

                formItems.push(options.name);
            })
        });

        let isValid;
        if (ignoreValidation) {
            console.log(`FB - submitForm - Ignore validation. Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);
            isValid = true;
        } else if (store.getState().StoredClient.valid) {
            console.log(`FB - submitForm - Check validation for stored donor. Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);
            isValid = window.rkd.form.isValid(formItemsStoredPayment);
        } else {
            console.log(`FB - submitForm - Check validation for form data. Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);
            isValid = window.rkd.form.isValid(formItems);
        }

        console.log(`FB - submitForm - ${isValid ? ` Form is valid` : `Form is invalid`}. Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);
        if (isValid) {
            store.dispatch({
                type: FORM_RENDER_SET_ERROR,
                payload: ''
            });
            console.log(`FB - submitForm - Call the submit. Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);
            await window.rkd.form.platforms[window.rkd.form.platform].submit();

            window.rkd.form.triggerEvent('formSubmit', {})
        } else {
            store.dispatch(setFormLoading(false));

            let errorMessage = getDynamicErrorMessage();

            let isValidAmount = (Number(FormValueState.donationArrayBtn) >= Number(FormValueState.giftMinAmountValue)) ? true : false;
            if(!checkValidAmount(FormValueState.donationArrayBtn) || !isValidAmount) {
                store.dispatch(setInvalidAmount(true));
                // errorMessage = errorMessage + ' <br /> Please enter a valid donation amount';
                errorMessage = getDynamicErrorMessage('amount');
            }

            store.dispatch({
                type: FORM_RENDER_SET_ERROR,
                payload: errorMessage
            });
            window.scrollTo({ top: 0, behavior: 'smooth' });
            window.rkd.form.setErrorAttempts();
        }

    },
    // Sets entire css string(Can be called globally)
    setCss: (css) => {
        store.getState().FormRender.customCSS = css;
    },
    // appends to the current css string (Can be called globally)
    appendCss: (css) => {
        store.dispatch(setFormCSS(store.getState().FormRender.customCSS + css));
    },
    // Changes the current theme the form is using (Can be called globally)
    setTheme: (theme) => {
        store.dispatch(setFormTheme(theme));
    },
    // Sets up event handlers to be called by the form when something happens (Can be called globally)
    on: (event, method) => {
        if (!window.rkd.form.eventHandlers[event]) {
            window.rkd.form.eventHandlers[event] = [];
        }
        window.rkd.form.eventHandlers[event].push(method);
    },
    // Triggers an event within the form, should only be called internally.
    triggerEvent: (event, data) => {
        if (window.rkd.form.eventHandlers[event]) {
            let handlerIndex = 0;
            const length = window.rkd.form.eventHandlers[event].length;
            while (handlerIndex < length) {
                window.rkd.form.eventHandlers[event][handlerIndex](data);
                handlerIndex++;
            }
        }
    },
    setErrorAttempts: () => {
        if (store.getState().GlobalSettings.lightboxIfDonationErrorAfterNAttempts.enabled) {
            const FormValueState = store.getState().FormValue;            
            if (typeof FormValueState[store.getState().GlobalSettings.lightboxIfDonationErrorAfterNAttempts.name] !== 'undefined') {
                //change to search the trigeer index
                const lightboxIfDonationErrorAfterNAttempts = store.getState().GlobalSettings.lightboxIfDonationErrorAfterNAttempts;
                const LIDEANA_INDEX = lightboxIfDonationErrorAfterNAttempts.index;
                const trigger = store.getState().FormRender.triggers.find(obj => obj.id === LIDEANA_INDEX);
                const LIDEANA_NAME = lightboxIfDonationErrorAfterNAttempts.name;
                let LIDEANA = lightboxIfDonationErrorAfterNAttempts.counter;
                if (!LIDEANA) {
                    store.dispatch(setFormValue(LIDEANA_NAME, '1'));
                }
                if (LIDEANA !== '') {
                    let expression = trigger.expression.replace('Lightbox_if_Donation_Error_after_n_attempts', `"${LIDEANA}"`);
                    console.log('expression: ', expression);
                    if (eval(expression)) {
                        store.dispatch(setFormValue(LIDEANA_NAME, lightboxIfDonationErrorAfterNAttempts.attempts));
                        window.rkd.form.triggerEvent('internal_change_Lightbox-if-Donation-Error-after-n-attempts',
                            {
                                action: trigger.action,
                                triggerIndex: LIDEANA_INDEX,
                                trigger: trigger
                            }
                        );
                        // check if there are more triggers for the ligthbox
                        if (lightboxIfDonationErrorAfterNAttempts.nCases.length > 0) {
                            const nextTrigger = lightboxIfDonationErrorAfterNAttempts.nCases.pop();
                            lightboxIfDonationErrorAfterNAttempts.attempts = nextTrigger.attempts;
                            lightboxIfDonationErrorAfterNAttempts.index = nextTrigger.index;

                            let attempt = parseInt(LIDEANA) + 1;
                            lightboxIfDonationErrorAfterNAttempts.counter = attempt + '';

                            store.dispatch({
                                type: GLOBAL_SETTINGS_SET_ERRORNATTEMPTS,
                                payload: lightboxIfDonationErrorAfterNAttempts
                            });
                        }
                    }
                    else {
                        let attempt = parseInt(LIDEANA) + 1;
                        lightboxIfDonationErrorAfterNAttempts.counter = attempt + '';
                        store.dispatch({
                            type: GLOBAL_SETTINGS_SET_ERRORNATTEMPTS,
                            payload: lightboxIfDonationErrorAfterNAttempts
                        });
                    }
                }
            }
        }
    },
    setError: (errorMessage) => {
        store.dispatch(setError(errorMessage));
    }
};
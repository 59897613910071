import {
    FORM_RENDER_INIT_SOCKET, FORM_RENDER_SET_CSS,
    FORM_RENDER_SET_ERROR,
    FORM_RENDER_SET_LOADING,
    FORM_RENDER_SET_PAYMENTPROVIDERS,
    FORM_RENDER_SET_PAGE,
    FORM_RENDER_SET_THEME,
    FORM_RENDER_SET_PAYMENTFAILURE,
    FORM_RENDER_SET_INVALIDAMOUNT,
    FORM_RENDER_SET_GENERALERRORS,
    FORM_RENDER_SET_SKIPPEDSTEPS
} from "../actionTypes";
import { isIE, isPreview, renderString, initGA, initTagManager, isGoogleTagManagerLoaded, getParameterByName, isZTMLoaded, initZTM } from "../../lib";

import axios from "axios";
import FormSocket from "../../Sockets/FormSocket";
import store from "../store";


async function parseData(data) {
    const payload = {};

    payload.formName = data.formName;
    payload.variables = {};
    if (typeof data.formName === "undefined" || data.isDeleted === 1) {
        payload.isLoaded = true;
        payload.pages = [];
    } else {
        payload.pages = (data.pages !== undefined) ? data.pages : [];
        if (typeof data.variables !== "undefined") {
            data.variables.forEach((variable) => {
                payload.variables[variable.key] = variable.value;
            });
        }
        payload.confirmationFields = data.confirmationFields;
        payload.thankYouPage = data.thankYouPage || [];
        payload.uniqueComponents = data.uniqueComponents;
        // set Google ReCaptch Site Key
        const abstraction = window.rkd.form.getAbstractionLayer();
        let objMeta = await abstraction.loadClientMeta();

        // check GA or GTM neither is configured or not
        if (typeof objMeta.analytics.googleID !== 'undefined' && objMeta.analytics.googleID !== '') {
            initGA(objMeta.analytics.googleID);
            //TriggerGAPageView();
        } else if (typeof objMeta.analytics.googleGTM !== 'undefined' && objMeta.analytics.googleGTM !== '' && !isGoogleTagManagerLoaded(objMeta.analytics.googleGTM)) {
            initTagManager(objMeta.analytics.googleGTM);
        }

        //loadZTM
        if(objMeta?.is_Identity && !isZTMLoaded()){
            initZTM(objMeta.acronym.toUpperCase());
        }

        if (data.recaptcha) {
            payload.googleRecaptcha = true;
            payload.recaptchaSiteKey = objMeta?.recaptchaVersion == 'v3' ? objMeta.recaptchaSiteKey_v3 : objMeta.recaptchaSiteKey;
        } else {
            payload.googleRecaptcha = false;
            payload.recaptchaSiteKey = '';
        }

        data.customCSS = renderString(data.customCSS, payload.variables);
        data.customJS = renderString(data.customJS, payload.variables);
        if (isPreview()) {
            payload.paymentProviders = store.getState().GlobalSettings.paymentProviders.preview;
        }
        else {
            payload.paymentProviders = store.getState().GlobalSettings.paymentProviders.live;
        }

        payload.options = data.options || {};
        payload.triggers = data.triggers || [];
        payload.modals = data.modals || [];
        if (payload.customCSS === data.customCSS) {
            payload.rerenderCSS = false;
        } else {
            payload.customCSS = data.customCSS;
            payload.rerenderCSS = true;
        }
        if (payload.customJS === data.customJS) {
            payload.rerenderJS = false;
        } else {
            payload.customJS = data.customJS;
            payload.rerenderJS = true;
        }
        payload.isLoaded = true;
        payload.DISSetting = data.DISSetting || false;
        payload.DISRules = data.DISRules || [];
    }

    return payload;
}

export const initFormRenderSocket = async (formId) => {
    const { dispatch } = store;
    if (isPreview() && !isIE()) {
        if (!store.getState().FormRender.socket) {
            const socket = FormSocket.init(formId);
            socket.on('serverUpdate', async (data) => {
                const payload = await parseData(data);

                await dispatch({
                    type: FORM_RENDER_INIT_SOCKET,
                    payload
                });
                window.rkd.form.triggerEvent("form_render", {})
                await callImpression();
            });
        }
    } else {
        if (!store.getState().FormRender.requestData) {
            await dispatch({
                type: FORM_RENDER_SET_LOADING,
                payload: true
            });

            const protocol = process.env.NODE_ENV === 'development' ? 'http://' : 'https://';
            const showPreview = isPreview() ? "true" : "false";
            const requestData = await axios.get(`${protocol}${process.env.REACT_APP_API_HOST}/form/${formId}?preview=${showPreview}&cl=${getParameterByName('cl')}`);
            const payload = await parseData(requestData.data);
            payload.requestData = requestData.data;
            payload.isLoading = false;
            
            await dispatch({
                type: FORM_RENDER_INIT_SOCKET,
                payload
            });

            window.rkd.form.triggerEvent("form_render")
            await callImpression();
        }
    }
}

export const callImpression = async () => {
    if(!getParameterByName('receipt')) {
        const abstraction = window.rkd.form.getAbstractionLayer();
        await abstraction.userImpression();
        await abstraction.impression();
    }
}

export const setFormLoading = isLoading => {
    return {
        type: FORM_RENDER_SET_LOADING,
        payload: isLoading
    }
}

export const setError = error => {
    return {
        type: FORM_RENDER_SET_ERROR,
        payload: error
    }
};

export const setFormPage = page => {
    return {
        type: FORM_RENDER_SET_PAGE,
        payload: page
    }
}

export const setFormCSS = css => {
    return {
        type: FORM_RENDER_SET_CSS,
        payload: css
    }
}

export const setFormTheme = theme => {
    return {
        type: FORM_RENDER_SET_THEME,
        payload: theme
    }
}

export const setPaymentProviders = paymentProviders => {
    return {
        type: FORM_RENDER_SET_PAYMENTPROVIDERS,
        payload: paymentProviders
    }
}

export const setPaymentFailure = paymentFailure => {
    return {
        type: FORM_RENDER_SET_PAYMENTFAILURE,
        payload: paymentFailure
    }
}

export const setInvalidAmount = invalidAmount => {
    return {
        type: FORM_RENDER_SET_INVALIDAMOUNT,
        payload: invalidAmount
    }
}

export const setGeneralErrors = generalErrors => {
    return {
        type: FORM_RENDER_SET_GENERALERRORS,
        payload: generalErrors
    }
}

export const setSkippedSteps = skippedSteps => {
    return {
        type: FORM_RENDER_SET_SKIPPEDSTEPS,
        payload: skippedSteps
    }
}

export const getDonorSummary = async(gaid) => {
    try {
        // const abstraction = window.rkd.form.getAbstractionLayer();
        // let gaid = await abstraction.getGAID();
        // let gaid = '208593700.1627422420';
        console.log('gaid: ', gaid);
        if(gaid) {
            const requestData = await axios.get(`https://lookup.dmc.systems/donor-summary?gaid=${gaid}`);
            if(requestData.data.status === 'success') {
                return requestData.data.message;
            }
            return false;            
        }

    } catch (error) {
        console.log('something went wrong while fetching donor summary')
        return false;
    }
}
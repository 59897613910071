import { GLOBAL_SETTINGS_INIT, GLOBAL_SETTINGS_SET_PAYMENTPROVIDERS, FORM_RENDER_SET_PAYMENTPROVIDERS } from "../actionTypes";
import { getParameterByName, isIE, isPreview } from "../../lib";
import axios from "axios";
import GlobalSettingsSocket from "../../Sockets/GlobalSettingsSocket";
import store from "../store";

function formRenderSetPaymentProviders(paymentProviders) {
    return {
        type: FORM_RENDER_SET_PAYMENTPROVIDERS,
        payload: paymentProviders
    }
}

function parseData(data, componentId) {
    const payload = {};
    data.forEach(variable => {
        if (variable.config.includes('paymentProviders-preview') ||
            variable.config.includes('paymentProviders-live')) {
            let paymentProviders = {};
            paymentProviders = store.getState().GlobalSettings.paymentProviders;
            try {
                var type = '';
                if (variable.config.includes('preview')) {
                    type = variable.config.replace('paymentProviders-preview-', '');
                    paymentProviders['preview'][type] = JSON.parse(variable.value);
                } else {
                    type = variable.config.replace('paymentProviders-live-', '');
                    paymentProviders['live'][type] = JSON.parse(variable.value);
                }
            } catch (error) {

            }
            payload['preview'] = paymentProviders.preview;
            payload['live'] = paymentProviders.live;
            // console.log(payload);
        }
        else {
            payload[variable.config] = variable.value;
        }
    });
    return payload;

}

export const initGlobalSettingsSocket = async () => {
    const { dispatch } = store;
    if (isPreview() && !isIE()) {
        if (!store.getState().GlobalSettings.socket) {
            const socket = GlobalSettingsSocket.init();
            socket.on('serverUpdate', async data => {
                await dispatch({
                    type: GLOBAL_SETTINGS_INIT,
                    payload: {
                        ...parseData(data),
                        socket
                    }
                });
                await dispatch({
                    type: GLOBAL_SETTINGS_SET_PAYMENTPROVIDERS,
                    payload: parseData(data)
                });
                // console.log("GlobalSettingsActions->initGlobalSettingsSocket", store.getState().GlobalSettings.paymentProviders);
                await store.dispatch(formRenderSetPaymentProviders(store.getState().GlobalSettings.paymentProviders.preview));
            });
        }
    } else {
        if (!store.getState().GlobalSettings.requestData) {
            const protocol = process.env.NODE_ENV === 'development' ? 'http://' : 'https://';
            const requestData = await axios.get(`${protocol}${process.env.REACT_APP_API_HOST}/globalSettings?client=${getParameterByName('cl')}`);
            await dispatch({
                type: GLOBAL_SETTINGS_INIT,
                payload: {
                    ...parseData(requestData.data),
                    requestData: requestData.data
                }
            });
            await dispatch({
                type: GLOBAL_SETTINGS_SET_PAYMENTPROVIDERS,
                payload: parseData(requestData.data)
            });
            // console.log("GlobalSettingsActions->initGlobalSettingsSocket");
            await store.dispatch(formRenderSetPaymentProviders(store.getState().GlobalSettings.paymentProviders.live));
        }
    }
}


import { initGA, initTagManager, isIE, isPreview, renderString, TriggerGAPageView, isGoogleTagManagerLoaded, isZTMLoaded, initZTM, getParameterByName } from "../../lib";
import store from "../store";
import { PAGE_INIT_SOCKET, PAGE_SET_GA } from "../actionTypes";
import axios from "axios";
//import VariableState from "../../State/VariableState";
import PageSocket from "../../Sockets/PageSocket";
import { variablesSetAll } from "./VariableActions";


async function parseData(data, pageId) {
    const payload = {};
    payload.isDeleted = data.isDeleted;

    payload.state = [store.getState().Page.state];
    payload.state[pageId] = data;


    const newVariables = {};
    if (typeof data.variables !== 'undefined') {
        data.variables.forEach((variable) => {
            newVariables[variable.key] = variable.value;
        });
    }
    store.dispatch(variablesSetAll(newVariables));

    if (data.css) {
        data.css = renderString(data.css, [])
    }
    if (data.javascript) {
        data.javascript = renderString(data.javascript, [])
    }

    const abstraction = window.rkd.form.getAbstractionLayer();
    let objMeta = await abstraction.loadClientMeta();

    // check GA or GTM neither is configured or not
    if (typeof objMeta.analytics.googleID !== 'undefined' && objMeta.analytics.googleID !== '') {
        initGA(objMeta.analytics.googleID);
        TriggerGAPageView();
    } else if (typeof objMeta.analytics.googleGTM !== 'undefined' && objMeta.analytics.googleGTM !== '' && !isGoogleTagManagerLoaded(objMeta.analytics.googleGTM)) {
        initTagManager(objMeta.analytics.googleGTM);
    }

    //loadZTM
    if(objMeta?.is_Identity && !isZTMLoaded()){
        console.log('loading ztm');
        initZTM(objMeta.acronym.toUpperCase());
    }

    payload.isLoaded = true;

    return payload;
}

export const initPageSocket = (pageId) => {
    return async function (dispatch) {
        if (isPreview() && !isIE()) {
            if (!PageSocket.sockets[pageId]) {
                PageSocket.init(pageId);
                
                PageSocket.sockets[pageId].on('serverUpdate', async (data) => {
                    const parsedData = await parseData(data, pageId);

                    dispatch({
                        type: PAGE_INIT_SOCKET,
                        payload: {
                            ...parsedData,
                            pageId,
                            isLoaded: true
                        }
                    });
                });
            }
        } else {
            if (!store.getState().Page.requestData[pageId]) {
                const protocol = process.env.NODE_ENV === 'development' ? 'http://' : 'https://';
                const showPreview = isPreview() ? "true" : "false";
                const requestData = await axios.get(`${protocol}${process.env.REACT_APP_API_HOST}/page/${pageId}?preview=${showPreview}&cl=${getParameterByName('cl')}`);
                const pageRequestData = [...store.getState().Page.requestData];
                pageRequestData[pageId] = requestData.data;

                const parsedData = await parseData(requestData.data, pageId);

                dispatch({
                    type: PAGE_INIT_SOCKET,
                    payload: {
                        ...parsedData,
                        requestData: pageRequestData,
                        pageId
                    }
                });
            }
        }
    }
}

export function setPageGA(ga) {
    return {
        type: PAGE_SET_GA,
        payload: ga
    }
}
import React from "react";
import { getParameterByName, } from "../../../lib";

function RecurringGiftField({ component, options, ReceiptState }) {

    return (
        <div className='row TYP_recurring-gift' style={{ padding: 10 }}>
            <div className='col-6' style={{ fontWeight: 'bold' }}>
                {typeof options.label !== "undefined" ? options.label : "Frequency Mode"}
            </div>
            <div className='col-6'>{(typeof getParameterByName('type') !== 'undefined' && getParameterByName('type') === 'recurring' ? (getParameterByName('subscriptionFrequency')[0].toUpperCase() + getParameterByName('subscriptionFrequency').slice(1)) : 'One Time')}</div>
        </div>
    );
}

export default RecurringGiftField;
import React, { Component } from "react";

// Import Packages.
import { connect } from 'react-redux'

// Import Lib.
import { renderEcommerceDatalayer } from "../../lib";

// Import Components.
import ReadOnlyFields from "./Components/ReadOnlyFields";
import InputFields from "./Components/InputFields";
import TributeSuccessModal from "../Fields/TributeSuccessModal";

class Receipt extends Component {

    async componentDidMount() {
        console.log("FB - ThankYou Page - Component Load, Get Receipt Data.");
        await window.rkd.form.getAbstractionLayer().getReceiptData();
        setTimeout(() => {
            renderEcommerceDatalayer(this.props.ReceiptState, this.props.FormRenderState);
        }, 1000);
    }

    componentWillUnmount() {
        console.log("FB - ThankYou Page - Component Unload, Reloading Page.");
        window.location.reload(false);
    }

    render() {
        const { FormRenderState, ReceiptState } = this.props;
        return (
            <div className='receipt-page'>
                {FormRenderState.thankYouPage.length !== 0
                    ?
                    <>
                        {FormRenderState.thankYouPage.map((section, index) => {
                            return section.pageType === "readonly"
                                ? < ReadOnlyFields section={section} key={index} />
                                : ((ReceiptState?.data?.tributeType?.trim() === "" || ReceiptState?.data?.tributeType === "none")
                                    ? < InputFields section={section} key={index} />
                                    : null)
                        })}
                    </>
                    :
                    < ReadOnlyFields />
                }
                {ReceiptState?.data?.showTributeSuccessModal &&
                    <TributeSuccessModal />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    FormRenderState: state.FormRender,
    ReceiptState: state.Receipt
});

export default connect(mapStateToProps)(Receipt);
import {getParameterByName, isIE, isPreview} from "../../lib";
import axios from "axios";
import GlobalVariableSocket from "../../Sockets/GlobalVariableSocket";
import store from "../store";
import {VARIABLES_INIT, VARIABLES_SET_ALL, VARIABLES_SET_FIELD_VALUES, VARIABLES_SET_VALUE} from "../actionTypes";

function parseData(data){
    const payload = {};
    data.forEach(variable => {
        payload[variable.variable_key] = variable.variable_value;
    });
    return payload;
}

export const initVariableSocket = (componentId) => {
    return async function(dispatch){
        if(isPreview() && !isIE()){
            if(!store.getState().Variable.socket){
                const socket = GlobalVariableSocket.init();
                socket.on('serverUpdate', data => {
                    dispatch({
                        type: VARIABLES_INIT,
                        payload: {
                            globalVariables: parseData(data),
                            socket
                        }
                    });
                });
            }
        }else{
            if(!store.getState().Variable.requestData){
                const protocol = process.env.NODE_ENV === 'development' ? 'http://' : 'https://';
                const requestData = await axios.get(`${protocol}${process.env.REACT_APP_API_HOST}/globalVariables?client=${getParameterByName('cl')}`);
                dispatch({
                    type: VARIABLES_INIT,
                    payload: {
                        globalVariables: parseData(requestData.data),
                        requestData: requestData.data
                    }
                });
            }
        }
    }
}

export function variablesSetFieldValues(fieldValues) {
    return {
        type: VARIABLES_SET_FIELD_VALUES,
        payload: fieldValues
    }
}

export function variablesSetValue(key, value){
    return {
        type: VARIABLES_SET_VALUE,
        payload: {
            key, value
        }
    }
}

export function variablesSetAll(variables){
    return {
        type: VARIABLES_SET_ALL,
        payload: variables
    }
}

import React, { useEffect, useState } from 'react';
import { BootstrappedChildField as ChildField } from '../BaseFields/ChildField';
import { isStage, getParameterByName } from "../../lib";
import moment from "moment";
import 'moment-timezone';

//Import Redux releated function.
import { connect, useSelector, useDispatch } from 'react-redux';

import { setCanSubmit } from "../../Redux/actions/FormValidationActions";
import { setFormValue } from "../../Redux/actions/FormValueActions";
import { setPaymentFailure, setError } from "../../Redux/actions/FormRenderActions";

//Method to get the Authorize.Net payment nonce.
const dispatchData = (authData, cardData) => {
    return new Promise((resolve, reject) => {
        window.Accept.dispatchData({ authData, cardData }, (response) => {
            if (response.messages.resultCode === 'Ok') {
                resolve(response);
            } else {
                reject(response);
            }
        });
    });
};

function AuthorizeForm(props) {

    //Reducer States.
    const dispatch = useDispatch();
    const FormRenderState = useSelector(state => state.FormRender);

    //Component props
    const { authKeys: authData, formattedOptions } = props;

    //Component States.
    const [cardNumberErrorMessage, setCardNumberErrorMessage] = useState("");
    const [expirationMonthErrorMessage, setExpirationMonthErrorMessage] = useState("");
    const [expirationYearErrorMessage, setExpirationYearErrorMessage] = useState("");
    const [cvvErrorMessage, setCVVErrorMessage] = useState("");
    const [cardHolderNameErrorMessage, setCardHolderNameErrorMessage] = useState("");

    const storageKey = 'storedClient';

    useEffect(() => {

        //Load Auth script.
        const scriptUrl = isStage() ? 'https://jstest.authorize.net/v1/Accept.js' : 'https://js.authorize.net/v1/Accept.js';
        let acceptJSScript = document.createElement('script');
        acceptJSScript.setAttribute('type', "application/javascript");
        acceptJSScript.setAttribute('src', scriptUrl);
        acceptJSScript.setAttribute('charset', "utf-8");
        document.body.appendChild(acceptJSScript);

        const abstraction = window.rkd.form.getAbstractionLayer();
        abstraction.registerSubmitTrigger('auth', () => handleSubmit());

        return () => {
            const abstraction = window.rkd.form.getAbstractionLayer();
            abstraction.deregisterSubmitTrigger('auth');
        };
    }, []);

    async function handleSubmit() {
        try {

            // Below code is written for check the repeat donor feature.
            const storedValue = JSON.parse(window.localStorage.getItem(storageKey));
            if (storedValue
                && (storedValue.data.paymentType === "auth"
                    && storedValue.data.paymentProvider.apiLoginID === FormRenderState.paymentProviders[storedValue.data.paymentProvider.type].apiLoginID)
            ) {
                return;
            }

            // Below code will execute when there is no repeat donor.
            const cardData = {
                cardNumber: document.getElementById('cardNumber').value,
                month: document.getElementById('expMonth').value,
                year: document.getElementById('expYear').value,
                cardCode: document.getElementById('cardCode').value
            };

            // Execute the dispatchData() method for get the Authorize.Net payment nonce.
            const response = await dispatchData(authData, cardData);

            // Set the custom form values.
            dispatch(setFormValue('dataDescriptor', response.opaqueData.dataDescriptor));
            dispatch(setFormValue('dataValue', response.opaqueData.dataValue));
            dispatch(setFormValue('last4', (cardData.cardNumber).substr(cardData.cardNumber.length - 4)));

            // Reset all error messages.
            setCardNumberErrorMessage('');
            setExpirationMonthErrorMessage('');
            setExpirationYearErrorMessage('');
            setCVVErrorMessage('');
            setCardHolderNameErrorMessage('');

            dispatch(setPaymentFailure(false));
            dispatch(setError(''));

        } catch (error) {

            if (error.messages) {

                const { message, resultCode } = error.messages;

                if (resultCode === 'Error') {
                    message.map((msg) => {
                        if (msg.code === 'E_WC_05') {
                            setCardNumberErrorMessage(msg.text);
                        } else if (msg.code === 'E_WC_06') {
                            setExpirationMonthErrorMessage(msg.text);
                        } else if (msg.code === 'E_WC_07') {
                            setExpirationYearErrorMessage(msg.text);
                        } else if (msg.code === 'E_WC_15') {
                            setCVVErrorMessage(msg.text);
                        } else if (msg.code === 'E_WC_16') {
                            setCardHolderNameErrorMessage(msg.text);
                        } else {
                            dispatch(setError(msg.text));
                        }
                    })
                }
            } else {
                dispatch(setError(error.message));
                console.log(`FB - Auth.Net - handleSubmit() - Error: ${error.message} - Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);
            }
            
            dispatch(setCanSubmit(false));
            dispatch(setPaymentFailure(true));
        }
    };

    // It's not very easy to use normal CSS with these fields.
    const fieldStyle = {
        display: 'block',
        width: '100%',
        height: 'calc(1.5em + .75rem + 2px)',
        padding: '.375rem .75rem',
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1.5',
        color: '#495057',
        backgroundColor: '#fff',
        backgroundClip: 'padding-box',
        border: '1px solid #ced4da',
        borderRadius: '.25rem',
        transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out'
    }


    return (
        <div className={'row' + (FormRenderState.paymentFailure ? ' payment-failure' : '')} style={{ paddingTop: 10 }}>
            <ChildField
                templateSize='card_number_template_size'
                templateOffset='card_number_template_offset'
                formattedOptions={{
                    label: formattedOptions.ccLabel || 'Credit Card Number',
                    required: true,
                    ...props.formattedOptions
                }}
                error={cardNumberErrorMessage}>
                <input
                    type="text"
                    name="cardNumber"
                    id="cardNumber"
                    className={cardNumberErrorMessage !== '' ? 'is-invalid' : ''}
                    style={fieldStyle}
                    placeholder="4111 1111 1111 1111"
                />
            </ChildField>
            <ChildField
                templateSize='expiration_template_size'
                templateOffset='expiration_template_offset'
                formattedOptions={{
                    label: 'Exp. Month',
                    required: true,
                    ...props.formattedOptions
                }}
                error={expirationMonthErrorMessage}>
                <input
                    type="text"
                    name="expMonth"
                    id="expMonth"
                    className={expirationMonthErrorMessage !== '' ? 'is-invalid' : ''}
                    style={fieldStyle}
                    placeholder="MM"
                />
            </ChildField>
            <ChildField
                templateSize='expiration_template_size'
                templateOffset='expiration_template_offset'
                formattedOptions={{
                    label: 'Exp. Year',
                    required: true,
                    ...props.formattedOptions
                }}
                error={expirationYearErrorMessage}>
                <input
                    type="text"
                    name="expYear"
                    id="expYear"
                    className={expirationYearErrorMessage !== '' ? 'is-invalid' : ''}
                    style={fieldStyle}
                    placeholder="YY"
                />
            </ChildField>
            <ChildField
                templateSize='cvv_template_size'
                templateOffset='cvv_template_offset'
                formattedOptions={{
                    label: formattedOptions.cvcLabel || 'Security Code',
                    required: true,
                    ...props.formattedOptions
                }}
                error={cvvErrorMessage}>
                <input
                    type="text"
                    name="cardCode"
                    id="cardCode"
                    className={cvvErrorMessage !== '' ? 'is-invalid' : ''}
                    style={fieldStyle}
                    placeholder="Security Code"
                />
            </ChildField>
            <ChildField
                templateSize='cvv_template_size'
                templateOffset='cvv_template_offset'
                formattedOptions={{
                    ...props.formattedOptions
                }}
            >
                <span className="ginput_card_security_code_icon">&nbsp;</span>
            </ChildField>
            <ChildField
                templateSize='card_holder_name_template_size'
                templateOffset='card_holder_name_template_offset'
                formattedOptions={{
                    label: 'Card Holder Name',
                    ...props.formattedOptions
                }}
                error={cardHolderNameErrorMessage}>
                <input
                    type="text"
                    name="cardName"
                    id="cardName"
                    className={cardHolderNameErrorMessage !== '' ? 'is-invalid' : ''}
                    style={fieldStyle}
                    placeholder="Cardholder Name"
                />
            </ChildField>
        </div>
    );
}

export default AuthorizeForm;
import {VARIABLES_INIT, VARIABLES_SET_ALL, VARIABLES_SET_FIELD_VALUES, VARIABLES_SET_VALUE} from "../actionTypes";

const initialState = {
    fieldValues: {},
    variables: {},
    globalVariables: {},
    socket: null,
    requestData: null,
};

export default function(state = initialState, action){
    switch (action.type){
        case VARIABLES_INIT:
            return {
                ...state,
                ...action.payload
            }
        case VARIABLES_SET_FIELD_VALUES:
            return {
                ...state,
                fieldValues: action.payload
            }
        case VARIABLES_SET_VALUE:
            const variables = [...state.variables];
            variables[action.payload.key] = action.payload.value;
            return {
                ...state,
                variables
            }
        case VARIABLES_SET_ALL:
            return {
                ...state,
                variables: action.payload
            }
        default:
            return state;
    }
}
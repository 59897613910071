import Checkbox, {CheckboxActionsToProps, CheckboxStateToProps} from "../BaseFields/Checkbox";
import {connect} from 'react-redux'

class AnonymousCheckbox extends Checkbox{

}

const mapStateToProps = (state) => ({
    ...CheckboxStateToProps(state)
})

const mapActionsToProps = {
    ...CheckboxActionsToProps
}

export default connect(mapStateToProps, mapActionsToProps)(AnonymousCheckbox);
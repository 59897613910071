import {FORM_ID_VALUE_SET} from "../actionTypes";

export const setFormIdValue = (key, value) => {
    return {
        type: FORM_ID_VALUE_SET,
        payload: {
            key,
            value
        }
    }
}
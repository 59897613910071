import React from "react";
import { connect } from "react-redux";
import { FormGroup, Button } from "react-bootstrap";
import BaseField, { BaseFieldStateToProps, BaseFieldActionsToProps } from "../BaseFields/BaseField";

class TributeTriggerButton extends BaseField {

    componentDidMount() {
        // This trigger for un-select TributeTriggerButton if form modal close by cancle button.
        window.rkd.form.on('internal_change_tributeCheckbox', (event) => {
            this.setFieldAndTriggerEvent(event.name, false);
        });
    }

    handleOnClick = (name, e) => {
        e.preventDefault();
        this.setFieldAndTriggerEvent(name, true);
        e.target.blur();
    }

    render() {

        const { setFormValue, ReceiptState } = this.props;
        const generatedStyles = this.getGeneratedStyles();
        const options = this.getOptionsArray();
        const { name, label, hideIfTributeGiftOptionNotSelected } = options;
        let { labelIfTributeGiftOptionNotSelected: buttonLabel } = options;

        const { data: ReceiptData } = ReceiptState;
        const isTributeGiftOptionSelected = ReceiptData?.customFields?.is_tribute_gift_option_selected?.trim();

        // If the Tribute gift option was not selected on the form submission then hide the button as per the field setting. 
        if (hideIfTributeGiftOptionNotSelected === "Yes" && !isTributeGiftOptionSelected) {
            generatedStyles.display = "none";
        }

        // If the Tribute gift option was selected on the form submission then update the button label as per the field setting. 
        // Update tributeFirstName and tributeLastName form field values.
        if (isTributeGiftOptionSelected) {
            buttonLabel = label.replace(/\{honoree_name}/g, isTributeGiftOptionSelected);
            const [honoreeFirstName, honoreeLastName] = isTributeGiftOptionSelected.split(" ");
            setFormValue("tributeFirstName", honoreeFirstName);
            setFormValue("tributeLastName", honoreeLastName);
        }

        return (
            <div className={this.getTemplateClasses()} style={generatedStyles}>
                <FormGroup>
                    <Button
                        onClick={this.handleOnClick.bind(this, name)}
                        variant='success'
                    >
                        {buttonLabel}
                    </Button>
                </FormGroup>
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    ...BaseFieldStateToProps(state),
    ReceiptState: state.Receipt
})

const mapActionsToProps = {
    ...BaseFieldActionsToProps
}

export default connect(mapStateToProps, mapActionsToProps)(TributeTriggerButton);
import React from 'react';
import { ElementsConsumer, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js'
// import { BootstrappedTextbox as Textbox } from '../BaseFields/Textbox';

import StripeForm from '../Forms/StripeForm'
import BraintreePaypalForm from "../Forms/BraintreePaypalForm";
import { Tabs, Tab, Row, Nav, Col } from "react-bootstrap";
import StripeApplePayForm from "../Forms/StripeApplePayForm";
import StripeACHPayForm from "../Forms/StripeACHPayForm";
import BaseField, { BaseFieldActionsToProps, BaseFieldStateToProps } from "../BaseFields/BaseField";
import BraintreeForm from "../Forms/BraintreeForm";
import BraintreeApplePayForm from "../Forms/BraintreeApplePayForm";
import AuthorizeForm from "../Forms/AuthorizeForm";
import { connect } from "react-redux";
import { setFormValue } from "../../Redux/actions/FormValueActions";
import { setError, setPaymentFailure } from "../../Redux/actions/FormRenderActions";
import { checkValidAmount, getDynamicErrorMessage } from "../../lib";
// import {  } from "../../API/FormAPI";
import BraintreeVenmoForm from "../Forms/BraintreeVenmoForm";

import store from "../../Redux/store";
import _ from 'lodash'
import Spinner from "react-bootstrap/Spinner";

class PaymentInfo extends BaseField {

    constructor(props) {
        super(props);
        this.state = {
            stripeKey: null,
            stripe: null,
            stripeAppleKey: null,
            stripeApple: null,
            stripeACH: false,
            braintreePaypal: null,
            braintree: null,
            authKeys: null,
            tabKey: null,
            isVaildForm: true,
            tempPaymentMethod: null,
            availPayments: [],
            loading: true,
            recurringType: 'single',
            ...this.state
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const superUpdate = super.shouldComponentUpdate(nextProps, nextState, nextContext);
        if (superUpdate) {
            return true;
        }

        if (
            nextProps.FormValueState.giftAmount !== this.props.FormValueState.giftAmount ||
            nextProps.FormValueState.braintreeCCToken !== this.props.FormValueState.braintreeCCToken ||
            nextProps.FormValueState.braintreePayPalToken !== this.props.FormValueState.braintreePayPalToken ||
            nextProps.FormValueState.paymentType !== this.props.FormValueState.paymentType
        ) {
            return true;
        }

        return false;
    }

    async componentDidMount() {
        const abstraction = window.rkd.form.getAbstractionLayer();
        const availPayments = await abstraction.getPaymentTypes();
        this.setState({
            availPayments,
            loading: false
        })
    }

    componentWillReceiveProps(nextProps) {
        let isErrorSet = nextProps.FormRenderState.submissionError !== "" ? this.state.isVaildForm : true;
        if(this.state.isVaildForm !== isErrorSet)
        {
            this.setState({ isVaildForm: isErrorSet});
        }

        if(typeof nextProps.FormValueState.recurring_gift !== "undefined" && this.state.recurringType !== nextProps.FormValueState.recurring_gift)
        {
            this.setState({ recurringType: nextProps.FormValueState.recurring_gift });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.FormValueState, this.props.FormValueState) || !_.isEqual(prevState.availPayments, this.state.availPayments)) {
            this.init();
        }
    }

    async init() {
        const abstraction = window.rkd.form.getAbstractionLayer();
        // const { FormValueState, setFormValue } = this.props;
        const { FormValueState } = this.props;
        const { availPayments } = this.state;

        if (availPayments.includes('stripe')) {
            const stripeKey = await abstraction.getStripeKey();
            this.setState({
                stripeKey: stripeKey,
                stripe: await loadStripe(stripeKey)
            });
        }

        if (availPayments.includes('auth')) {
            const authKeys = await abstraction.getAuthKeys();
            this.setState({ authKeys: authKeys });
        }

        if (availPayments.includes('stripe_applepay')) {
            const stripeAppleKey = await abstraction.getStripeApplePayKey();
            console.log(stripeAppleKey);
            this.setState({
                stripeAppleKey: stripeAppleKey,
                stripeApple: loadStripe(stripeAppleKey)
            });
        }

        if (availPayments.includes('braintree')) {
            this.setState({
                braintree: FormValueState.braintreeCCToken
            })
        }

        if (availPayments.includes('braintree_paypal')) {
            this.setState({
                braintreePaypal: FormValueState.braintreePayPalToken
            })
        }

        if (availPayments.includes('braintree_applepay')) {
            const braintreeAppleKey = await abstraction.getBraintreeApplePayKey();
            this.setState({
                braintreeAppleKey: braintreeAppleKey,
                braintreeApplePay: FormValueState.braintreeApplePayToken
            });
        }

        if (availPayments.includes('stripe_ach')) {
            this.setState({
                stripeACH: true
            })
        }

        // let tabs = this.getTabs();
        // if(tabs[0]){
        //     setFormValue('paymentType', tabs[0].key);
        // }

        if (availPayments.includes('braintree_venmo')) {
            this.setState({
                braintreeVenmo: FormValueState.braintreeVenmoToken
            })
        }

    }

    getFormItems() {
        const FormRenderState = store.getState().FormRender;
        const formItems = [];
        FormRenderState.pages.forEach(page => {
            page.components.forEach(component => {
                let options = {};
                component.options.forEach((option) => {
                    options[option.field] = option.value || option.default;
                });
                formItems.push(options.name);
            })
        });
        return formItems;
    }

    getTabs() {
        const { FormValueState } = this.props;
        const options = {};

        this.props.options.forEach((option) => {
            options[option.field] = option.value || option.default;
        });

        const formattedOptionsArray = this.getOptionsArray();

        const { stripeKey, stripe, stripeAppleKey, stripeApple, braintree, braintreePaypal, braintreeApplePay, stripeACH, braintreeVenmo , recurringType, authKeys } = this.state;
        const tabs = [];
        if (stripeKey) {
            tabs.push({
                name: 'Credit Card',
                key: 'stripe',
                type: 'stripe',
                content: FormValueState.paymentType === 'stripe' ? <Elements stripe={stripe}>
                    <ElementsConsumer>
                        {({ stripe, elements }) => <StripeForm formattedOptions={formattedOptionsArray} stripe={stripe} elements={elements} />}
                    </ElementsConsumer>
                </Elements> : null
            });
        }

        if (braintree) {
            tabs.push({
                name: 'Credit Card',
                key: 'braintree',
                type: 'braintree',
                content: FormValueState.paymentType === 'braintree' ? <BraintreeForm formattedOptions={formattedOptionsArray} token={braintree} /> : null
            });
        }

        if (authKeys) {
            tabs.push({
                name: 'Credit Card',
                key: 'auth',
                type: 'auth',
                content: FormValueState.paymentType === 'auth' 
                ? <AuthorizeForm formattedOptions={formattedOptionsArray} authKeys={authKeys} /> 
                : null
            });
        }

        if (braintreePaypal) {
            let isValidState = false;
            tabs.push({
                name: 'PayPal',
                key: 'braintree_paypal',
                type: 'braintree_paypal',
                onClick: () => {
                    isValidState = window.rkd.form.isValid(this.getFormItems());
                    this.setState({ tempPaymentMethod: 'PayPal' });
                    return isValidState;
                },
                content: FormValueState.paymentType === 'braintree_paypal' ? <BraintreePaypalForm formattedOptions={formattedOptionsArray} token={braintreePaypal} /> : null
            });
        }

        if (stripeAppleKey && window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
            let isValidState = false;
            tabs.push({
                name: 'Apple Pay',
                key: 'stripe_applepay',
                type: 'stripe_applepay',
                onClick: () => {
                    isValidState = window.rkd.form.isValid(this.getFormItems());
                    this.setState({ tempPaymentMethod: 'Apple Pay' });
                    return isValidState;
                },
                content: FormValueState.paymentType === 'stripe_applepay' ? <Elements stripe={stripeApple}>
                    <ElementsConsumer>
                        {({ stripe, elements }) => <StripeApplePayForm formattedOptions={formattedOptionsArray} stripe={stripe} elements={elements} />}
                    </ElementsConsumer>
                </Elements> : null
            });
        }

        if (braintreeApplePay && window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
            let isValidState = false;
            tabs.push({
                name: 'Apple Pay',
                key: 'braintree_applepay',
                type: 'braintree_applepay',
                onClick: () => {
                    isValidState = window.rkd.form.isValid(this.getFormItems());
                    this.setState({ tempPaymentMethod: 'Apple Pay' });
                    return isValidState;
                },
                content: FormValueState.paymentType === 'braintree_applepay' ? <BraintreeApplePayForm formattedOptions={formattedOptionsArray} token={braintreeApplePay} /> : null
            });
        }

        if (stripeACH) {
            tabs.push({
                name: 'Bank',
                key: 'stripe_ach',
                type: 'stripe_ach',
                content: FormValueState.paymentType === 'stripe_ach' ? <StripeACHPayForm /> : null
            });
        }

        if (!stripeKey && !braintree && !braintreePaypal && !stripeAppleKey && !authKeys) {
            tabs.push({
                name: 'Credit Card',
                key: 'generic_credit_card',
                type: 'generic_credit_card',
                content:
                    // FormValueState.paymentType === 'generic_credit_card' ? <>
                    //     <Textbox formattedOptions={{
                    //         name: options.ccName,
                    //         label: options.ccLabel,
                    //         placeholder: options.ccPlaceholder,
                    //     }}
                    //         value={FormValueState[options.ccName]}
                    //     />
                    //     <Textbox formattedOptions={{
                    //         name: options.expireName,
                    //         label: options.expireLabel,
                    //         placeholder: options.expirePlaceholder,
                    //     }}
                    //         value={FormValueState[options.expireName]}
                    //     />
                    //     <Textbox formattedOptions={{
                    //         name: options.cvcName,
                    //         label: options.cvcLabel,
                    //         placeholder: options.cvcPlaceholder,
                    //     }}
                    //         value={FormValueState[options.cvcName]}
                    //     />
                    //     <Textbox formattedOptions={{
                    //         name: options.ccNameOnCard,
                    //         label: options.ccNameLabel,
                    //         placeholder: options.ccNamePlaceholder,
                    //     }}
                    //         value={FormValueState[options.ccNameOnCard]}
                    //     />
                    // </> : null
                    null
            })
        }

        if (braintreeVenmo && recurringType === 'single') {
            let isValidState = false;
            tabs.push({
                name: 'Venmo',
                key: 'braintree_venmo',
                type: 'braintree_venmo',
                onClick: () => {
                    isValidState = window.rkd.form.isValid(this.getFormItems());
                    this.setState({ tempPaymentMethod: 'Venmo' });
                    return isValidState;
                },
                content: FormValueState.paymentType === 'braintree_venmo' ? <BraintreeVenmoForm formattedOptions={formattedOptionsArray} token={braintreeVenmo} /> : null
            });
        }

        return tabs;
    }

    handleTabClick(tab) {
        const { FormValueState, setFormValue, setError, setPaymentFailure } = this.props;
        const tabs = this.getTabs();
        let currentTab;
        let i = 0;
        while (i < tabs.length) {
            if (tabs[i].key === tab) {
                currentTab = tabs[i];
                break;
            }
            i++;
        }

        let renderTab = true;
        if (typeof currentTab.onClick !== 'undefined') {
            renderTab = currentTab.onClick();
        }

        let isValidAmount = (Number(FormValueState.giftAmount) >= Number(FormValueState.giftMinAmountValue)) ? true : false;
        if (renderTab && checkValidAmount(FormValueState.giftAmount) && isValidAmount) {
            setFormValue('paymentType', tab);
            let isVaildFormValue = (!checkValidAmount(FormValueState.giftAmount) && !isValidAmount) ? false : true;
            this.setState({
                tabKey: tab,
                isVaildForm: isVaildFormValue
            });
            if (!isVaildFormValue) {
                window.rkd.form.setErrorAttempts();
            }
            setError('');
            setPaymentFailure(false);
            return true;
        } else {
            this.setState({ isVaildForm: false });
            window.rkd.form.setErrorAttempts();
            const errorMessage = (!checkValidAmount(FormValueState.giftAmount) || !isValidAmount) ? getDynamicErrorMessage("amount") : getDynamicErrorMessage();
            setError(errorMessage);
            window.scroll({ top: 0, behavior: "smooth" });
        }
        return false;
    }

    handleChange(props, event) {

        const tab = event.target.value;

        const { FormValueState, setFormValue, setError } = props;
        const tabs = this.getTabs();
        let currentTab;
        let i = 0;
        if (tab !== "") {
            while (i < tabs.length) {
                if (tabs[i].key === tab) {
                    currentTab = tabs[i];
                    break;
                }
                i++;
            }

            let renderTab = true;
            if (typeof currentTab.onClick !== 'undefined') {
                renderTab = currentTab.onClick();
            }

            let isValidAmount = (Number(FormValueState.giftAmount) >= Number(FormValueState.giftMinAmountValue)) ? true : false;
            if (renderTab && checkValidAmount(FormValueState.giftAmount) && (FormValueState.giftMinAmount && FormValueState.giftAmount >= FormValueState.giftMinAmountValue)) {
                setFormValue('paymentType', tab);
                let isVaildFormValue = (!checkValidAmount(FormValueState.giftAmount) && !isValidAmount) ? false : true;
                this.setState({
                    tabKey: tab,
                    isVaildForm: isVaildFormValue
                });
                if (!isVaildFormValue) {
                    window.rkd.form.setErrorAttempts();
                }
                setError('');
                setPaymentFailure(false);
                return true;
            } else {
                this.setState({ isVaildForm: false });
                window.rkd.form.setErrorAttempts();
                const errorMessage = (!checkValidAmount(FormValueState.giftAmount) && !isValidAmount) ? getDynamicErrorMessage("amount") : getDynamicErrorMessage();
                setError(errorMessage);
                window.scroll({ top: 0, behavior: "smooth" });
            }
        }
        else {
            setError('');
            setPaymentFailure(false);
            this.setState({ tabKey: null, isVaildForm: true });
        }
        return false;
    }

    render() {
        if (this.state.loading) {
            if (!store.getState().StoredClient.valid) {
                return (<div className="payment-loader"><Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner></div>);
            } else {
                return null;
            }
        }

        const { FormValueState, setFormValue } = this.props;
        const options = this.getOptionsArray();
        if (this.state.hidden) {
            return null;
        }

        const tabs = this.getTabs();

        if (tabs.length !== 0 && (!FormValueState.paymentType || FormValueState.paymentType === 'generic_credit_card') && FormValueState.paymentType !== tabs[0].type) {
            setFormValue('paymentType', tabs[0].type);
        }

        if (tabs.length === 0) {
            // This needs to exist to keep the form from rendering if there's nothing to render.
            // There may be a better way to fix this, it crashes the app otherwise
            return null;
        } else if (tabs.length === 1) {
            // check form validation before render paypal button (only for single payment selected)
            if (tabs[0].key === "braintree_paypal") {
                return (
                    <div className='col-12 paymentinfo-section' style={ this.getGeneratedStyles() } data-private>
                        <span className="paymentinfo-heading"> Select Your Payment Method</span>
                        {!this.state.isVaildForm &&
                            <div className='col-12'>
                                <div className='alert alert-danger'>You must fill out all of the form fields to use {this.state.tempPaymentMethod}.</div>
                            </div>
                        }

                        {typeof options.displayType !== 'undefined' && options.displayType !== "Tabs" &&
                            <Tab.Container id="left-tabs-example" defaultActiveKey={this.state.tabKey}>
                                <Row>
                                    <Col sm={12}>
                                        {options.displayType === "Select" &&
                                            <select className="form-control" aria-label="Default select example" onChange={this.handleChange.bind(this, this.props)} value={this.state.tabKey !== null ? this.state.tabKey : ""}>
                                                <option selected="true" value="">Please Select</option>
                                                {tabs.map((tab, index) => (
                                                    <option selected={tab.key === this.state.tabKey} value={tab.key}>{tab.name}</option>
                                                ))}
                                            </select>
                                        }

                                        {options.displayType !== "Select" &&
                                            <Nav >
                                                {tabs.map((tab, index) => (

                                                    <Nav.Item>
                                                        <Nav.Link eventKey={tab.key} onSelect={(key) => { return this.handleTabClick(key); }}>
                                                            {options.displayType === "Button" &&
                                                                <button className={tab.key === this.state.tabKey ? "btn btn-primary" : "btn btn-outline-primary"} onClick={(e) => { e.preventDefault();}}>{tab.name}</button>
                                                            }

                                                            {options.displayType === "Radio" &&
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={tab.key === this.state.tabKey} />
                                                                    <label className="form-check-label" for="flexRadioDefault1">
                                                                        {tab.name}
                                                                    </label>
                                                                </div>
                                                            }

                                                            {options.displayType === "Image" &&
                                                                <button className={(tab.key === this.state.tabKey ? "btn btn-primary" : "btn btn-outline-primary") + " " + (options[tab.name.replace(/\s+/g, '_').toLowerCase()] !== "" && typeof options[tab.name.replace(/\s+/g, '_').toLowerCase()] !== "undefined" ? "image-button-payment-ui" : "")} onClick={(e) => { e.preventDefault();}}>
                                                                    {typeof options[tab.name.replace(/\s+/g, '_').toLowerCase()] !== "undefined" && options[tab.name.replace(/\s+/g, '_').toLowerCase()] !== "" &&
                                                                        <img src={options[tab.name.replace(/\s+/g, '_').toLowerCase()]} alt="payment img" />
                                                                    }

                                                                    {(options[tab.name.replace(/\s+/g, '_').toLowerCase()] === "" || typeof options[tab.name.replace(/\s+/g, '_').toLowerCase()] === "undefined") &&
                                                                        `${tab.name}`
                                                                    }
                                                                </button>
                                                            }
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                ))}
                                            </Nav>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Tab.Content>
                                            {tabs.map((tab, index) => (
                                                <Tab.Pane eventKey={tab.key} active={tab.key === this.state.tabKey}>
                                                    {this.state.isVaildForm &&
                                                        tab.content
                                                    }
                                                </Tab.Pane>
                                            ))}
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        }

                        {(typeof options.displayType === 'undefined' || options.displayType === "Tabs") &&

                            <Tabs activeKey={this.state.tabKey} onSelect={(key) => { return this.handleTabClick(key); }}>
                                {tabs.map((tab, index) => (
                                    <Tab eventKey={tab.key} title={tab.name}>
                                        {this.state.isVaildForm &&
                                            tab.content
                                        }
                                    </Tab>
                                ))}
                            </Tabs>
                        }
                    </div>
                );
            } else {
                return (
                    <div className='col-12 paymentinfo-section' style={ this.getGeneratedStyles() } data-private>
                        {tabs[0].content}
                    </div>
                );
            }
        } else {

            if (!this.state.tabKey) {
                this.setState({ tabKey: tabs[0].key });
                return null;
            }

            return (<div className='col-12 paymentinfo-section' style={this.getGeneratedStyles()} data-private>
                <span className="paymentinfo-heading"> Select Your Payment Method</span>
                <div className='row'>
                    {!this.state.isVaildForm &&
                        <div className='col-12'>
                            <div className='alert alert-danger'>You must fill out all of the form fields to use {this.state.tempPaymentMethod}.</div>
                        </div>
                    }
                    <div className='col-12'>
                        {typeof options.displayType !== 'undefined' && options.displayType !== "Tabs" &&
                            <Tab.Container id="left-tabs-example" defaultActiveKey={this.state.tabKey}>
                                <Row>
                                    <Col sm={12}>
                                        {options.displayType === "Select" &&
                                            <select className="form-control" aria-label="Default select example" onChange={this.handleChange.bind(this, this.props)} value={this.state.tabKey}>
                                                {tabs.map((tab, index) => (
                                                    <option selected={tab.key === this.state.tabKey} value={tab.key}>{tab.name}</option>
                                                ))}
                                            </select>
                                        }

                                        {options.displayType !== "Select" &&
                                            <Nav >
                                                {tabs.map((tab, index) => (
                                                    <Nav.Item key={index}>
                                                        <Nav.Link eventKey={tab.key} onSelect={(key) => { return this.handleTabClick(key); }}>
                                                            {options.displayType === "Button" &&
                                                                <button className={tab.key === this.state.tabKey ? "btn btn-primary" : "btn btn-outline-primary"} onClick={(e) => { e.preventDefault();}}>{tab.name}</button>
                                                            }

                                                            {options.displayType === "Radio" &&
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={tab.key === this.state.tabKey} />
                                                                    <label className="form-check-label" for="flexRadioDefault1">
                                                                        {tab.name}
                                                                    </label>
                                                                </div>
                                                            }

                                                            {options.displayType === "Image" &&
                                                                <button className={(tab.key === this.state.tabKey ? "btn btn-primary" : "btn btn-outline-primary") + " " + (options[tab.name.replace(/\s+/g, '_').toLowerCase()] !== "" && typeof options[tab.name.replace(/\s+/g, '_').toLowerCase()] !== "undefined" ? "image-button-payment-ui" : "")} onClick={(e) => { e.preventDefault();}}>
                                                                    {typeof options[tab.name.replace(/\s+/g, '_').toLowerCase()] !== "undefined" && options[tab.name.replace(/\s+/g, '_').toLowerCase()] !== "" &&
                                                                        <img src={options[tab.name.replace(/\s+/g, '_').toLowerCase()]} alt="Payment img" />
                                                                    }

                                                                    {(options[tab.name.replace(/\s+/g, '_').toLowerCase()] === "" || typeof options[tab.name.replace(/\s+/g, '_').toLowerCase()] === "undefined") &&
                                                                        `${tab.name}`
                                                                    }
                                                                </button>
                                                            }
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                ))}
                                            </Nav>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Tab.Content>
                                            {tabs.map((tab, index) => (
                                                <Tab.Pane eventKey={tab.key} active={tab.key === this.state.tabKey}>
                                                    {tab.content}
                                                </Tab.Pane>
                                            ))}
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        }

                        {(typeof options.displayType === 'undefined' || options.displayType === "Tabs") &&
                            <Tabs activeKey={this.state.tabKey} onSelect={(key) => { return this.handleTabClick(key); }}>
                                {tabs.map((tab, index) => (
                                    <Tab eventKey={tab.key} title={tab.name}>
                                        {tab.content}
                                    </Tab>
                                ))}
                            </Tabs>
                        }
                    </div>
                </div>
            </div>);
        }
    }
}

const mapStateToProps = (state) => ({
    ...BaseFieldStateToProps(state),
    FormValueState: state.FormValue,
    FormRenderState: state.FormRender
})

const mapActionsToProps = {
    ...BaseFieldActionsToProps,
    setFormValue,
    setError,
    setPaymentFailure
}

export default connect(mapStateToProps, mapActionsToProps)(PaymentInfo);

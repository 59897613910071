import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/es/object';
import 'core-js/es/array';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import store from './Redux/store'

import './API/index'

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById(window.rkdFormEmbedRoot || 'react-root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

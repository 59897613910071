import React from "react";

function GiftArrayBtnField({ component, options, ReceiptState }) {
    return (
        <div className='row TYP_amount' style={{ padding: 10 }}>
            <div className='col-6 TYC_Label_amount' style={{ fontWeight: 'bold' }}>
                Amount
            </div>
            <div className='col-6 TYC_Value_amount'>${ReceiptState.data.giftAmount}</div>
        </div>
    );
}

export default GiftArrayBtnField;
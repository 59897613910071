import { isStage } from "../lib";

const hostname = {
    dmc: () => {
        console.log("isStage()=-=--=---------------------========", isStage());
        console.log("process.env.REACT_APP_NODE_ENV", process.env.REACT_APP_NODE_ENV);
        console.log("process.env.REACT_APP_NODE_ENV", process.env.REACT_APP_NODE_ENV === 'production');
        return isStage() ? 'https://preview.charitable.one' : 'https://donate.charitable.one';
    },
    transactionEndPoint: () => {
        console.log("process.env.REACT_APP_NODE_ENV", process.env.REACT_APP_NODE_ENV);
        console.log("process.env.REACT_APP_NODE_ENV", process.env.REACT_APP_NODE_ENV === 'production');
        return process.env.REACT_APP_NODE_ENV === 'production' ? 'https://donate.charitable.one' : 'https://preview.charitable.one';
    }


};

export default hostname;
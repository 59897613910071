import React, { Component } from 'react';
import { client, hostedFields } from 'braintree-web';
import { BootstrappedChildField as ChildField } from '../BaseFields/ChildField';
import { connect } from 'react-redux';
import { setCanSubmit } from "../../Redux/actions/FormValidationActions";
import { setFormValue } from "../../Redux/actions/FormValueActions";
import { setPaymentFailure, setError } from "../../Redux/actions/FormRenderActions";

class BraintreeForm extends Component {

    state = {
        braintreeClient: null,
        hostedFieldsInstance: null,
        cardHolderNameErrorMessage: '',
        cardNumberErrorMessage: '',
        postalCodeErrorMessage: '',
        expirationDateErrorMessage: '',
        cvvErrorMessage: '',
    }

    componentDidMount() {
        const abstraction = window.rkd.form.getAbstractionLayer();
        const { formattedOptions } = this.props;

        this.setState({
            braintreeClient: client.create({
                authorization: this.props.token
            }, (clientErr, clientInstance) => {
                if (clientErr) {
                    return;
                }

                const options = {
                    client: clientInstance,
                    styles: {

                    },
                    fields: {
                        number: {
                            selector: '#card-number',
                            placeholder: formattedOptions.ccPlaceholder || "4111 1111 1111 1111"
                        },
                        cvv: {
                            selector: '#cvv',
                            placeholder: formattedOptions.cvcPlaceholder || "CVC"
                        },
                        expirationDate: {
                            selector: '#expiration-date',
                            placeholder: formattedOptions.expirePlaceholder || "MM / YYYY"
                        },
                        postalCode: {
                            selector: '#postal-code',
                            placeholder: formattedOptions.postalPlaceholder || "Postal Code"
                        }
                    }
                };

                hostedFields.create(options, (hostedFieldsErr, hostedFieldsInstance) => {
                    if (hostedFieldsErr) {
                        return;
                    }
                    this.setState({ hostedFieldsInstance }, () => {
                        abstraction.registerSubmitTrigger('braintree', () => this.submit_braintree());
                    });
                });
            })
        });
        this.props.setPaymentFailure(false);
    }

    componentWillUnmount() {
        const abstraction = window.rkd.form.getAbstractionLayer();
        abstraction.deregisterSubmitTrigger('braintree');
    }

    async submit_braintree() {
        if (this.props.storedClientState.valid) {
            return;
        }
        const { hostedFieldsInstance } = this.state;
        const { formattedOptions } = this.props;

        if (hostedFieldsInstance !== null) {
            try {
                const tokenData = await hostedFieldsInstance.tokenize();
                this.props.setFormValue('braintree_token', tokenData.nonce);
                this.props.setFormValue('last4', tokenData.details.lastFour);
                this.setState({
                    cardHolderNameErrorMessage: '',
                    cardNumberErrorMessage: '',
                    postalCodeErrorMessage: '',
                    expirationDateErrorMessage: '',
                    cvvErrorMessage: '',
                });
                this.props.setPaymentFailure(false);
                this.props.setError('');
            } catch (e) {
                if(e.code === "HOSTED_FIELDS_FIELDS_EMPTY")
                {
                    this.setState({
                        cardHolderNameErrorMessage: 'Please check and re-enter your ' + formattedOptions.ccNameLabel.replace(/:\s*$/, "") || 'Cardholder Name',
                        cardNumberErrorMessage: 'Please check and re-enter your ' + formattedOptions.ccLabel.replace(/:\s*$/, "") || 'Credit Card Number',
                        postalCodeErrorMessage: 'Please check and re-enter your ' + formattedOptions.postalLabel.replace(/:\s*$/, "") || 'Postal Code',
                        expirationDateErrorMessage: 'Please check and re-enter your ' + formattedOptions.expireLabel.replace(/:\s*$/, "") || 'Exp. Date',
                        cvvErrorMessage: 'Please check and re-enter your ' + formattedOptions.cvcLabel.replace(/:\s*$/, "") || 'Security Code',
                    });
                }
                else if(e.code === "HOSTED_FIELDS_FIELDS_INVALID")
                {
                    this.setState({
                        cardHolderNameErrorMessage: e.details.invalidFieldKeys.includes('cardholderName') ? 'Please check and re-enter your ' + formattedOptions.ccNameLabel.replace(/:\s*$/, "") || 'Cardholder Name' : '',
                        cardNumberErrorMessage: e.details.invalidFieldKeys.includes('number') ? 'Please check and re-enter your ' + formattedOptions.ccLabel.replace(/:\s*$/, "") || 'Credit Card Number' : '',
                        postalCodeErrorMessage: e.details.invalidFieldKeys.includes('postalCode') ? 'Please check and re-enter your ' + formattedOptions.postalLabel.replace(/:\s*$/, "") || 'Postal Code' : '',
                        expirationDateErrorMessage: e.details.invalidFieldKeys.includes('expirationDate') ? 'Please check and re-enter your ' + formattedOptions.expireLabel.replace(/:\s*$/, "") || 'Exp. Date' : '',
                        cvvErrorMessage: e.details.invalidFieldKeys.includes('cvv') ? 'Please check and re-enter your ' + formattedOptions.cvcLabel.replace(/:\s*$/, "") || 'Security Code' : '',
                    });
                }
                else
                {
                    console.error(e);
                }
                this.props.setCanSubmit(false);
                this.props.setPaymentFailure(true);
            }
        } else {
            this.props.setCanSubmit(false);
            this.props.setPaymentFailure(true);
        }
    }

    render() {
        const { cardHolderNameErrorMessage, cardNumberErrorMessage, expirationDateErrorMessage, cvvErrorMessage, postalCodeErrorMessage } = this.state;

        const { formattedOptions } = this.props;
        // These statically coded styles are present due to the way Braintree renders their hosted fields,
        // it's not very easy to use normal CSS with these fields.
        const fieldStyle = {
            display: 'block',
            width: '100%',
            height: 'calc(1.5em + .75rem + 2px)',
            padding: '.375rem .75rem',
            fontSize: '1rem',
            fontWeight: '400',
            lineHeight: '1.5',
            color: '#495057',
            backgroundColor: '#fff',
            backgroundClip: 'padding-box',
            border: '1px solid #ced4da',
            borderRadius: '.25rem',
            transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out'
        }

        return (<div className={'row' + (this.props.FormRenderState.paymentFailure ? ' payment-failure' : '')} style={{ paddingTop: 10 }}>
            <ChildField
                templateSize='card_number_template_size'
                templateOffset='card_number_template_offset'
                formattedOptions={{
                    label: formattedOptions.ccLabel || 'Credit Card Number',
                    required: true,
                    ...this.props.formattedOptions
                }}
                error={cardNumberErrorMessage}>
                <div id='card-number' className={ cardNumberErrorMessage !== '' ? 'is-invalid' : '' } style={fieldStyle} />
            </ChildField>
            <ChildField
                templateSize='postal_template_size'
                templateOffset='postal_template_offset'
                formattedOptions={{
                    label: formattedOptions.postalLabel || 'Postal Code',
                    required: true,
                    ...this.props.formattedOptions
                }}
                error={postalCodeErrorMessage}>
                <div id='postal-code' className={ postalCodeErrorMessage !== '' ? 'is-invalid' : '' } style={fieldStyle} />
            </ChildField>
            <ChildField
                templateSize='expiration_template_size'
                templateOffset='expiration_template_offset'
                formattedOptions={{
                    label: formattedOptions.expireLabel || 'Exp. Date',
                    required: true,
                    ...this.props.formattedOptions
                }}
                error={expirationDateErrorMessage}>
                <div id='expiration-date' className={ expirationDateErrorMessage !== '' ? 'is-invalid' : '' } style={fieldStyle} />
            </ChildField>
            <ChildField
                templateSize='cvv_template_size'
                templateOffset='cvv_template_offset'
                formattedOptions={{
                    label: formattedOptions.cvcLabel || 'Security Code',
                    required: true,
                    ...this.props.formattedOptions
                }}
                error={cvvErrorMessage}>
                <div id='cvv' className={ cvvErrorMessage !== '' ? 'is-invalid' : '' } style={fieldStyle} />
            </ChildField>

        </div>);
    }

}

const mapStateToProps = state => ({
    storedClientState: state.StoredClient,
    FormRenderState: state.FormRender,
})

const mapActionsToProps = {
    setCanSubmit,
    setFormValue,
    setPaymentFailure,
    setError
}

export default connect(mapStateToProps, mapActionsToProps)(BraintreeForm);
import React from "react";
import { renderString } from "../../lib";

import {
    FormGroup,
    FormLabel,
    FormControl
} from "react-bootstrap";
import BaseField, { BaseFieldActionsToProps, BaseFieldStateToProps } from "./BaseField";
import { connect } from "react-redux";

class Select extends BaseField {

    // state = {
    //     coverFee: 0
    // }
    
    
    componentDidMount() {

        // This code will set the custom field value by default blank,
        // So it will pass for transaction without interact with custom field
        const { FormValueState } = this.props;
        const options = this.getOptionsArray();
        let fieldName = options.name;
        if (options.custom) {
            fieldName = `dmc_custom_field_${options.name}`;
        }
        this.triggerOnFieldLoadInForm(typeof FormValueState[fieldName] !== 'undefined' ? FormValueState[fieldName] : this.props.optionItems[0].value);
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.FormValueState.calculatedCoverFee !== nextProps.FormValueState.calculatedCoverFee)
        {
            this.setState({ coverFee: typeof nextProps.FormValueState.calculatedCoverFee !== 'undefined' ? nextProps.FormValueState.calculatedCoverFee : 0});
        }
    }


    render() {
        const { FormValueState } = this.props;

        const errorMessage = this.getRenderedValidationMessage();
        const options = this.getOptionsArray();
        let fieldName = options.name;
        if (options.custom) {
            fieldName = `dmc_custom_field_${options.name}`;
        }

        const randomNumber = Math.abs(Math.random() * Number.MAX_SAFE_INTEGER).toFixed(0);

        return (
            <div className={this.getTemplateClasses() + ' donation-form-selectOption'} style={this.getGeneratedStyles()}>
                <FormGroup>
                    <FormLabel htmlFor={`form-control-${randomNumber}`} className={typeof options.validators !== 'undefined' ? options.validators.required ? 'required required-field' : '' : ''}>{renderString(options.label)}</FormLabel>
                    <FormControl
                        id={`form-control-${randomNumber}`}
                        as="select"
                        onChange={(event) => {
                            this.onChange(event);
                            if (this.props.handleCheck) {
                                this.props.handleCheck();
                            }
                        }}
                        name={renderString(fieldName, this.props.FormRenderState.variables)}
                        value={this.props.value || renderString(FormValueState[fieldName], this.props.FormRenderState.variables)}
                        placeholder={renderString(options.placeholder, this.props.FormRenderState.variables)}
                        className={errorMessage ? 'required-error' : ''}
                    >
                        {this.props.optionItems.map((option, index) => (
                            <option
                                key={index}
                                value={renderString(option.value, this.props.FormRenderState.variables)}
                            >{renderString(option.label, this.props.FormRenderState.variables)}</option>
                        ))}
                    </FormControl>
                    <div style={{ color: '#F00' }} className="donation-form-field-error">{errorMessage}</div>
                </FormGroup>
            </div>
        );
    }

}

export const SelectStateToProps = (state) => ({
    ...BaseFieldStateToProps(state),
    FormRenderState: state.FormRender,
    FormValueState: state.FormValue
})

export const SelectActionsToProps = {
    ...BaseFieldActionsToProps
}

export const BootstrappedSelect = connect(SelectStateToProps, SelectActionsToProps)(Select);
export default Select;

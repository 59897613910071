import React, { Component } from 'react';
import FormField from "./FormField";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FormModalTriggers from "./FormModalTriggers";
import { connect } from 'react-redux';
import store from "../Redux/store";
import Spinner from "react-bootstrap/Spinner";

import {  getParameterByName } from "../lib";
import { TRIGGER_SET_TEMP_NAME } from '../Redux/actionTypes';

class FormModal extends Component {

    state = {
        show: false,
        error: false,
        isLoading: false
    }

    constructor() {
        super();
        window.rkd.form.on('internal_event', (event) => this.triggerListener(event));
        window.rkd.form.on('internal_change_Lightbox-if-Donation-Error-after-n-attempts', (event) => this.triggerListener(event));
    }

    triggerListener(event) {
        const { triggers } = this.props.modal;

        if (triggers && triggers.includes(event.triggerIndex)) {
            if (['hide', 'show'].includes(event.action)) {
                this.setState({
                    show: event.action !== 'hide'
                });
                this.setState({ error: false });
            }
        }
    }

    async hideModal() {

        if (this.isTributeCheckBoxTigger()) {

            const currentModalData = this.props.modal.components;
            const formItems = [];

            if (currentModalData && currentModalData.length !== 0) {

                currentModalData.forEach(component => {
                    let options = {};
                    component.options.forEach((option) => {
                        options[option.field] = option.value || option.default;
                    });
                    formItems.push(options.name);
                });

                // Check modal fields validations.
                if (window.rkd.form.isValid(formItems)) {

                    // If tribute gift modal is submit on thank you page,
                    // Then make an API call to submit tribute gift data.
                    if (getParameterByName("receipt")) {
                        try {
                            this.setState({ isLoading: true });
                            await window.rkd.form.getAbstractionLayer().submitTributeGift();
                            this.setState({ show: false });
                            this.setState({ error: false });
                            this.setState({ isLoading: false });
                        } catch (error) {
                            this.setState({ isLoading: false });
                            this.setState({ error: true });
                            const element = document.getElementsByClassName('modal-dialog'); 
                            element[0].scrollIntoView(true);
                        }
                    }else{
                        this.setState({ show: false });
                        this.setState({ error: false });
                    }

                }

            }

        } else {

            this.setState({ show: false });
            this.setState({ error: false });
            store.dispatch({
                type: TRIGGER_SET_TEMP_NAME,
                payload: ""
            });
        }

    }

    hideOnCancel() {
        if (this.isTributeCheckBoxTigger()) {
            // this trigger for uncheck tributeCheckbox if form field not validated
            window.rkd.form.triggerEvent('internal_change_tributeCheckbox', { name: 'tribute' });
        } else {
            this.setState({ show: false });
            this.setState({ error: false });
        }
    }

    showModal() {
        this.setState({show: true});
        this.setState({ error: false });
    }

    isTributeCheckBoxTigger() {
        const filter = 'Tribute';
        const modalList = this.props.FormRenderState.modals.find(obj => obj.id === this.props.index);
        const filteredResult = modalList.components.filter((item) => {
            return (item.category.indexOf(filter) >= 0);
        });
        if (filteredResult.length > 0) {
            return true;
        }
        return false;
    }
    // Function to add onClick event on close modal button for 
    modalIsOpen() {
        // console.log( "Modal is Shown" );
        if (this.state.show) {
            try{
                // console.log("!!!-FormModal.js->triggerListener:xCloseModalButton", store.getState().GlobalSettings.lightboxIfDonationErrorAfterNAttempts);
                let xCloseModalButton = document.getElementsByClassName(store.getState().GlobalSettings.lightboxIfDonationErrorAfterNAttempts.xCloseModalButtons[0]);
                // console.log("!!!-FormModal.js->triggerListener:xCloseModalButton", xCloseModalButton[0]);
                xCloseModalButton[0].onclick = (event) => {
                    this.hideModal();
                }
            }
            catch(err){
                // console.log("!!!-FormModal.js->triggerListener->onclick->catch:err", err);
            }
            try{
                let xCloseModalButton = document.getElementsByClassName(store.getState().GlobalSettings.lightboxIfDonationErrorAfterNAttempts.xCloseModalButtons[1]);
                // console.log("!!!-FormModal.js->triggerListener:xCloseModalButton", xCloseModalButton[0]);
                xCloseModalButton[0].onclick = (event) => {
                    this.hideModal();
                }
            }
            catch(err){
                // console.log("!!!-FormModal.js->triggerListener->onclick->catch:err", err);
            }
        }
    }  

    render() {
        const modalData = this.props.modal.components;
        const { error, isLoading } = this.state;
        return (
            <Modal size="md" id={this.props.modal.id} centered show={this.state.show} onHide={() => this.hideModal()} onEntered={() => this.modalIsOpen()}>
                <Modal.Body>
                    <div className='row'>
                        {error &&
                            <div className='alert alert-danger' role='alert' >
                                <p>Please wait a few seconds and try again. If issues persist, please contact dmcsupport@rkdgroup.com.</p>
                            </div>
                        }
                        {modalData.map((step, index) => (
                            <FormField key={index} field={step} />
                        ))}
                    </div>
                </Modal.Body>
                <FormModalTriggers />
                <Modal.Footer>
                    <Button
                        variant="danger"
                        onClick={(e) => {
                            e.preventDefault();
                            this.hideOnCancel()
                        }}
                        disabled={isLoading}>
                        Cancel
                    </Button>
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            this.hideModal()
                        }}
                        disabled={isLoading}>
                        {isLoading &&
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        }
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

}

const mapStateToProps = (state) => ({
    FormRenderState: state.FormRender,
    TriggerState: state.Trigger
})

export default connect(mapStateToProps)(FormModal);
import store from "../store";
import { TRIGGER_SET_TEMP_NAME, TRIGGER_SUBMIT_SET, TRIGGER_UPDATE_STATE, TRIGGERS_SET_TRIGGERS, GLOBAL_SETTINGS_SET_ERRORNATTEMPTS } from "../actionTypes";
import { getParameterByName, isFalsy, isTruthy } from "../../lib";
import { parseCEL, formatQuery } from 'react-querybuilder';
import _ from 'lodash';

function setTriggers(triggers) {
    return {
        type: TRIGGERS_SET_TRIGGERS,
        payload: triggers
    }
}

function setTempTriggerName(name) {
    return {
        type: TRIGGER_SET_TEMP_NAME,
        payload: name
    }
}

function setTriggerState(index, state) {
    return {
        type: TRIGGER_UPDATE_STATE,
        payload: {
            index, state
        }
    }
}

export function setSubmitTriggers(triggers) {
    return {
        type: TRIGGER_SUBMIT_SET,
        payload: triggers
    }
}

export function initTriggers() {
    let checkInterval = setInterval(() => {
        if (store.getState().FormRender.pages.length > 0) {
            forceRunTriggers();
            clearInterval(checkInterval);
        }
    });

    window.rkd.form.on('internal_change', (event) => {
        store.getState().Trigger.triggers.forEach((trigger, triggerIndex) => {
            if (trigger.dependantFields.includes(event.field_id)) {
                store.dispatch(setTempTriggerName(event.field_id));
                processTriggers(trigger.index, triggerIndex);
            }
        })
    })
}

function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

let iterateData = (data, key, triggerFieldList) => {
    if (data.hasOwnProperty(key)) {
      for (let i = 0; i < data[key].length; i++) {
        if (data[key][i].hasOwnProperty(key)) {
          iterateData(data[key][i], key, triggerFieldList);
        } else {        
            if(data[key][i].field == 'Lightbox_if_Donation_Error_after_n_attempts') {
                data[key][i].field = 'Lightbox-if-Donation-Error-after-n-attempts';
            }
            if(data[key][i].field == 'recurring_gift') {
                data[key][i].field = 'recurring-gift';
            }
            if(data[key][i].field === 'urlParam') {
                let value = data[key][i].value.split(":");
                let urlParam = getParameterByName(value[0].trim());
                if(urlParam) {
                    data[key][i].field = urlParam ? ("'" + urlParam + "'").toLowerCase()  : null;
                    data[key][i].value = value[1] ? value[1].trim() : '';                    
                } else {
                    data[key][i].field = 0;
                    data[key][i].value = 0;
                    data[key][i].operator = "!=";
                }

            } else if(data[key][i].field === 'Lightbox-if-Donation-Error-after-n-attempts') {
                triggerFieldList.push(data[key][i].field);    
                data[key][i].field = parseInt(store.getState().GlobalSettings.lightboxIfDonationErrorAfterNAttempts.counter) - 1;
                data[key][i].value = parseInt(data[key][i].value);
            } else {
                triggerFieldList.push(data[key][i].field);    
                let field = data[key][i].field;
                let formValue = store.getState().FormIdValue[field];
                if(isNumeric(formValue)) {
                    data[key][i].field = formValue ? parseFloat(formValue) : 0;
                } else {
                    data[key][i].field = store.getState().FormIdValue[field] ? ("'" + store.getState().FormIdValue[field] + "'").toLowerCase() : "''";
                }

                let triggerValue = data[key][i].value;                
                if(triggerValue && typeof triggerValue === 'string') {
                    data[key][i].value = isNumeric(triggerValue) ? parseFloat(triggerValue) : triggerValue.toLowerCase();
                }
                
            }
        }
      }
    }
};

export function processTriggers(index, triggerStateIndex) {
    const triggerState = { ...store.getState().Trigger.triggers[triggerStateIndex] };
    
    let actionEvent = 'action';
    const trigger = store.getState().FormRender.triggers.find(obj => obj.id === index);
    let jsonTree = parseCEL(trigger.expression);
    let triggerFieldList = [];

    iterateData(jsonTree, "rules", triggerFieldList);
    
    triggerFieldList = _.uniq(triggerFieldList);
    
    let ispageLength = store.getState().FormRender.pages.filter((p) => (typeof p.pageType === "undefined" || p.pageType === "step")).length;
    
    console.log(trigger.name, '=> ', formatQuery(jsonTree, 'cel'), trigger.expression);
    if(!eval(formatQuery(jsonTree, 'cel'))) {
        actionEvent = 'inverse';
    }
    if (trigger.name === 'Lightbox-if-Donation-Error-after-n-attempts-duo' || trigger.name === 'Lightbox-if-Donation-Error-after-n-attempts') {
        console.log('eval: ', formatQuery(jsonTree, 'cel'), trigger.name);
        actionEvent = 'action';
    }
    console.log('action: ', actionEvent);
    
    if (actionEvent === 'action' && !triggerState.executed.action) {
        triggerFieldList.map((currentCase) => {
            if (store.getState().Trigger.tempTriggerFieldName === currentCase) {
                window.rkd.form.triggerEvent('internal_event', {
                    action: trigger.action,
                    triggerIndex: index,
                    trigger: trigger
                });
                triggerState.executed.action = true;
                triggerState.executed.inverseAction = false;
                store.dispatch(setTriggerState(triggerStateIndex, triggerState));
            }
        })

        if(!triggerFieldList.length) {
            window.rkd.form.triggerEvent('internal_event', {
                action: trigger.action,
                triggerIndex: index,
                trigger: trigger
            });
            triggerState.executed.action = true;
            triggerState.executed.inverseAction = false;
            store.dispatch(setTriggerState(triggerStateIndex, triggerState));
        }

    } else if (actionEvent === 'inverse' && (!triggerState.executed.inverseAction || ispageLength > 1)) {
        window.rkd.form.triggerEvent('internal_event', {
            action: trigger.inverseAction,
            triggerIndex: index,
            trigger: trigger
        });
        triggerState.executed.action = false;
        triggerState.executed.inverseAction = true;
        store.dispatch(setTriggerState(triggerStateIndex, triggerState));
    }
}

let getTriggerFieldValue = (data, key, triggerData) => {
    if (data.hasOwnProperty(key)) {
        for (let i = 0; i < data[key].length; i++) {
          if (data[key][i].hasOwnProperty(key)) {
            getTriggerFieldValue(data[key][i], key, triggerData);
          } else {
            let newKey = data[key][i].field;
            if(data[key][i].field === 'Lightbox_if_Donation_Error_after_n_attempts') {
                newKey = 'Lightbox-if-Donation-Error-after-n-attempts';
            }
            if(data[key][i].field === 'recurring_gift') {
                newKey = 'recurring-gift';
            }
            if(data[key][i].field === 'urlParam') {
                continue;
            }
            triggerData.push({
                field_id: newKey,
                field_value: data[key][i].value
            });
          }
        }
    }
}

export function forceRunTriggers() {
    // eslint-disable-next-line array-callback-return
    store.getState().FormRender.triggers.map((trigger, triggerIndex) => {
        const dependantFields = [];
        // eslint-disable-next-line array-callback-return
        let jsonTree = parseCEL(trigger.expression);
        
        let triggerData = [];

        getTriggerFieldValue(jsonTree, 'rules', triggerData)

        triggerData.map((currentCase) => {            
            dependantFields.push(currentCase.field_id);            
            setErrorAttemptSettings(currentCase, trigger.id);
        });
                
        const triggerState = [...store.getState().Trigger.triggers];
        triggerState.push({
            index: trigger.id,
            dependantFields,
            executed: {
                defaultAction: false,
                inverseAction: false,
                action: false
            }
        });

        store.dispatch(setTriggers(triggerState));

        processTriggers(trigger.id, triggerState.length - 1)
    });
}

function setErrorAttemptSettings(data, triggerIndex) {
    //change to search the trigeer index define the trigger index
    const lightboxIfDonationErrorAfterNAttempts = store.getState().GlobalSettings.lightboxIfDonationErrorAfterNAttempts;
    if (data.field_id === lightboxIfDonationErrorAfterNAttempts.name) {

        let nLigthboxes = lightboxIfDonationErrorAfterNAttempts.nLigthboxes;
        let errorAttemptSettingsArray = {};
        let errorAttemptSettings = {};

        if (nLigthboxes.length === 0) {
            nLigthboxes.push(triggerIndex);

            errorAttemptSettings = {
                name: data.field_id,
                enabled: true,
                attempts: data.field_value,
                counter: '1',
                index: triggerIndex,
                nLigthboxes: nLigthboxes,
                xCloseModalButtons: lightboxIfDonationErrorAfterNAttempts.xCloseModalButtons,
                nCases: []
            };
            store.dispatch({
                type: GLOBAL_SETTINGS_SET_ERRORNATTEMPTS,
                payload: errorAttemptSettings
            });
        }
        else {
            let indexToSearch = nLigthboxes.indexOf(triggerIndex);
            if (!(indexToSearch > -1)) {
                nLigthboxes.push(triggerIndex);

                errorAttemptSettingsArray = {
                    enabled: true,
                    attempts: data.field_value,
                    index: triggerIndex,
                };
                lightboxIfDonationErrorAfterNAttempts.nLigthboxes = nLigthboxes;
                lightboxIfDonationErrorAfterNAttempts.nCases.push(errorAttemptSettingsArray);
                // lightboxIfDonationErrorAfterNAttempts.xCloseModalButtons=['rkd-2312-close-button', 'rkd-2313-close-button'];
                store.dispatch({
                    type: GLOBAL_SETTINGS_SET_ERRORNATTEMPTS,
                    payload: lightboxIfDonationErrorAfterNAttempts
                });
            }
        }
    }
}

import {createStore, applyMiddleware} from "redux";
import thunk from "redux-thunk";
import rootReducer from './reducers'
import { composeWithDevTools } from "remote-redux-devtools";
import { getParameterByName, isTruthy } from '../lib';

let store = null;

if(isTruthy(getParameterByName('debug'))){
    store = createStore(rootReducer, composeWithDevTools( applyMiddleware(thunk)));
}else{
    store = createStore(rootReducer, applyMiddleware(thunk));
}
export default store;

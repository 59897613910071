import BaseSocket from "./BaseSocket";
import {getParameterByName} from "../lib";

const GlobalVariableSocket = {
    socket: null,
    init: () => {
        if(!BaseSocket.socket){
            BaseSocket.init();
        }
        if(GlobalVariableSocket.socket){
            return GlobalVariableSocket.socket;
        } else {
            GlobalVariableSocket.socket = BaseSocket.socket.subscribe(`globalVariables:${getParameterByName('cl')}`);
        }
        return GlobalVariableSocket.socket;
    }
};

export default GlobalVariableSocket;
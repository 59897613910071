import Ws from '@adonisjs/websocket-client';

const BaseSocket = {
    isConnected: false,
    socket: null,
    init: () => {
        if(!BaseSocket.socket){
            let protocol = 'wss';
            if(process.env.NODE_ENV === 'development'){
                protocol = 'ws';
            }
            BaseSocket.socket = Ws(protocol + '://' + process.env.REACT_APP_API_HOST, {
                path: 'ws'
            }).connect();
            BaseSocket.socket.on('open', () => {
                BaseSocket.isConnected = true;
            });

            BaseSocket.socket.on('close', () => {
                BaseSocket.isConnected = false;
            });
        }

        return BaseSocket.socket;
    }

};

export default BaseSocket;
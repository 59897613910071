import {TRIGGER_SET_TEMP_NAME, TRIGGER_SUBMIT_SET, TRIGGER_UPDATE_STATE, TRIGGERS_SET_TRIGGERS} from "../actionTypes";

const initialState = {
    submit: {},
    triggers: [],
    triggersRegistered: false,
    tempTriggerFieldName: '',
};

export default function(state = initialState, action){
    switch (action.type){
        case TRIGGERS_SET_TRIGGERS:
            return {
                ...state,
                triggers: action.payload
            }
        case TRIGGER_SET_TEMP_NAME:
            return {
                ...state,
                tempTriggerFieldName: action.payload
            }
        case TRIGGER_UPDATE_STATE:
            const triggers = [...state.triggers];
            triggers[action.payload.index] = action.payload.state;
            return {
                ...state,
                triggers: triggers
            }
        case TRIGGER_SUBMIT_SET:
            return {
                ...state,
                submit: action.payload
            }
        default:
            return state;
    }
}
import React, { useState, useEffect } from 'react';
import {PaymentRequestButtonElement, useStripe} from '@stripe/react-stripe-js'
import {connect} from 'react-redux';
import {setCanSubmit} from "../../Redux/actions/FormValidationActions";
import {setFormValue} from "../../Redux/actions/FormValueActions";

const StripeApplePayForm = (props) => {
    const stripe = useStripe();
    const {FormValueState, setFormValue} = props;
    const [paymentRequest, setPaymentRequest] = useState(null);
    const [paymentToken, setPaymentToken] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        const submit_stripe_applepay = () => {
            if(!paymentToken && !errorMessage){
                setErrorMessage('You must complete your payment method for Apple Pay.');
            }
            if(errorMessage){
                this.props.setCanSubmit(false);
            }
        }


        const abstraction = window.rkd.form.getAbstractionLayer();
        abstraction.registerSubmitTrigger('stripe_applepay', () => submit_stripe_applepay());

        if(FormValueState.giftAmount){
            setErrorMessage(null);
            if(stripe){
                const pr = stripe.paymentRequest({
                    country: 'US',
                    currency: 'usd',
                    total: {
                        label: 'Donation',
                        amount: parseFloat(FormValueState.giftAmount) * 100
                    },
                    requestShipping: false,
                    requestPayerPhone: false,
                    requestPayerName: false,
                    requestPayerEmail: false
                });

                pr.on("token", (ev) => {
                    setPaymentToken(ev.token.id);
                    console.log('token', ev.token.id);
                    setFormValue('stripe_card', ev.token.id);
                    ev.complete('success');
                    // reason: it take time to load token into the state before submit the payment
                    setTimeout(() => { window.rkd.form.submitForm(); }, 100);
                });

                pr.canMakePayment().then(result => {
                    if(result){
                        setPaymentRequest(pr);
                        setErrorMessage(null);
                    } else {
                        setErrorMessage('ApplePay is not supported on this device.');
                    }
                });
            }
        }else{
            setErrorMessage('You must select a gift amount before using Apple Pay');
        }

        return function cleanup(){
            abstraction.deregisterSubmitTrigger('stripe_applepay');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripe]);

    useEffect(() => {
        if(paymentRequest){
            let giftAmount = FormValueState.giftAmount;
            if(FormValueState.giftAmount === ''){
                giftAmount = 0.00;
            }

            paymentRequest.update({
                currency: 'usd',
                total: {
                    label: 'Donation',
                    amount: Number(parseFloat(giftAmount) * 100)
                },
            });
        }

    }, [paymentRequest, FormValueState.giftAmount])


    if(paymentRequest){
        if(paymentToken){
            return (<div className='row'>
                <div className='col-12 mt-3'>
                    <div className='alert alert-success'>We will use Apple Pay to process your payment. You still need to complete the form to process your donation.</div>
                </div>
            </div>);
        } else {
            if(errorMessage){
                return (
                    <div>
                        <div className='row'>
                            <div className='col-12 mt-3'>
                                <div className='alert alert-danger'>{errorMessage}</div>
                            </div>
                        </div>
                        <br /><div className="stripe-applePay-amount-text" style={{ textAlign: 'center' }}>Your donation will be in the amount of: ${FormValueState.giftAmount}</div><PaymentRequestButtonElement options={{paymentRequest}} /><br />
                    </div>
                );
            } else {
                return (<div><br /><div className="stripe-applePay-amount-text" style={{ textAlign: 'center' }}>Your donation will be in the amount of: ${FormValueState.giftAmount}</div><PaymentRequestButtonElement options={{paymentRequest}} /><br /></div>)
            }
        }
    }

    if(errorMessage){
        return (
            <div className='row'>
                <div className='col-12'>
                    <div className='alert alert-danger'>{errorMessage}</div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

const mapStateToProps = state => ({
    FormValueState: state.FormValue
})

const mapActionsToProps = {
    setCanSubmit,
    setFormValue
}

export default connect(mapStateToProps, mapActionsToProps)(StripeApplePayForm);
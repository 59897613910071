import React, { Component } from 'react';
import BillingCity from "./Fields/BillingCity";
import BillingEmail from "./Fields/BillingEmail";
import BillingFirstName from "./Fields/BillingFirstName";
import BillingLastName from "./Fields/BillingLastName";
import BillingPhone from "./Fields/BillingPhone";
import BillingState from "./Fields/BillingState";
import BillingStreetAddress1 from "./Fields/BillingStreetAddress1";
import BillingStreetAddress2 from "./Fields/BillingStreetAddress2";
import BillingZip from "./Fields/BillingZip";
import Salutation from "./Fields/Salutation";
import CustomTextbox from "./Fields/CustomTextbox";
import TributeCity from "./Fields/TributeCity";
import TributeHonoreeEmail from "./Fields/TributeHonoreeEmail";
import TributeHonoreeFirstName from "./Fields/TributeHonoreeFirstName";
import TributeHonoreeLastName from "./Fields/TributeHonoreeLastName";
import TributeMessage from "./Fields/TributeMessage";
import TributeRecipientEmail from "./Fields/TributeRecipientEmail";
import TributeRecipientFirstName from "./Fields/TributeRecipientFirstName";
import TributeRecipientLastName from "./Fields/TributeRecipientLastName";
import TributeState from "./Fields/TributeState";
import TributeStreetAddress1 from "./Fields/TributeStreetAddress1";
import TributeStreetAddress2 from "./Fields/TributeStreetAddress2";
import TributeZip from "./Fields/TributeZip";
import PaymentInfo from './Fields/PaymentInfo';
import GiftArray from './Fields/GiftArray';
import TitleText from "./Fields/TitleText";
import RecurringGift from "./Fields/RecurringGift";
import CoverFeeOption from './Fields/CoverFeeOption'
import TributeCheckbox from "./Fields/TributeCheckbox";
import TributeTriggerButton from "./Fields/TributeTriggerButton";
import TributeType from "./Fields/TributeType";
import TributeNotification from "./Fields/TributeNotification";
import AnonymousCheckbox from "./Fields/AnonymousCheckbox";
import Source from "./Fields/Source";
import TributeNotificationType from "./Fields/TributeNotificationType";
import TributeRecipientPhoneNumber from "./Fields/TributeRecipientPhoneNumber";
import CompanyDonorCheckbox from "./Fields/CompanyDonorCheckbox";
import CompanyMatchingCheckbox from "./Fields/CompanyMatchingCheckbox";
import CompanyDonorName from "./Fields/CompanyDonorName";
import GiftNotes from "./Fields/GiftNotes";
import RememberMe from "./Fields/RememberMe";
import CustomHTML from "./Fields/CustomHTML";
import TextBox from "./Fields/TextBox";
import Select from "./Fields/SelectField";
import Checkbox from "./Fields/Checkbox";
import RadioField from "./Fields/RadioField";
import TextAreaField from "./Fields/TextAreaField";
import DateField from "./Fields/DateField";
import CheckboxInput from "./Fields/CheckboxInput";
import LIDEANA from "./Fields/LightboxIfDonationErrorAfterNAttempts";

import {connect} from 'react-redux'

class Formfield extends Component{

    render(){
        const {field, parentIndex} = this.props;
        field.parentIndex = parentIndex;

        return (<>
            {field.id === 'billingCity' && <BillingCity {...field} />}
            {field.id === 'billingEmail' && <BillingEmail {...field} />}
            {field.id === 'billingFirstName' && <BillingFirstName {...field} />}
            {field.id === 'billingLastName' && <BillingLastName {...field} />}
            {field.id === 'billingPhone' && <BillingPhone {...field} />}
            {field.id === 'billingState' && <BillingState {...field} />}
            {field.id === 'billingStreetAddress1' && <BillingStreetAddress1 {...field} />}
            {field.id === 'billingStreetAddress2' && <BillingStreetAddress2 {...field} />}
            {field.id === 'billingZip' && <BillingZip {...field} />}
            {field.id === 'salutation' && <Salutation {...field} />}
            {field.id === 'customTextbox' && <CustomTextbox {...field} />}
            {field.id === 'tributeCity' && <TributeCity {...field} />}
            {field.id === 'tributeEmail' && <TributeHonoreeEmail {...field} />}
            {field.id === 'tributeFirstName' && <TributeHonoreeFirstName {...field} />}
            {field.id === 'tributeLastName' && <TributeHonoreeLastName {...field} />}
            {field.id === 'tributeMessage' && <TributeMessage {...field} />}
            {field.id === 'tributeRecipientEmail' && <TributeRecipientEmail {...field} />}
            {field.id === 'tributeRecipientFirstName' && <TributeRecipientFirstName {...field} />}
            {field.id === 'tributeRecipientLastName' && <TributeRecipientLastName {...field} />}
            {field.id === 'tributeRecipientPhoneNumber' && <TributeRecipientPhoneNumber {...field} />}
            {field.id === 'tributeState' && <TributeState {...field} />}
            {field.id === 'tributeStreetAddress1' && <TributeStreetAddress1 {...field} />}
            {field.id === 'tributeStreetAddress2' && <TributeStreetAddress2 {...field} />}
            {field.id === 'tributeZip' && <TributeZip {...field} />}
            {field.id === 'paymentInfo' && <PaymentInfo {...field} />}
            {field.id === 'giftArrayBtn' && <GiftArray {...field} />}
            {field.id === 'horizontalRule' && <hr />}
            {field.id === 'titleText' && <TitleText {...field} />}
            {field.id === 'recurring-gift' && <RecurringGift {...field} />}
            {field.id === 'coverFeeOption' && <CoverFeeOption {...field} />}
            {field.id === 'tributeCheckbox' && <TributeCheckbox {...field} />}
            {field.id === 'tributeTriggerButton' && <TributeTriggerButton {...field} />}
            {field.id === 'tributeType' && <TributeType {...field} />}
            {field.id === 'tributeNotification' && <TributeNotification {...field} />}
            {field.id === 'tributeNotificationType' && <TributeNotificationType {...field} />}
            {field.id === 'anonymousCheckbox' && <AnonymousCheckbox {...field} />}
            {field.id === 'sourceDropdown' && <Source {...field} />}
            {field.id === 'companyDonorCheckbox' && <CompanyDonorCheckbox {...field} />}
            {field.id === 'companyDonorName' && <CompanyDonorName {...field} />}
            {field.id === 'companyMatchingCheckbox' && <CompanyMatchingCheckbox {...field} />}
            {field.id === 'companyMatchingName' && <CompanyDonorName {...field} />}
            {field.id === 'giftNotes' && <GiftNotes {...field} />}
            {field.id === 'rememberMe' && <RememberMe {...field} />}
            {field.id === 'customHTML' && <CustomHTML {...field} />}
            {field.id === 'companyDonorCheckboxPlusName' && <CheckboxInput {...field} />}
            {field.id === 'companyMatchingCheckboxPlusName' && <CheckboxInput {...field} />}
            {field.id === 'tributeCountry' && <BillingState {...field} />}
            {field.id === 'billingCountry' && <BillingState {...field} />}
            {field.id === 'Lightbox-if-Donation-Error-after-n-attempts' && <LIDEANA {...field} />}
            {typeof field.customType !== "undefined" && field.customType === "text" && <TextBox {...field} />}
            {typeof field.customType !== "undefined" && field.customType === "select" && <Select {...field} />}
            {typeof field.customType !== "undefined" && field.customType === "checkbox" && <Checkbox {...field} />}
            {typeof field.customType !== "undefined" && field.customType === "radio" && <RadioField {...field} />}
            {typeof field.customType !== "undefined" && field.customType === "textArea" && <TextAreaField {...field} />}
            {typeof field.customType !== "undefined" && field.customType === "date" && <DateField {...field} />}
            {typeof field.customType !== "undefined" && field.customType === "checkbox-input" && <CheckboxInput {...field} />}
        </>);
    }

}

export default connect()(Formfield);

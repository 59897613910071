import {FORM_ID_VALUE_SET} from "../actionTypes";

const initialState = {

};

export default function(state = initialState, action){
    switch (action.type){
        case FORM_ID_VALUE_SET:
            state[action.payload.key] = action.payload.value;
            return state;
        default:
            return state;
    }
}
import Checkbox, { CheckboxActionsToProps, CheckboxStateToProps } from "../BaseFields/Checkbox";
import { connect } from "react-redux";

class TributeNotification extends Checkbox {

}

const mapStateToProps = (state) => ({
    ...CheckboxStateToProps(state)
})

const mapActionsToProps = {
    ...CheckboxActionsToProps
}

export default connect(mapStateToProps, mapActionsToProps)(TributeNotification);
import {STORED_CLIENT_SET_DATA, STORED_CLIENT_SET_TRIGGERS, STORED_CLIENT_SET_VALID} from "../actionTypes";

const initialState = {
    valid: null,
    data: {},
    triggers: [],
};

export default function(state = initialState, action){
    switch (action.type){
        case STORED_CLIENT_SET_VALID:
            return {
                ...state,
                valid: action.payload
            }
        case STORED_CLIENT_SET_DATA:
            return {
                ...state,
                data: action.payload
            }
        case STORED_CLIENT_SET_TRIGGERS:
            return {
                ...state,
                triggers: action.payload
            }
        default:
            return state;
    }
}
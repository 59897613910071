import React, { Component } from 'react';
import { connect } from 'react-redux';
import { client, venmo, dataCollector } from 'braintree-web'
import store from "./../../Redux/store";
import {setCanSubmit} from "../../Redux/actions/FormValidationActions";
import {setFormValue} from "../../Redux/actions/FormValueActions";
import { isStage } from "../../lib";

/***
 * 
 * Form-builder's FORM VENMO PAYMENT
 * 
 */
class BraintreeVenmoForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userCookie: '',
            captcha: 'none',
            venmo: false,
            venmoInstance: '',
            token: 'none',
            venmoFailed: '',
            dataCollectionInstance: '',
            loader: false
        };

    }

    componentDidMount() {

        const abstraction = window.rkd.form.getAbstractionLayer();
        abstraction.registerSubmitTrigger('braintree_venmo', () => this.submit_braintree_venmo())

        this.getToken();
    }

    componentWillReceiveProps(nextProps) {
        const { setFormValue, FormRenderState } = nextProps;
        if(this.state.loader === FormRenderState.generalErrors)
        {
            this.setState({ loader: false });
        }
    }

    componentWillUnmount() {
        const abstraction = window.rkd.form.getAbstractionLayer();
        abstraction.deregisterSubmitTrigger('braintree_Venmo');
    }
    
    //Show loader
    loadingAnimation = () => {
        this.setState({ loader: true });
    }

    //Hide loader
    removeLoading = () => {
        this.setState({ loader: false });
    }

    getToken = () => {
        this.loadingAnimation();

        client.create({
            authorization: this.props.token
        }, (err, clientInstance) => {

            //catch token error
            if (err !== null) {

                console.log("An error occurred in Venmo payment method: getToken()");

                console.log(err)

                this.setState({ venmoFailed: "Venmo failed to load." });

                this.removeLoading();

                return;

            }

            // Load Venmo
            this.load_venmo(client, clientInstance);
            

        });

    }

    //Venmo
    load_venmo = (braintree, clientInstance) => {
        
        Promise.all([
            dataCollector.create({
                client: clientInstance,
                paypal: true
            }),
            // Create a Venmo component.
            venmo.create({
                client: clientInstance,
                allowDesktop: true,
                paymentMethodUsage: "multi_use", //available in v3.77.0+
                /**
                 * Add allowNewBrowserTab: false if your checkout page does not support
                 * relaunching in a new tab when returning from the Venmo app. This can be ommited
                 * otherwise.
                */
                allowNewBrowserTab: false
            })
        ]).then(results => {

            var dataCollectionInstance = results[0];
            var venmoInstance = results[1];
            /**
             * At this point, you should access the device data value and provide it to your server
             * e.g. by injecting it into your form as a  hidden input.
            */
            // Verify browser support before proceeding.
            if(!venmoInstance.isBrowserSupported())
            {
                console.log("An error occurred in Venmo payment method: load_venmo()");
        
                console.log("Error: Browser does not support venmo.");

                this.setState({ venmoFailed: "Browser does not support venmo." });

                this.removeLoading();
                return;
            }

            // displayVenmoButton(venmoInstance);
            this.removeLoading();

            // Set up Venmo Pay buttons
            this.setState({ venmo: true, venmoInstance: venmoInstance, dataCollectionInstance: dataCollectionInstance });

        }).catch(err => {
            // Handle Venmo component creation error.
            console.log("An error occurred in Venmo payment method: load_venmo()");
        
            console.log("Error:", err);

            this.setState({ venmoFailed: "Venmo failed to load." });

            this.removeLoading();

            return;
        });
    }

    handleVenmoSuccess = (payload) => {
        
        var elem = document.getElementsByClassName("paymentinfo-section");
        elem[0].scrollIntoView();

        this.loadingAnimation();
        
        this.props.setFormValue('device_data', this.state.dataCollectionInstance.deviceData);

        let payloadToken = payload;
        
        // For sandbox account send the 'fake-venmo-account-nonce' for testing purpose.  
        if(isStage()) {
            payloadToken = { nonce: "fake-venmo-account-nonce" };
        }
        this.setState({ paymentToken: payloadToken });

        setTimeout(() => { window.rkd.form.submitForm(); }, 100);

    }

    handleVenmoError = (err) => {
        // console.log("Error", err);
        
        if (err.code === 'VENMO_CANCELED') {
            console.log('App is not available or user aborted payment flow');
        } else if (err.code === 'VENMO_APP_CANCELED') {
            console.log('User canceled payment flow from application');
        } else if (err.code === 'VENMO_DESKTOP_CANCELED') {
            console.log('User canceled payment flow from desktop');
        } else {
            console.error('An error occurred:', err.message);
        }
        
        if(isStage())
        {
            let payload = { nonce: "fake-venmo-account-nonce" };
            this.handleVenmoSuccess(payload);
        }
    }

    create_token_venmo = () => {
        var venmoButton = document.getElementById('venmo-button');
        venmoButton.disabled = true;

        this.state.venmoInstance.tokenize().then((payload) => this.handleVenmoSuccess(payload)).catch((error) => this.handleVenmoError(error)).then(function(){
            venmoButton.removeAttribute('disabled');
        });
    }

    submit_braintree_venmo() {
        
        if(this.state.paymentToken && !this.state.errorMessage){
            this.props.setFormValue('venmo_token', this.state.paymentToken.nonce);
            this.props.setFormValue('token', this.state.paymentToken.nonce);
        }else{
            this.setState({errorMessage: 'You must complete your payment method for Apple Pay.'}, () => {
                this.props.setCanSubmit(false);
            });
        }
    }

    

    render() {

        let backgroundVenmoButton = 'https://public.charitable.one/assets/donate/images/blue_venmo_button_active_280x48.svg';

        return (

            <section id="billingVenmo" className="text-center" >
                { this.state.venmoFailed !== "" &&

                    < div className="col-sm-12">

                        <br />

                        <h3 style={{ color: "red" }}>{this.state.venmoFailed}</h3>

                    </div>

                }
                <div className="col-sm-12" > { /* Loading Animation */}
                    <div className={"row " + (this.state.loader ? "" : "d-none" )} id="loader" >
                        <div className="col-sm-12" >
                            <img src="https://public.charitable.one/assets/donate/images/donation-loader-pagespeed.gif" alt="Not Found!" />
                        </div>
                    </div>

                    <div > < br />  </div>

                    {this.state.venmoFailed === "" &&

                        <div className={"col-sm-10 col-sm-offset-1 submit " + ( !this.state.loader ? "" : "d-none")} id="venmo-form">
                            <br />
                            {/* Your donation will be in the amount of: {this.props.transactionSymbol}{ MainFunctions.numberWithCommas(this.props.feeTransaction)} */}
                            Your donation will be in the amount of: ${this.props.FormValueState.giftAmount}

                            {this.state.venmo && (

                                <div id="venmo-pay-button-wrapper mt-3">

                                    <button type="button" style={{ backgroundImage: `url(${backgroundVenmoButton})`, width: '280px', height: '48px' }} id="venmo-button" onClick={this.create_token_venmo} >

                                    </button>

                                </div>

                            )}
                        </div>
                    }
                </div>
            </section>

        );
    }
}

const mapStateToProps = state => ({
    FormValueState: state.FormValue,
    FormRenderState: state.FormRender,
})

const mapActionsToProps = {
    setCanSubmit,
    setFormValue
}

export default connect(mapStateToProps, mapActionsToProps)(BraintreeVenmoForm);
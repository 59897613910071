import DatePicker, { DatePickerActionsToProps, DatePickerStateToProps } from "../BaseFields/DatePicker";
import { connect } from "react-redux";

class DateFields extends DatePicker {

}

const mapStateToProps = (state) => ({
    ...DatePickerStateToProps(state)
})

const mapActionsToProps = {
    ...DatePickerActionsToProps
}

export default connect(mapStateToProps, mapActionsToProps)(DateFields);
import React from "react";
import BaseField, { BaseFieldActionsToProps, BaseFieldStateToProps } from "../BaseFields/BaseField";
import { getParameterByName, isTruthy } from "../../lib";
import { setFormValue } from "../../Redux/actions/FormValueActions";
import { connect } from "react-redux";
import { FormGroup, FormLabel, FormCheck } from "react-bootstrap";
import { renderString } from "../../lib";

class RecurringGift extends BaseField {

    componentDidMount() {
        const { setFormValue, FormValueState } = this.props;
        const options = this.getOptionsArray();
        if (getParameterByName('monthly')) {
            setFormValue(options.name, isTruthy(getParameterByName('monthly')) ? 'recurring' : 'single');
        } else {
            setFormValue(options.name, (typeof FormValueState['recurring_gift'] === 'undefined' ? (options.preselected === 'one-time' ? 'single' : (options.preselected === 'monthly' ? 'recurring' : options.preselected)) : FormValueState['recurring_gift']));
        }
        // set init trigger on component load
        setTimeout(() => {
            if (getParameterByName('monthly')) {
                this.setFieldAndTriggerEvent(options.name, 'recurring');
            } else {
                this.setFieldAndTriggerEvent(options.name, (typeof FormValueState['recurring_gift'] === 'undefined' ? (options.preselected === 'one-time' ? 'single' : (options.preselected === 'monthly' ? 'recurring' : options.preselected)) : FormValueState['recurring_gift']));
            }
        }, 30);
    }

    changeOption(optionValue) {
        const { setFormValue, FormValueState } = this.props;
        const options = this.getOptionsArray();
        if (FormValueState[options.name] !== optionValue) {
            this.setFieldAndTriggerEvent(options.name, optionValue);
            if (FormValueState.giftArrayOther !== "") {
                setFormValue('giftArrayOther', FormValueState.giftArrayOther);
            } else {
                setFormValue('giftArrayActiveIndex', '');
            }
        }
    }

    renderButton(FormValueState, options) {
        let recurring_gift_arr = ["single", "weekly", "recurring", "quarterly", "annually"];
        let temp = typeof FormValueState[options.name] !== 'undefined' ? FormValueState[options.name] : options.preselected;
        temp = (temp === "single" ? "one-time" : (temp === "recurring" ? "monthly" : temp)).split('-');

        let temp1 = '';
        temp.map(function(value, key) {
            temp1 += value[0].toUpperCase() + value.slice(1);
        });

        let selectedOption = 0;
        if(typeof options['show'+temp1+'Option'] !== "undefined") 
        {
            if(options['show'+temp1+'Option'] === "No")
            {
                let giftOptionsValues = [options.showOneTimeOption, options.showWeeklyOption, options.showMonthlyOption, options.showQuarterlyOption, options.showAnnuallyOption];
                this.setFieldAndTriggerEvent(options.name, recurring_gift_arr[giftOptionsValues.indexOf("Yes")]);
                selectedOption = giftOptionsValues.indexOf("Yes") + 1;
            }
        }
        else
        {
            // Below condition is implemented for ignor the recurring field's logic for existing forms, which are supporting only one-time and monthly gift options.
            if(!['single', 'recurring'].includes(FormValueState[options.name]))
            {
                this.setFieldAndTriggerEvent(options.name, recurring_gift_arr[0]);
                selectedOption = 1;
            }
        }

        // Following condition is only applied for One-time and Monthly gift option, by which old forms will not affect with new implementation.
        let showOneTime = true;
        let showMonthly = true;
        if(options.oneTimeText !== undefined){
            if(options.showOneTimeOption !== undefined){
                showOneTime = options.showOneTimeOption === "No" ? false : true;         
            }
        }

        if(options.monthlyText !== undefined){
            if(options.showMonthlyOption !== undefined){
                showMonthly = options.showMonthlyOption === "No" ? false : true;
            }
        }

        // Following condition is only applied for Weekly, Quarterly and Annually gift option, by which old forms will not affect with new implementation.
        let showWeekly = false; 
        let showQuarterly = false;
        let showAnnually = false;
        if(options.weeklyText !== undefined){
            if(options.showWeeklyOption !== undefined){
                showWeekly = options.showWeeklyOption === "No" ? false : true;
            }
        }

        if(options.quarterlyText !== undefined){
            if(options.showQuarterlyOption !== undefined){
                showQuarterly = options.showQuarterlyOption === "No" ? false : true;
            }
        }

        if(options.annuallyText !== undefined){
            if(options.showAnnuallyOption !== undefined){
                showAnnually = options.showAnnuallyOption === "No" ? false : true;
            }
        }

        return (<>
            <button
                className={`btn ${FormValueState[options.name] === 'single' || (selectedOption !== 0 && selectedOption === 1) ? 'btn-success' : 'btn-secondary'} ${ showOneTime === false ? 'd-none' : ''}`}
                style={{ marginRight: 10 }}
                onClick={(e) => {
                    e.preventDefault();
                    this.changeOption('single')
                }}
            >{options.oneTimeText}</button>
            {/* <button
                className={`btn ${FormValueState[options.name] === 'weekly' || (selectedOption !== 0 && selectedOption === 2) ? 'btn-success' : 'btn-secondary'} ${ showWeekly === false ? 'd-none' : ''}`}
                style={{ marginRight: 10 }}
                onClick={(e) => {
                    e.preventDeafult();
                    this.changeOption('weekly')}}
            >{options.weeklyText}</button> */}
            <button
                className={`btn ${FormValueState[options.name] === 'recurring' || (selectedOption !== 0 && selectedOption === 3) ? 'btn-success' : 'btn-secondary'} ${ showMonthly === false ? 'd-none' : ''}`}
                style={{ marginRight: 10 }}
                onClick={(e) => {
                    e.preventDefault();
                    this.changeOption('recurring')}}
            >{options.monthlyText}</button>
            <button
                className={`btn ${FormValueState[options.name] === 'quarterly' || (selectedOption !== 0 && selectedOption === 4) ? 'btn-success' : 'btn-secondary'} ${ showQuarterly === false ? 'd-none' : ''}`}
                style={{ marginRight: 10 }}
                onClick={(e) => {
                    e.preventDefault();
                    this.changeOption('quarterly')}}
            >{options.quarterlyText}</button>
            <button
                className={`btn ${FormValueState[options.name] === 'annually' || (selectedOption !== 0 && selectedOption === 5) ? 'btn-success' : 'btn-secondary'} ${ showAnnually === false ? 'd-none' : ''}`}
                onClick={(e) => {
                    e.preventDefault();
                    this.changeOption('annually')
                }}
            >{options.annuallyText}</button>
        </>);
    }

    renderCheckBox(FormRenderState, options, type) {
        let recurring_gift_arr = ["single", "weekly", "recurring", "quarterly", "annually"];
        let temp = typeof FormRenderState[options.name] !== 'undefined' ? FormRenderState[options.name] : options.preselected;
        temp = (temp === "single" ? "one-time" : (temp === "recurring" ? "monthly" : temp)).split('-');

        let temp1 = '';
        temp.map(function(value, key) {
            temp1 += value[0].toUpperCase() + value.slice(1);
        });

        let selectedOption = 0;
        if(typeof options['show'+temp1+'Option'] !== "undefined") 
        {
            if(options['show'+temp1+'Option'] === "No")
            {
                let giftOptionsValues = [options.showOneTimeOption, options.showWeeklyOption, options.showMonthlyOption, options.showQuarterlyOption, options.showAnnuallyOption];
                this.setFieldAndTriggerEvent(options.name, recurring_gift_arr[giftOptionsValues.indexOf("Yes")]);
                selectedOption = giftOptionsValues.indexOf("Yes") + 1;
            }
        }
        else 
        {

            // Below condition is implemented for ignor the recurring field's logic for existing forms, which are supporting only one-time and monthly gift options.
            if(!['single', 'recurring'].includes(FormRenderState[options.name]))
            {
                this.setFieldAndTriggerEvent(options.name, recurring_gift_arr[0]);
                selectedOption = 1;
            }
        }

        // Following condition is only applied for One-time and Monthly gift option, by which old forms will not affect with new implementation.
        let showOneTime = true;
        let showMonthly = true;
        if(options.oneTimeText !== undefined){
            if(options.showOneTimeOption !== undefined){
                showOneTime = options.showOneTimeOption === "No" ? false : true;         
            }
        }

        if(options.monthlyText !== undefined){
            if(options.showMonthlyOption !== undefined){
                showMonthly = options.showMonthlyOption === "No" ? false : true;
            }
        }

        // Following condition is only applied for Weekly, Quarterly and Annually gift option, by which old forms will not affect with new implementation.
        let showWeekly = false; 
        let showQuarterly = false;
        let showAnnually = false;
        if(options.weeklyText !== undefined){
            if(options.showWeeklyOption !== undefined){
                showWeekly = options.showWeeklyOption === "No" ? false : true;
            }
        }

        if(options.quarterlyText !== undefined){
            if(options.showQuarterlyOption !== undefined){
                showQuarterly = options.showQuarterlyOption === "No" ? false : true;
            }
        }

        if(options.annuallyText !== undefined){
            if(options.showAnnuallyOption !== undefined){
                showAnnually = options.showAnnuallyOption === "No" ? false : true;
            }
        }

        return (<>
            <FormGroup>
                { showOneTime &&
                    <>
                        <FormCheck
                            type={type}
                            id={`${type}_single`}
                            inline
                            onChange={() => this.changeOption('single')}
                            checked={FormRenderState.recurring_gift === 'single' || (selectedOption !== 0 && selectedOption === 1) ? true : false}
                            className={FormRenderState.recurring_gift === 'single' || (selectedOption !== 0 && selectedOption === 1) ? 'amt-radio-active amt-radio-active-single' : 'amt-radio-active-monthly'}
                            name={renderString(options.oneTimeText, FormRenderState.variables)}
                            placeholder={renderString(options.placeholder, FormRenderState.variables)}
                            style={{ marginRight: 5 }}
                        />
                        <FormLabel htmlFor={`${type}_single`} style={{ marginRight: 10 }}>{renderString(options.oneTimeText, FormRenderState.variables)}</FormLabel>
                    </>
                }

                {/* { showWeekly &&
                    <>
                        <FormCheck
                            type={type}
                            id={`${type}_weekly`}
                            inline
                            onChange={() => this.changeOption('weekly')}
                            checked={FormRenderState.recurring_gift === 'weekly' || (selectedOption !== 0 && selectedOption === 2) ? true : false}
                            className={FormRenderState.recurring_gift === 'weekly' || (selectedOption !== 0 && selectedOption === 2) ? 'amt-radio-active amt-radio-active-weekly' : 'amt-radio-active-monthly'}
                            name={renderString(options.weeklyTextText, FormRenderState.variables)}
                            placeholder={renderString(options.placeholder, FormRenderState.variables)}
                            style={{ marginRight: 5 }}
                        />
                        <FormLabel htmlFor={`${type}_weekly`} style={{ marginRight: 10 }}>{renderString(options.weeklyText, FormRenderState.variables)}</FormLabel>
                    </>
                } */}

                { showMonthly && 
                    <>
                        <FormCheck
                            type={type}
                            id={`${type}_recurring`}
                            inline
                            onChange={() => this.changeOption('recurring')}
                            checked={FormRenderState.recurring_gift === 'recurring' || (selectedOption !== 0 && selectedOption === 3) ? true : false}
                            className={FormRenderState.recurring_gift === 'recurring' || (selectedOption !== 0 && selectedOption === 3) ? 'amt-radio-active amt-radio-active-recurring' : 'amt-radio-active-single'}
                            name={renderString(options.monthlyText, FormRenderState.variables)}
                            placeholder={renderString(options.placeholder, FormRenderState.variables)}
                            style={{ marginRight: 5 }}
                        />
                        <FormLabel htmlFor={`${type}_recurring`} style={{ marginRight: 10 }}>{renderString(options.monthlyText, FormRenderState.variables)}</FormLabel>
                    </>
                }

                { showQuarterly && 
                    <>
                        <FormCheck
                            type={type}
                            id={`${type}_quarterly`}
                            inline
                            onChange={() => this.changeOption('quarterly')}
                            checked={FormRenderState.recurring_gift === 'quarterly' || (selectedOption !== 0 && selectedOption === 4) ? true : false}
                            className={FormRenderState.recurring_gift === 'quarterly' || (selectedOption !== 0 && selectedOption === 4) ? 'amt-radio-active amt-radio-active-quarterly' : 'amt-radio-active-monthly'}
                            name={renderString(options.quarterlyText, FormRenderState.variables)}
                            placeholder={renderString(options.placeholder, FormRenderState.variables)}
                            style={{ marginRight: 5 }}
                        />
                        <FormLabel htmlFor={`${type}_quarterly`} style={{ marginRight: 10 }}>{renderString(options.quarterlyText, FormRenderState.variables)}</FormLabel>
                    </>
                }

                { showAnnually && 
                    <>
                        <FormCheck
                            type={type}
                            id={`${type}_annually`}
                            inline
                            onChange={() => this.changeOption('annually')}
                            checked={FormRenderState.recurring_gift === 'annually' || (selectedOption !== 0 && selectedOption === 5) ? true : false}
                            className={FormRenderState.recurring_gift === 'annually' || (selectedOption !== 0 && selectedOption === 5) ? 'amt-radio-active amt-radio-active-annually' : 'amt-radio-active-monthly'}
                            name={renderString(options.annuallyText, FormRenderState.variables)}
                            placeholder={renderString(options.placeholder, FormRenderState.variables)}
                            style={{ marginRight: 5 }}
                        />
                        <FormLabel htmlFor={`${type}_annually`} style={{ marginRight: 10 }}>{renderString(options.annuallyText, FormRenderState.variables)}</FormLabel>
                    </> 
                }
            </FormGroup>
        </>);
    }

    render() {
        const { FormValueState } = this.props;
        const options = this.getOptionsArray();
        let renderComponent = '';
        if (options.displayType === 'Dropdown') {
            renderComponent = this.renderSelection(FormValueState, options)
        } else if (options.displayType === 'Checkbox' || options.displayType === 'Radio') {
            renderComponent = this.renderCheckBox(FormValueState, options, options.displayType === 'Checkbox' ? 'checkbox' : 'radio');
        } else {
            renderComponent = this.renderButton(FormValueState, options);
        }
        return (
            <div className={this.getTemplateClasses()} style={this.getGeneratedStyles()}>
                <div className='row'>
                    <div className='col-12'>
                        {options.label}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 text-center'>
                        {renderComponent}
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    ...BaseFieldStateToProps(state),
    FormValueState: state.FormValue
})

const mapActionsToProps = {
    ...BaseFieldActionsToProps,
    setFormValue
}

export default connect(mapStateToProps, mapActionsToProps)(RecurringGift);
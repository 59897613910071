import {combineReducers} from "redux";

import ComponentReducer from "./ComponentReducer";
import GlobalSettingsReducer from "./GlobalSettingsReducer";
import FormIdValueReducer from "./FormIdValueReducer";
import FormRenderReducer from "./FormRenderReducer";
import FormValidationReducer from "./FormValidationReducer";
import FormValueReducer from "./FormValueReducer";
import MetaReducer from "./MetaReducer";
import PageReducer from "./PageReducer";
import ReceiptReducer from "./ReceiptReducer";
import StoredClientReducer from "./StoredClientReducer";
import TriggerReducer from "./TriggerReducer";
import VariableReducer from "./VariableReducer";

export default combineReducers({
    Component: ComponentReducer,
    GlobalSettings: GlobalSettingsReducer,
    FormIdValue: FormIdValueReducer,
    FormRender: FormRenderReducer,
    FormValidation: FormValidationReducer,
    FormValue: FormValueReducer,
    Meta: MetaReducer,
    Page: PageReducer,
    Receipt: ReceiptReducer,
    StoredClient: StoredClientReducer,
    Trigger: TriggerReducer,
    Variable: VariableReducer
})
import React from "react";
import { getParameterByName, } from "../../../lib";

function PaymentInfoFields({ component, options, ReceiptState }) {

    let paymentType = '';
    if (ReceiptState.data.paymentType === 'cc') {
        paymentType = 'Credit Card';
    } else if (ReceiptState.data.paymentType === 'paypal') {
        paymentType = 'PayPal';
    } else if (ReceiptState.data.paymentType === 'apple') {
        paymentType = 'ApplePay';
    } else if (ReceiptState.data.paymentType === 'bank') {
        paymentType = 'ACH Banking';
    } else if (ReceiptState.data.paymentType === 'venmo') {
        paymentType = 'Venmo';
    }

    return (
        <>
            <div className='row TYP_paymentType' style={{ padding: 10 }}>
                <div className='col-6 TYC_Label_paymentType' style={{ fontWeight: 'bold' }}>
                    Payment Type
                </div>
                <div className='col-6 TYC_Value_paymentType'>{paymentType}</div>
            </div>
            {ReceiptState.data.paymentType === 'cc' &&
                <div className='row TYP_cardType' style={{ padding: 10 }}>
                    <div className='col-6 TYC_Label_cardType' style={{ fontWeight: 'bold' }}>
                        Card Type
                    </div>
                    <div className='col-6 TYC_Value_cardType'>{ReceiptState.data.cardType}</div>
                </div>}
            {ReceiptState.data.paymentType === 'cc' &&
                <div className='row TYP_cardNumber' style={{ padding: 10 }}>
                    <div className='col-6 TYC_Label_cardNumber' style={{ fontWeight: 'bold' }}>
                        Card Number
                    </div>
                    <div className='col-6 TYC_Value_cardNumber'>****************</div>
                </div>}
            <div className='row TYP_receipt_number' style={{ padding: 10 }}>
                <div className='col-6 TYC_Label_receiptNumber' style={{ fontWeight: 'bold' }}>
                    Receipt Number
                </div>
                <div className='col-6 TYC_Value_receiptNumber'>{ReceiptState.data.transactionId}</div>
            </div>
            {typeof getParameterByName('type') !== 'undefined' && getParameterByName('type') === 'recurring' &&
                <>
                    <div className='row TYP_recurringStatus' style={{ padding: 10 }}>
                        <div className='col-6 TYC_Label_recurringStatus' style={{ fontWeight: 'bold' }}>
                            Recurring Status
                        </div>
                        <div className='col-6 TYC_Value_recurringStatus'>{ReceiptState.data.billingSubscriptionStatus}</div>
                    </div>
                    <div className='row TYP_recurringStartDate' style={{ padding: 10 }}>
                        <div className='col-6 TYC_Label_recurringStartDate' style={{ fontWeight: 'bold' }}>
                            Recurring Start Date
                        </div>
                        <div className='col-6 TYC_Value_recurringStartDate'>{ReceiptState.data.billingSubscriptionStartDate}</div>
                    </div>
                </>
            }
        </>
    );
}

export default PaymentInfoFields;
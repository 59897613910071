import Textbox, {TextboxActionsToProps, TextboxStateToProps} from "../BaseFields/Textbox";
import {connect} from "react-redux";

class BillingFirstName extends Textbox{


}

const mapStateToProps = (state) => ({
    ...TextboxStateToProps(state)
})

const mapActionsToProps = {
    ...TextboxActionsToProps
}

export default connect(mapStateToProps, mapActionsToProps)(BillingFirstName);
import {RECEIPT_SET_DATA} from "../actionTypes";

const initialState = {
    data: {}
};

export default function(state = initialState, action){
    switch (action.type){
        case RECEIPT_SET_DATA:
            return {
                ...state,
                data: action.payload
            }
        default:
            return state;
    }
}
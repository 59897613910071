import React, { Component } from 'react';
import { Alert, Button } from "react-bootstrap";
import FormStep from "../../Components/FormStep";
import StepRenderer from "../../Components/StepRenderer";
import { getParameterByName, isTruthy, renderString } from '../../lib';
import DebugBar from "../../Components/DebugBar";
import Spinner from "react-bootstrap/Spinner";
import Receipt from "../../Components/ReceiptPage/Receipt";
import ModalRenderer from "../../Components/ModalRenderer";
import StagingAlert from "../../Components/StagingAlert";
import ReCAPTCHA from "react-google-recaptcha";

import { connect } from 'react-redux'
import { initGlobalSettingsSocket } from "../../Redux/actions/GlobalSettingsActions";
import { initFormRenderSocket } from "../../Redux/actions/FormRenderActions";
import { initTriggers } from "../../Redux/actions/TriggerActions";
import { initVariableSocket } from "../../Redux/actions/VariableActions";
import { storedClientIsValid, storedClientProcessTriggers, storedClientClear } from "../../Redux/actions/StoredClientActions";
import moment from "moment";
import 'moment-timezone';
import { withRouter } from "react-router";
import { setSkippedSteps } from "../../Redux/actions/FormRenderActions";

class DonationForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            storedClient: false,
            checkRememberMeField: false,
            skippedSteps: []
        };

        window.rkd.form.on('internal_event', (event) => this.triggerListener(event));
    }

    triggerListener(event) {
        const { pages, skippedSteps } = this.props.FormRenderState;
        pages.map((page, index) => {
            const { triggers } = page;
            if (triggers && triggers.includes(event.triggerIndex)) {
                if (['hide', 'show'].includes(event.action)) {
                    if(event.action === 'hide') {
                        if(!skippedSteps.includes(index)) {
                            skippedSteps.push(index);
                        }
                    } else {
                        const index1 = skippedSteps.indexOf(index);
                        if (index > -1) { // only splice array when item is found
                            skippedSteps.splice(index1, 1); // 2nd parameter means remove one item only
                        }
                    }
                }
            }
        });
        this.setState({ skippedSteps: skippedSteps})
        setSkippedSteps('skippedSteps', skippedSteps);
    }

    async componentDidMount() {
        const { initVariableSocket, history } = this.props;
        await initVariableSocket();
        window.rkd.form.on("form_render", async () => {
            await storedClientIsValid();

            let storedClient = await new Promise(resolve => resolve(storedClientIsValid()));
            this.setState({ storedClient });

            storedClientProcessTriggers();
        });
        await initGlobalSettingsSocket();
        await initFormRenderSocket(this.props.formId || getParameterByName('df'));
        await initTriggers();

        if (!window.rkd.routeHistory) {
            console.log("Donation Form - componentDidMount - Load History")

            window.rkd.routeHistory = history;
        }

        // console.log("composerDidMount Recaptcha SiteKey : ", this.props.FormRenderState.recaptchaSiteKey);
        // if((this.props.FormRenderState.googleRecaptcha && (!this.props.FormValueState.reCaptchaResponse || this.props.FormValueState.reCaptchaResponse === ''))) {
        //     // eslint-disable-next-line
        //     this.props.FormRenderState.recaptcha?.props?.grecaptcha.execute(); 
        //     console.log("FB - checkRecaptcha():grecaptcha.execute()");
        // }
        

    }

    async componentDidUpdate() {

        if(this.props.FormRenderState.pages.length > 0 && this.state.checkRememberMeField === false)
        {
            let rememberMe = true;
            this.props.FormRenderState.pages.map((page) => {
                page.components.map((field) => {
                    if(field.id === "rememberMe")
                    {
                        rememberMe = false;
                    }
                });
            });

            if(rememberMe) 
            {
                storedClientClear();
            }

            this.setState({ checkRememberMeField: true });
        }

        let storedClient = await new Promise(resolve => resolve(storedClientIsValid()));
        if(this.state.storedClient !== storedClient)
        {
            this.setState({ storedClient });
        }

        console.log("componentDidUpdate Recaptcha SiteKey : ", this.props.FormRenderState.recaptchaSiteKey);

    }

    handleChange = value => {
        // alert('handleChange');
        const { FormValueState } = this.props;

        console.log("onChange prop - Captcha value:", value);
        FormValueState.reCaptchaResponse = value
        this.handleSubmit();
    };

    // handleSubmit() {
    handleSubmit = async () => {
        const { FormRenderState, FormValueState } = this.props;

        console.log(`FB - handleSubmit - Call the function submit Form. Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);
        if(FormRenderState.googleRecaptcha) {
            console.log("FB - handleSubmit():FormValueState.reCaptchaResponse", FormValueState.reCaptchaResponse);
            if (!FormValueState.reCaptchaResponse) {
                try {
                    // // eslint-disable-next-line
                    console.log("FB - handleSubmit():grecaptcha?.getResponse()", FormRenderState.recaptcha?.props?.grecaptcha?.getResponse());
                    // eslint-disable-next-line
                    FormValueState.reCaptchaResponse = (FormRenderState.googleRecaptcha && FormValueState.reCaptchaResponse !== '') 
                    ? FormValueState.reCaptchaResponse : FormRenderState.recaptcha?.props?.grecaptcha?.getResponse();
                } catch (error) {
                    console.log(error);
                }
            }
            else {
                window.rkd.form.submitForm();
            }

        }
        else {
            window.rkd.form.submitForm();
        }

    }

    checkRecaptcha = async () => {
        console.log(`FB - checkRecaptcha - Checking the reCaptcha. Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);
        const { FormRenderState, FormValueState } = this.props;
        
        if (FormValueState.paymentType !== 'generic_credit_card') {
            console.log(`FB - checkRecaptcha - This is not a generic cc. Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);
            console.log(`FB - checkRecaptcha - Check the googleRecaptcha object. Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);
            console.log(FormRenderState.googleRecaptcha);
            
            console.log(`FB - checkRecaptcha - Check the googleRecaptcha response. Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);
            if(FormRenderState.googleRecaptcha) {
                try {
                    // eslint-disable-next-line
                    console.log("FB - checkRecaptcha():grecaptcha", FormRenderState.recaptcha?.props?.grecaptcha);    
                    // eslint-disable-next-line
                    console.log("FB - checkRecaptcha():grecaptcha.getResponse()", FormRenderState.recaptcha?.props?.grecaptcha?.getResponse());    
                } catch (error) {
                    console.log(error);
                }

            } else {
                console.log(`FB - checkRecaptcha - Google reCaptcha is not set. Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);    
            }

            if((FormRenderState.googleRecaptcha && (!FormValueState.reCaptchaResponse || FormValueState.reCaptchaResponse === ''))) {
                // eslint-disable-next-line
                FormRenderState.recaptcha?.props?.grecaptcha.execute(); 
                console.log("FB - checkRecaptcha():grecaptcha.execute()");
            }
            this.handleSubmit();
        }
        else
        {
            console.log(`FB - checkRecaptcha - This is a generic cc. Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);
        }
    }

    handleReCaptchaExpired = () => {
        console.log("FB - handleReCaptchaExpired");
        const { FormRenderState } = this.props;
        // eslint-disable-next-line
        FormRenderState.recaptcha?.props?.grecaptcha.reset();
        // eslint-disable-next-line
        FormRenderState.recaptcha?.props?.grecaptcha.execute();
    }

    getSubmitText() {
        const { FormValueState } = this.props;
        let submitTextLable = "Submit {amount}";
        if (typeof FormValueState.recurring_gift !== "undefined") {
            if (typeof FormValueState.giftArrayInfo.SubmitButtonOneTimeText !== "undefined" && FormValueState.giftArrayInfo.SubmitButtonOneTimeText !== "" && FormValueState.recurring_gift === "single") {
                submitTextLable = FormValueState.giftArrayInfo.SubmitButtonOneTimeText;
            } else if (typeof FormValueState.giftArrayInfo.SubmitButtonMonthlyText !== "undefined" && FormValueState.giftArrayInfo.SubmitButtonMonthlyText !== "" && FormValueState.recurring_gift === "recurring") {
                submitTextLable = FormValueState.giftArrayInfo.SubmitButtonMonthlyText;
            }
        }
        if (typeof FormValueState.giftAmount === 'undefined' || !FormValueState.giftAmount) {
            return renderString(submitTextLable.replace(/\{amount}/g, ''));
        } else {
            return renderString(submitTextLable.replace(/\{amount}/g, `$${parseFloat(FormValueState.giftAmount).toFixed(2)}`))
        }
    }

    renderForm() {
        const { FormRenderState, FormValueState } = this.props;
        let isSectionLength = 0, ispageLength = 0;

        isSectionLength = FormRenderState.pages.filter((p) => (typeof p.pageType !== "undefined" && p.pageType === "section")).length;
        ispageLength = FormRenderState.pages.filter((p) => (typeof p.pageType === "undefined" || p.pageType === "step")).length;

        return (
            <>
                {FormRenderState.submissionError !== '' && 
                    <div className='alert alert-danger' role='alert' dangerouslySetInnerHTML={{__html: FormRenderState.submissionError}}>

                    </div>
                }
                {!FormRenderState.isLoaded && FormValueState.paymentType !== 'braintree_venmo' && (
                    <div className="d-flex justify-content-center mt-4">
                        <div className="spinner-border" role="status" style={{ width: "3rem", height: "3rem" }}>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}
                {FormRenderState.isLoaded && FormRenderState.pages.length === 0 && isSectionLength === 0 &&
                    <Alert variant='danger'>This form has no content</Alert>
                }
                {((FormRenderState.isLoaded && FormRenderState.pages.length === 1) || (isSectionLength > 0) || this.state.storedClient) &&
                    <div>
                        {FormRenderState.pages.map((index, i) => <div key={i} className={`page-section page-section-${i} ${this.state.skippedSteps.includes(i) ? `d-none` : ``}`}><FormStep index={i} /></div>)}

                        {FormRenderState.isLoading && FormValueState.paymentType !== 'braintree_venmo' && <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>}
                        {!FormRenderState.isLoading && (this.state.storedClient || (FormValueState.paymentType !== 'braintree_paypal' && FormValueState.paymentType !== 'stripe_applepay' && FormValueState.paymentType !== 'braintree_applepay' && FormValueState.paymentType !== 'braintree_venmo')) && <div className="donation-form-button-parent"><Button onClick={(e) => {
                            e.preventDefault();
                            this.checkRecaptcha()}} 
                            className="donation-form-submit-button" 
                            variant='success'>{this.getSubmitText()}
                            </Button></div>}
                        {FormValueState.paymentType === 'generic_credit_card' && <Alert variant='danger'>You must configure your payment processors in order to process payments.</Alert>}
                        {FormRenderState.googleRecaptcha && FormRenderState.recaptchaSiteKey !== '' &&
                            <ReCAPTCHA
                                size="invisible"
                                sitekey={FormRenderState.recaptchaSiteKey}
                                onChange={this.handleChange}
                                onExpired={this.handleReCaptchaExpired}
                                ref={ref => FormRenderState.recaptcha = ref}
                            />
                        }
                    </div>
                }

                {FormRenderState.isLoaded && ispageLength > 1 && !this.state.storedClient && <StepRenderer />}
                <ModalRenderer />
                <StagingAlert />
                {isTruthy(getParameterByName('debug')) && <DebugBar />}
            </>
        );
    }

    render() {
        if (getParameterByName('receipt')) {
            return (<Receipt />)
        } else {
            return this.renderForm();
        }
    }

}

const mapStateToProps = (state) => ({
    FormRenderState: state.FormRender,
    FormValueState: state.FormValue
});

const mapActionsToProps = {
    initFormRenderSocket,
    initVariableSocket,
    setSkippedSteps
}

export default withRouter(connect(mapStateToProps, mapActionsToProps)(DonationForm));

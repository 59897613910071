import AbstractBase from './AbstractBase';
import { isTruthy, getParameterByName, isStage, spaceWithUnderscore, checkValidAmount } from "../lib";
import axios from 'axios';
import qs from 'qs'
import createHash from 'hash-generator'
import moment from "moment";
import 'moment-timezone'
import jwt_decode from 'jwt-decode';
import hostname from "../Libs/hostname";
import store from "../Redux/store";
import { setFormLoading } from "../Redux/actions/FormRenderActions";
import { setMetaClient } from "../Redux/actions/MetaActions";
import { setReceiptData } from "../Redux/actions/ReceiptActions";
import { storedClientStoreWithoutReset, storedClientIsValid } from "../Redux/actions/StoredClientActions";
import { setFormValue } from "../Redux/actions/FormValueActions";
import { getDynamicErrorMessage } from "../lib";
import { getGAID } from "../GlobalFunction/GlobalFunction";

class DMC extends AbstractBase {

    /* The form editor presents a validation editor interface, but
    these fields need to remain for the default validators */
    validators = {
        'billingCity': 'required|alpha|minLength,1',
        'billingEmail': 'required|email',
        'billingFirstName': 'required|alpha|minLength,1',
        'billingLastName': 'required|alpha|minLength,1',
        'billingPhone': 'required|phone',
        'billingState': 'required',
        'billingStreetAddress1': 'required|minLength,1',
        'billingZip': 'required|postal',
        'tributeFirstName': 'required|alpha|minLength,1',
        'tributeLastName': 'required|alpha|minLength,1',
        'tributeRecipientEmail': 'required|email',
        'tributeRecipientPhoneNumber': 'required|phone',
        'tributeRecipientFirstName': 'required|alpha|minLength,1',
        'tributeRecipientLastName': 'required|alpha|minLength,1',
        'tributeCity': 'required|alpha|minLength,1',
        'tributeState': 'required',
        'tributeStreetAddress1': 'required|minLength,1',
        'tributeZip': 'required|postal',
    }

    fieldMaps = {
        'billingCity': 'city',
        'billingEmail': 'email',
        'billingFirstName': 'firstName',
        'billingLastName': 'lastName',
        'billingPhone': 'phone',
        'billingState': 'state',
        'billingStreetAddress1': 'street1',
        'billingStreetAddress2': 'street2',
        'billingZip': 'postal',
        'salutation': 'title',
        'stripe_card': 'token',
        'paypal_token': 'token',
        'applepay_token': 'token',
        'braintree_token': 'token',
        'venmo_token': 'token',
        'paymentType': 'paymentType',
        'tributeType': 'hontitle',
        'tributeCity': 'recipientcity',
        'tributeNotificationType': 'tributeAlert',
        'tributeFirstName': 'honfirstName',
        'tributeLastName': 'honlastName',
        'tributeMessage': 'recipientMessage',
        'tributeRecipientPhoneNumber': 'recipientphone',
        'tributeRecipientEmail': 'recipientemail',
        'tributeRecipientFirstName': 'recipientFirstName',
        'tributeRecipientLastName': 'recipientLastName',
        'tributeState': 'recipientstate',
        'tributeStreetAddress1': 'recipientstreet',
        'tributeZip': 'recipientpostal',
        'transactionId': 'transactionID',
        'cardType': 'cardType',
        'transactionDate': 'date',
        'frequency': 'recurring_gift',
        'Lightbox-if-Donation-Error-after-n-attempts': 'Lightbox-if-Donation-Error-after-n-attempts'

        // 'companyMatchingName': 'dmc_custom_field_OrgName',
        // 'companyDonorName': 'dmc_custom_field_CompanyName',
        // 'giftNotes': 'dmc_custom_field_gift_notes'
    }

    receiptFieldMaps = {
        'billingCity': 'city',
        'billingEmail': 'email',
        'billingFirstName': 'firstName',
        'billingLastName': 'lastName',
        'billingPhone': 'phone',
        'billingState': 'state',
        'billingStreetAddress1': 'street1',
        'billingStreetAddress2': 'street2',
        'billingZip': 'postal',
        'salutation': 'title',
        'paymentType': 'paymentType',
        'tributeCity': 'recipientcity',
        'tributeFirstName': 'honfirstName',
        'tributeLastName': 'honlastName',
        'tributeMessage': 'recipientMessage',
        'tributeRecipientPhoneNumber': 'recipientphone',
        'tributeRecipientEmail': 'recipientemail',
        'tributeRecipientFirstName': 'recipientfirstName',
        'tributeRecipientLastName': 'recipientlastName',
        'tributeState': 'recipientstate',
        'tributeStreetAddress1': 'recipientstreet',
        'tributeZip': 'recipientpostal',
        'transactionId': 'transactionID',
        'transactionID': 'transactionID',
        'cardType': 'cardType',
        'transactionDate': 'date',
        'frequency': 'recurring_gift',
        'tributeType': 'isTribute',
        'tributeNotificationType': 'tributeAlert',
        'anonymousCheckbox': 'anonymous',
        'companyMatchingCheckboxPlusName': 'OrgName',
        'companyDonorCheckboxPlusName': 'CompanyName',
        'giftNotes': 'gift_notes',
        'referralSource': 'source'
    }

    /**
     * List of unexpected or technical errors. Please add errors as below format if you found something unexpected.
     * Error Code  |    Payment Processor   |   Error Message
     * ------------|------------------------|----------------------------------------------------------------------
     * E00003      |    Authorize.Net       |   Error occurs into creating new donor process.
     * ------------|------------------------|----------------------------------------------------------------------
     */

    unExpectedErrorCodes = ['E00003'];

    async loadBraintreeToken(client, processor = 'default') {
        try {
            const braintreeTokenAxios = await axios.get(`${hostname.dmc()}/security/tokenBraintree?client=${client}&setting=${processor}`);
            const braintreeTokenResponse = braintreeTokenAxios.data;
            if (typeof braintreeTokenResponse.status !== 'undefined' && braintreeTokenResponse.status === 'success') {
                return braintreeTokenResponse.message;
            }
        } catch (e) {
            console.log('Braintree Token fetch Failed');
        }

        return null;
    }

    async getStripeKey() {
        const FormRenderState = store.getState().FormRender;
        return (FormRenderState.paymentProviders.creditCard && FormRenderState.paymentProviders.creditCard.type === 'stripe') ?
            FormRenderState.paymentProviders.creditCard.publicKey : '';
    }

    async getStripeApplePayKey() {
        const FormRenderState = store.getState().FormRender;
        return (FormRenderState.paymentProviders.applePay && FormRenderState.paymentProviders.applePay.type === 'stripe') ?
            FormRenderState.paymentProviders.applePay.publicKey : '';
    }

    async getBraintreeApplePayKey() {
        const FormRenderState = store.getState().FormRender;
        return (FormRenderState.paymentProviders.applePay && FormRenderState.paymentProviders.applePay.type === 'braintree') ?
            FormRenderState.paymentProviders.applePay.publicKey : '';
    }

    async getAuthKeys() {
        const FormRenderState = store.getState().FormRender;
        return (FormRenderState.paymentProviders.creditCard && FormRenderState.paymentProviders.creditCard.type === 'auth') ?
            {
                apiLoginID: FormRenderState.paymentProviders.creditCard.apiLoginID,
                clientKey: FormRenderState.paymentProviders.creditCard.clientKey
            } : null;
    }

    async loadClientMeta() {
        const MetaState = store.getState().Meta;

        if (Object.keys(MetaState.client).length > 0) { return MetaState.client; }
        const client = getParameterByName("cl");

        const res = await axios.get(`${hostname.dmc()}/request/meta?cl=${client}`);
        store.dispatch(setMetaClient(res.data.data));

        return res.data.data;
    }

    async userImpression() {

        try {
            const FormRenderState = store.getState().FormRender;

            let campaignFound = getParameterByName('campaign') || FormRenderState.options.campaign || 'NONE';

            let gaID = await getGAID();

            let locationInfo = await this.geoLocation();

            let messageNew = {

                client: getParameterByName('cl'),
                page: spaceWithUnderscore(FormRenderState.formName),
                source: getParameterByName('source') || getParameterByName('appeal') || '',
                ref: getParameterByName('ref') || 'none',
                campaign: campaignFound,
                messageType: 'impression',
                gaID: gaID,
                ip: locationInfo.traits.ipAddress,
                ipCity: locationInfo.city.names.en,
                ipState: locationInfo.subdivisions[0].isoCode,
                ipCoutry: locationInfo.country.isoCode,
                ipPostal: locationInfo.postal.code,
                ipUser: locationInfo.traits.userType,
                isp: locationInfo.traits.organization,
                utmSource: getParameterByName('utm_source') || '',
                utmMedium: getParameterByName('utm_medium') || '',
                utmCampaign: getParameterByName('utm_campaign') || '',
                utmContent: getParameterByName('utm_content') || '',
                utmTerm: getParameterByName('utm_term') || '',
                utmReferrer: getParameterByName('utm_referral') || getParameterByName('utm_referrer') || document.referrer || ''
            };

            await axios.post(`${hostname.dmc()}/users/impression`, qs.stringify(messageNew));
        } catch (error) {
            console.log("user impression error")
            console.log(error)
        }
    }

    async impression() {

        try {
            const FormRenderState = store.getState().FormRender;

            let campaignFound = getParameterByName('campaign') || FormRenderState.options.campaign || 'NONE';

            let currentTimeDate = moment().tz('America/New_York').format('MM-DD-YYYY');
            let currentTimeMonth = moment().tz('America/New_York').format('MM-YYYY');

            let locationInfo = await this.geoLocation();

            let messageNew = {
                client: getParameterByName('cl'),
                page: spaceWithUnderscore(FormRenderState.formName),
                source: getParameterByName('source') || getParameterByName('appeal') || '',
                ref: getParameterByName('ref') || 'none',
                status: isStage() ? 'preview' : 'live',
                campaign: campaignFound,
                messageType: 'impression',
                currentTimeDate: currentTimeDate,
                currentTimeMonth: currentTimeMonth,
                utmSource: getParameterByName('utm_source') || '',
                utmMedium: getParameterByName('utm_medium') || '',
                utmCampaign: getParameterByName('utm_campaign') || '',
                utmContent: getParameterByName('utm_content') || '',
                utmTerm: getParameterByName('utm_term') || '',
                utmReferrer: getParameterByName('utm_referral') || getParameterByName('utm_referrer') || document.referrer || '',
                ip: locationInfo.traits.ipAddress,
                ipCity: locationInfo.city.names.en,
                ipState: locationInfo.subdivisions[0].isoCode,
                ipCoutry: locationInfo.country.isoCode,
                ipPostal: locationInfo.postal.code,
                ipUser: locationInfo.traits.userType,
                isp: locationInfo.traits.organization
            };
            await axios.post(`${hostname.dmc()}/reporting/impression`, qs.stringify(messageNew));

        } catch (error) {

            console.log("impression error")
            console.log(error)

        }
    }

    async loadACHToken(client, userId, processor = 'default') {
        try {
            const achTokenAxios = await axios.get(`${hostname.dmc()}/security/getACHToken?client=${client}&setting=${processor}&userId=${userId}`);
            const achTokenResponse = achTokenAxios.data;
            if (typeof achTokenResponse.status !== 'undefined' && achTokenResponse.status === 'success') {
                return achTokenResponse.link_token;
            }
        } catch (e) {
            console.log('Braintree Token fetch Failed');
        }

        return null;
    }

    async getPaymentTypes() {
        let GlobalSettingsState;
        GlobalSettingsState = store.getState().FormRender.paymentProviders;
        // console.log("DMC->getPaymentTypes()", GlobalSettingsState);
        // TODO: Rather than default to RKD on missing cl param, we should always have this in the URL string
        const client = getParameterByName('cl') ?? 'RKD';
        let braintreeToken = null;

        const availPayments = [];
        if (GlobalSettingsState.creditCard) {
            switch (GlobalSettingsState.creditCard.type) {
                case "stripe":
                    availPayments.push("stripe");
                    break;
                case "braintree":
                    // TODO: Remove the default Braintree processor before production release
                    braintreeToken = await this.loadBraintreeToken(client, GlobalSettingsState.creditCard.name);
                    store.dispatch(setFormValue('braintreeCCToken', braintreeToken));
                    availPayments.push('braintree');
                    break;
                case "auth":
                    availPayments.push("auth");
                    break;
                default: // Do nothing for the default case, satisfying linter
                    break;
            }
        }

        if (GlobalSettingsState.applePay && GlobalSettingsState.applePay.type === 'stripe') {
            availPayments.push("stripe_applepay");
        }

        if (GlobalSettingsState.applePay && GlobalSettingsState.applePay.type === 'braintree') {
            // TODO: Remove the default Braintree processor before production release
            braintreeToken = await this.loadBraintreeToken(client, GlobalSettingsState.applePay.name);


            if (braintreeToken !== null) {
                const jwtData = jwt_decode(braintreeToken, { header: true });
                if (jwtData.applePay) {
                    store.dispatch(setFormValue('braintreeApplePayToken', braintreeToken));
                    availPayments.push("braintree_applepay");
                }
            }
        }

        if (GlobalSettingsState.paypal && GlobalSettingsState.paypal.type === 'braintree') {
            // TODO: Remove the default Braintree processor before production release
            braintreeToken = await this.loadBraintreeToken(client, GlobalSettingsState.paypal.name);

            if (braintreeToken !== null) {
                const jwtData = jwt_decode(braintreeToken, { header: true });
                if (jwtData.paypalEnabled) {
                    store.dispatch(setFormValue('braintreePayPalToken', braintreeToken));
                    availPayments.push("braintree_paypal");
                }
            }
        }

        if (GlobalSettingsState.venmo && GlobalSettingsState.venmo.type === 'braintree') {
            // TODO: Remove the default Braintree processor before production release
            braintreeToken = await this.loadBraintreeToken(client, GlobalSettingsState.venmo.name);

            if (braintreeToken !== null) {
                const jwtData = jwt_decode(braintreeToken, { header: true });

                store.dispatch(setFormValue('braintreeVenmoToken', braintreeToken));
                availPayments.push("braintree_venmo");
            }
        }

        if (GlobalSettingsState.ach && GlobalSettingsState.ach.type === 'ach') {
            availPayments.push("stripe_ach");
        }

        return availPayments;
    }

    getGatewayInfo(type) {
        const { paymentProviders } = store.getState().FormRender;
        switch (type) {
            case "stripe":
            case "braintree":
                return { type: "creditCard", publicKey: paymentProviders.creditCard.publicKey }
            case "stripe_applepay":
                return { type: "applePay", publicKey: paymentProviders.applePay.publicKey }
            case "braintree_paypal":
                return { type: "paypal", publicKey: paymentProviders.paypal.publicKey }
            case "braintree_applepay":
                return { type: "applePay", publicKey: paymentProviders.applePay.publicKey }
            case "braintree_venmo":
                return { type: "venmo", publicKey: paymentProviders.venmo.publicKey }
            case "stripe_ach":
                return { type: "ach", publicKey: paymentProviders.ach.publicKey }
            case "auth":
                return { type: "creditCard", apiLoginID: paymentProviders.creditCard.apiLoginID, clientKey: paymentProviders.creditCard.clientKey }
            default:
                return { type: "", publicKey: "" }
        }
    }

    async geoLocation() {
        const client = getParameterByName('cl');
        try {

            const { data } = await axios.get(`https://maxmind-cache.dmc.systems/get-data?cl=ZTM-${client}`);
            console.log('geo data', data);
            return data;
        } catch (e) {
            console.log(`FB - Get Location catch error : ${JSON.stringify(e)}`);
            return {};
        }
    }

    async submit() {
        try {

            this.clearErrors();
            await this.processSubmitTriggers(); // Triggers can invalidate the form

            const FormValueState = store.getState().FormValue;
            const FormValidationState = store.getState().FormValidation;
            const FormRenderState = store.getState().FormRender;
            console.log(`FB - submit - Can form submit?. Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);

            let isValidAmount = (Number(FormValueState.giftAmount) >= Number(FormValueState.giftMinAmountValue)) ? true : false;

            if (FormValidationState.canSubmit && checkValidAmount(FormValueState.giftAmount) && isValidAmount) {
                console.log(`FB - submit - Yes. Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);

                const url = this.getSubmissionURL();
                const values = await this.getPreparedForm();

                console.log(`FB - getPreparedForm Response - ${JSON.stringify(values)} `);

                const response = await this.submitData(url, values);

                console.log(`FB - submitData Response - ${JSON.stringify(response)} `);
                console.log(`FB - submit - Response of form submit. Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);

                if (response.data) {

                    if (response.data.status === 'Error') {
                        console.log(`FB - submit - form not submitted successfully!. Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);
                        
                        if(this.unExpectedErrorCodes.includes(response.data.errorCode)) {
                            this.setError(response.data.message);
                        } else {
                            this.setError(getDynamicErrorMessage("payment"));
                            this.setPaymentFailure(true);
                        }

                        store.dispatch(setFormLoading(false));
                        window.rkd.form.setErrorAttempts();
                        
                        // reset recaptcha
                        if (FormRenderState.googleRecaptcha && FormRenderState.recaptcha) {
                            FormRenderState.recaptcha.reset();
                        }
                        
                        // this.setError(response.data.message);
                        window.scroll({ top: 0, behavior: "smooth" })
                    } else {
                        console.log(`FB - submit - form submitted successfully. Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);
                        this.setPaymentFailure(false);
                        if (typeof FormValueState.rememberMe !== 'undefined' && isTruthy(FormValueState.rememberMe)) {
                            let storedClient = await new Promise(resolve => resolve(storedClientIsValid()));
                            if (!storedClient && FormValueState.paymentType !== "braintree_venmo") {
                                storedClientStoreWithoutReset({
                                    paymentProvider: this.getGatewayInfo(FormValueState.paymentType),
                                    customer: response.data.customer,
                                    mode: response.data.mode,
                                    url: url,
                                    receiptID: response.data.receiptID,
                                    paymentType: FormValueState.paymentType,
                                    billingFirstName: FormValueState.billingFirstName,
                                    billingLastName: FormValueState.billingLastName,
                                    last4: store.getState().FormValue.last4 ? store.getState().FormValue.last4 : null
                                });
                            }
                        }

                        let recurringParams = '';
                        if (response.data.type === 'recurring') {
                            recurringParams = `&type=recurring&service=${this.getPaymentProvider()}&subscriptionFrequency=${FormValueState.recurring_gift}`;
                        }
                        this.redirectToReceipt(response.data.receiptID, recurringParams);
                    }
                }
            } else {
                console.log(`FB - submit - No. Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);
                // The form cannot submit, let's display an error to the user
                store.dispatch(setFormLoading(false));
                const errorMessage = (!checkValidAmount(FormValueState.giftAmount) && !isValidAmount) ? getDynamicErrorMessage("amount") : (FormRenderState.paymentFailure ? getDynamicErrorMessage("payment") : getDynamicErrorMessage());
                if (!checkValidAmount(FormValueState.giftAmount) && !isValidAmount) {
                    this.setInvalidAmount(true);
                }
                else {
                    this.setGeneralErrors(true);
                }
                console.log(`FB - submit - Check error messages. Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);
                console.log(errorMessage);
                this.setError(errorMessage);
                window.scrollTo({ top: 0, behavior: 'smooth' });
                window.rkd.form.setErrorAttempts();
            }
        } catch (error) {
            console.log(`FB - submit - Error catched into catch block?. Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);
            console.log(error);
        }
    }

    redirectToReceipt(recieptID, frequencyType) {

        /**
         * CAUTION: Don't directly change the below receipt redirection URL.
         * If you want to change the receipt the check/Unit test below all things.
         * 1. Thank You page for Section forms (Without steps).
         * 2. Thank You page for Step forms.
         * 3. Thank You page for Modal forms.
         * 4. Thank You page for embeded forms with script and iframe elements.
         */

        const year = moment().tz('America/New_York').format('YYYY');
        const month = moment().tz('America/New_York').format('MM');

        const { push: historyPush, location } = window.rkd.routeHistory;
        const symbol = (location.search) ? '&' : '?';

        historyPush(`${location.pathname}${location.search}${symbol}receipt=${recieptID}&years=${year}&month=${month}-${year}${frequencyType}${window.location.hash}`);
    }

    async getReceiptData() {
        const receiptID = getParameterByName('receipt');
        const client = getParameterByName('cl');
        const month = getParameterByName('month');
        const year = getParameterByName('years');
        const type = getParameterByName('type');
        const mode = isStage() ? 'staging' : 'production';
        let service = '';

        if (typeof type !== 'undefined' && type === 'recurring') {
            service = `&type=recurring&service=${getParameterByName('service')}`;
        }

        const requestURL = `${hostname.dmc()}/receipt/findTransaction/?receipt=${receiptID}&client=${client}&mode=${mode}&year=${year}&month=${month}${service}`;

        const { data } = await axios.get(requestURL);
        if (data.status === 'success') {
            const receiptData = this.mapExternalObjectToInternal(data.message);
            receiptData.billingStreetAddress1 = data.message.streetaddress;
            receiptData.billingStreetAddress2 = data.message.streetaddress2;
            receiptData.giftAmount = data.message.amount;
            receiptData.billingSubscriptionStatus = data.message.subscriptionstatus;
            receiptData.billingSubscriptionStartDate = data.message.currentTimeDate;
            receiptData.customFields = data.message.customFields;
            if (typeof type !== 'undefined' && type === 'recurring') {
                receiptData.transactionId = data.message.subscriptionID;
            }

            window.receiptData = receiptData;
            store.dispatch(setReceiptData(receiptData));
        }
    }

    getPaymentProvider() {
        const FormValueState = store.getState().FormValue;
        const paymentType = store.getState().StoredClient.valid ? store.getState().StoredClient.data.paymentType : FormValueState.paymentType;

        if (['stripe', 'stripe_applepay', 'stripe_ach'].includes(paymentType)) {
            return `stripe`;
        } else if (['braintree', 'braintree_paypal', 'braintree_applepay', 'braintree_venmo'].includes(paymentType)) {
            return `braintree`;
        } else if (['auth'].includes(paymentType)) {
            return `auth`;
        }
    }

    getSubmissionURL() {
        const FormValueState = store.getState().FormValue;
        if (store.getState().StoredClient.valid) {
            return store.getState().StoredClient.data.url;
        }

        if (['stripe', 'stripe_applepay', 'stripe_ach'].includes(FormValueState.paymentType)) {
            return `${hostname.transactionEndPoint()}/transaction/stripe/post`;
        } else if (['braintree', 'braintree_paypal', 'braintree_applepay', 'braintree_venmo'].includes(FormValueState.paymentType)) {
            return `${hostname.transactionEndPoint()}/transaction/braintree/post`;
        } else if (['auth'].includes(FormValueState.paymentType)) {
            return `${hostname.transactionEndPoint()}/transaction/auth/post`;
        }
    }

    getPaymentType() {
        const FormValueState = store.getState().FormValue;
        const paymentType = store.getState().StoredClient.valid ? store.getState().StoredClient.data.paymentType : FormValueState.paymentType;

        if (['stripe', 'braintree', 'auth'].includes(paymentType)) {
            return 'cc';
        } else if (paymentType === 'stripe_applepay') {
            return 'apple';
        } else if (paymentType === 'braintree_paypal') {
            return 'paypal';
        } else if (paymentType === 'braintree_applepay') {
            return 'apple';
        } else if (paymentType === 'braintree_venmo') {
            return 'venmo';
        }
    }

    async submitData(url, data) {
        const response = await axios.post(url, qs.stringify(data), {
            headers: {
                'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
            }
        })
        return response;
    }

    async getPreparedForm() {
        const GlobalSettingsState = store.getState().GlobalSettings;
        const FormValueState = store.getState().FormValue;
        const FormRenderState = store.getState().FormRender;
        const geo = await this.geoLocation();
        console.log(`FB - Client: ${getParameterByName('cl')} Geo Location : ${JSON.stringify(geo)}`);

        const gaid = await getGAID();           

        let timezone = (typeof GlobalSettingsState.globalSettingsState.timezone !== "undefined") ? GlobalSettingsState.globalSettingsState.timezone : 'America/New_York';

        let ccName = (FormRenderState.paymentProviders.creditCard && FormRenderState.paymentProviders.creditCard.type !== 'none') ?
            FormRenderState.paymentProviders.creditCard.name : 'none';
        console.log(`FB - ccName Client: ${getParameterByName('cl')} ccName : ${ccName}`);
        console.log(`FB - FormRenderState Client: ${getParameterByName('cl')} FormRenderState : ${FormRenderState}`);
        // TODO: remove replacement of ccName before production
        // if (FormRenderState.paymentProviders.creditCard.type === 'braintree') {
        //     ccName = 'default';
        // }


        const formObject = this.mapFieldsToObject();
        console.log(`FB - response of mapFieldsToObject Client: ${getParameterByName('cl')} : ${JSON.stringify(formObject)}`);
        const values = {
            'customAmount': '',
            'company2': '',
            'company': '',
            'comments': '',
            'title': '',
            'firstName': '',
            'lastName': '',
            'email': '',
            'phone': '',
            'street1': '',
            'street2': '',
            'city': '',
            'state': '',
            'postal': '',
            'hear': '',
            'paymentType': this.getPaymentType(),
            'repeatDonor': false,
            'repeatUserSetting': false,
            'layout': 'formBuilder',
            'client': getParameterByName('cl'),
            'creative': spaceWithUnderscore(FormRenderState.formName) || getParameterByName('pg') || '8thjulyneighbours',
            'campaign': getParameterByName('campaign') || FormRenderState.options.campaign || 'NONE',
            'status': isStage() ? 'preview' : 'live',
            'googleRecaptcha': 'off',
            'group': 'NONE',
            'source': getParameterByName('source') || getParameterByName('appeal') || '',
            'reference': getParameterByName('ref') || 'none',
            'customCode01': getParameterByName('custom01') || 'none',
            'customCode02': getParameterByName('custom02') || 'none',
            'customCode03': getParameterByName('custom03') || 'none',
            'customCode04': getParameterByName('custom04') || 'none',
            'customCode05': getParameterByName('custom05') || 'none',
            'customCode06': getParameterByName('custom06') || 'none',
            'customCode07': getParameterByName('custom07') || 'none',
            'customCode08': getParameterByName('custom08') || 'none',
            'customCode09': getParameterByName('custom09') || 'none',
            'customCode10': getParameterByName('custom10') || 'none',
            'gaid': gaid || "Could not be captured",  // Static Text added because, AS DMC donation form GAID not found store.js the "Could not be captured" text
            'hostname': 'localhost:4041',
            'amount': (parseFloat(FormValueState.giftAmount) - ((typeof FormValueState.feeAdded !== 'undefined' && FormValueState.feeAdded !== null) ? parseFloat(FormValueState.feeAdded).toFixed(2) : 0)),
            'feeTransaction': parseFloat(FormValueState.giftAmount).toFixed(2),
            'amountType': 'array',
            'frequency': FormValueState.recurring_gift || 'single',
            'goal': '10000',
            'timeInitiated': moment().tz(timezone).format('MM/DD/YYYY hh:mm:ss a'),
            'largeGift': '500',
            'cc': ccName,
            'bank': (FormRenderState.paymentProviders.ach && FormRenderState.paymentProviders.ach.type !== 'none') ?
                FormRenderState.paymentProviders.ach.name : 'none',
            'paypal': (FormRenderState.paymentProviders.paypal && FormRenderState.paymentProviders.paypal.name && FormRenderState.paymentProviders.paypal.name !== 'none')
                ? FormRenderState.paymentProviders.paypal.name : 'none',
            'apple': (FormRenderState.paymentProviders.applePay && FormRenderState.paymentProviders.applePay.type !== 'none')
                ? FormRenderState.paymentProviders.applePay.name : 'none',
            'venmo': (FormRenderState.paymentProviders.venmo && FormRenderState.paymentProviders.venmo.name && FormRenderState.paymentProviders.venmo.name !== 'none')
                ? FormRenderState.paymentProviders.venmo.name : 'none',
            'ipAddress': (geo && geo.traits && geo.traits.ipAddress) ? geo.traits.ipAddress : '',
            'ipCity': (geo && geo.city && geo.city.names && geo.city.names.en) ? geo.city.names.en : '',
            'ipState': (geo && geo.subdivisions && geo.subdivisions[0] && geo.subdivisions[0].isoCode) ? geo.subdivisions[0].isoCode : '',
            'ipCountry': (geo && geo.country && geo.country.isoCode) ? geo.country.isoCode : '',
            'ipZip': (geo && geo.postal && geo.postal.code) ? geo.postal.code : '',
            'ipUser': (geo && geo.traits && geo.traits.userType) ? geo.traits.userType : '',
            'isp': (geo && geo.traits && geo.traits.organization) ? geo.traits.organization : '',
            'timezone': timezone,
            'isTribute': (FormValueState.tribute ? (FormValueState.tributeType || 'none') : 'none'),
            'tributeAlert': (FormValueState.tribute && FormValueState.tribute !== 'no_none' ? (FormValueState.tributeNotificationType || 'none') : 'none'),
            'autoresponder': getParameterByName('EMAIL') || FormRenderState.options.autoresponder || 'none',
            'customNotification': FormRenderState.options.customEmailNotification || 'none',
            'customNotificationList': FormRenderState.options.customEmailNotificationList || 'none',
            'honorTributeNotification': FormRenderState.options.honorTributeNotification || 'none',
            'memorialTributeNotification': FormRenderState.options.memorialTributeNotification || 'none',
            'converFeeSettingConflict': 'false',
            'peerCampaign': 'No-Campaign',
            'peerID': 'No-ID',
            'peerCID': 'No-CID',
            'peerRef': 'No-Ref',
            'peerSubCamp': 'No-Subcampaign',
            'utmSource': getParameterByName('utm_source') || '',
            'utmMedium': getParameterByName('utm_medium') || '',
            'utmCampaign': getParameterByName('utm_campaign') || '',
            'utmContent': getParameterByName('utm_content') || '',
            'utmTerm': getParameterByName('utm_term') || '',
            'token': '',
            'hontitle': '',
            'recipientcity': '',
            'honfirstName': '',
            'honlastName': '',
            'recipientMessage': '',
            'recipientphone': '',
            'recipientemail': '',
            'recipientFirstName': '',
            'recipientLastName': '',
            'recipientstate': '',
            'recipientstreet': '',
            'recipientpostal': '',
            // 'dmc_custom_field_OrgName': '',
            // 'dmc_custom_field_CompanyName': '',
            // 'dmc_custom_field_gift_notes': '',
            'g-recaptcha-response': '',
            'utmReferrer': getParameterByName('utm_referral') || '',
            'feeAdded': (typeof FormValueState.feeAdded !== 'undefined' && FormValueState.feeAdded !== null) ? parseFloat(FormValueState.feeAdded).toFixed(2) : 0
        }
        console.log(`FB - values Client: ${getParameterByName('cl')} values : ${JSON.stringify(values)}`);

        if (FormValueState.customFields) {
            FormValueState.customFields.map((field) => {
                return values[field] = FormValueState[field];
            });
        }

        if (store.getState().StoredClient.valid) {
            values.userToken = store.getState().StoredClient.data.customer;
            values.repeatUser = true;
            values.repeatDonor = true;
            values.repeatUserSetting = true;
        }

        if (this.getPaymentType() === 'cc' || FormValueState.paymentType === 'stripe_applepay') {
            values.hash = createHash(32);
        }

        if (FormValueState.referralSource) {
            values.source = FormValueState.referralSource;
        }

        if (typeof FormValueState.companyMatching !== "undefined" && FormValueState.companyMatching !== null) {
            values.dmc_custom_field_OrgMatch = (FormValueState.companyMatching) ? 'Yes' : 'No';
        }

        if (typeof FormValueState.companyMatchingName !== "undefined" && FormValueState.companyMatchingName !== null) {
            values.dmc_custom_field_OrgName = FormValueState.companyMatchingName;
        }

        if (typeof FormValueState.companyDonor !== "undefined" && FormValueState.companyDonor !== null) {
            values.dmc_custom_field_CompanyGift = (FormValueState.companyDonor) ? 'Yes' : 'No';
        }

        if (typeof FormValueState.companyDonorName !== "undefined" && FormValueState.companyDonorName !== null) {
            values.dmc_custom_field_CompanyName = FormValueState.companyDonorName;
        }

        if (typeof FormValueState.giftNotes !== "undefined" && FormValueState.giftNotes !== null) {
            values.dmc_custom_field_gift_notes = FormValueState.giftNotes;
        }

        if (typeof FormValueState.anonymous !== "undefined" && FormValueState.anonymous !== null) {
            values.dmc_custom_field_anonymous = FormValueState.anonymous ? 'Yes' : 'No';
        }

        if (typeof FormValueState.billingCountry !== "undefined" && FormValueState.billingCountry !== null) {
            values.dmc_custom_field_country = FormValueState.billingCountry ? FormValueState.billingCountry : '';
        }

        if (typeof FormValueState.tributeCountry !== "undefined" && FormValueState.tributeCountry !== null) {
            values.dmc_custom_field_tributeCountry = FormValueState.tributeCountry ? FormValueState.tributeCountry : '';
        }


        if (getParameterByName('package')) {
            values.dmc_custom_field_package = getParameterByName('package');
        }

        if (getParameterByName('mc_cid')) {
            values.dmc_custom_field_mc_cid = getParameterByName('mc_cid');
        } else {
            values.dmc_custom_field_mc_cid = "";
        }

        if (getParameterByName('appeal')) {
            values.dmc_custom_field_appeal = getParameterByName('appeal');
        }

        Object.keys(values).forEach((key) => {
            if (typeof formObject[key] !== 'undefined' && !['amount', 'paymentType'].includes(key)) {
                values[key] = formObject[key];
            }

            if (FormValueState.tribute && FormValueState.tributeNotificationType === "mail" && ['recipientFirstName'].includes(key)) {
                values['recipientfirstName'] = formObject[key];
            }
            if (FormValueState.tribute && FormValueState.tributeNotificationType === "mail" && ['recipientLastName'].includes(key)) {
                values['recipientlastName'] = formObject[key];
            }
        });

        if (FormValueState.stripe_card) {
            values.token = FormValueState.stripe_card
        }

        if (FormValueState.reCaptchaResponse) {
            values['g-recaptcha-response'] = FormValueState.reCaptchaResponse
        }

        if (FormValueState.stripe_ach_token) {
            values.token = FormValueState.stripe_ach_token;
            values.paymentType = 'banking';
            values.accountID = FormValueState.stripe_ach_account_id;
        }

        if (FormValueState.dataDescriptor) {
            values.dataDescriptor = FormValueState.dataDescriptor;
            values.dataValue = FormValueState.dataValue;
        }

        // DMCDIS-258: Add "utmReferrer" data in transaction file.
        values.utmReferrer = values.utmReferrer ? values.utmReferrer : getParameterByName('utm_referrer');
        values.utmReferrer = values.utmReferrer ? values.utmReferrer : (document.referrer ? document.referrer : '');

        console.log(`FB - Final values Client: ${getParameterByName('cl')} values : ${JSON.stringify(values)}`);

        return values;
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async submitTributeGift() {

        try {
            const { data: ReceiptState } = store.getState().Receipt;
            const FormValueState = store.getState().FormValue;

            const tributeGift = {
                transactionID: ReceiptState.transactionID,
                transactionDate: ReceiptState.transactionDate,
                mode: isStage() ? 'test' : 'live',  // For production use 'live'
                client: getParameterByName('cl'),
                tributeDetails: {
                    "isTribute": FormValueState.tributeType || 'none',
                    "tributeAlert": FormValueState.tributeNotificationType || 'false',
                    "honfirstName": FormValueState.tributeFirstName || "",
                    "honlastName": FormValueState.tributeLastName || "",
                    "recipientfirstName": FormValueState.tributeRecipientFirstName || "",
                    "recipientlastName": FormValueState.tributeRecipientLastName || "",
                    "recipientemail": FormValueState.tributeRecipientEmail || "",
                    "recipientphone": FormValueState.tributeRecipientPhoneNumber || "",
                    "recipientstreet": FormValueState.tributeStreetAddress1 || "",
                    "recipientcity": FormValueState.tributeCity || "",
                    "recipientstate": FormValueState.tributeState || "",
                    "recipientpostal": FormValueState.tributeZip || "",
                    "recipientMessage": FormValueState.tributeMessage || "",
                }
            };

            console.log(`FB - ThankYou Page - submitting tribute gift. - Client: ${getParameterByName('cl')} - Page: ${getParameterByName('page')} - UnixTime: ${moment().tz('America/New_York')}`);
            console.log("FB - ThankYou Page - tributeGift:", tributeGift)

            const { data: response } = await axios.post(`${hostname.dmc()}/tribute-gift/process-tribute-gift/`, qs.stringify(tributeGift));

            console.log(`FB - ThankYou Page - tribute gift API response: `, response);

            if (response.status === "success") {
                ReceiptState.tributeType = tributeGift.tributeDetails.isTribute;
                ReceiptState.tributeNotificationType = tributeGift.tributeDetails.tributeAlert;
                ReceiptState.tributeFirstName = tributeGift.tributeDetails.honfirstName;
                ReceiptState.tributeLastName = tributeGift.tributeDetails.honlastName;
                ReceiptState.tributeRecipientFirstName = tributeGift.tributeDetails.recipientfirstName;
                ReceiptState.tributeRecipientLastName = tributeGift.tributeDetails.recipientlastName;
                ReceiptState.tributeRecipientEmail = tributeGift.tributeDetails.recipientemail;
                ReceiptState.tributeRecipientPhoneNumber = tributeGift.tributeDetails.recipientphone;
                ReceiptState.tributeStreetAddress1 = tributeGift.tributeDetails.recipientstreet;
                ReceiptState.tributeCity = tributeGift.tributeDetails.recipientcity;
                ReceiptState.tributeState = tributeGift.tributeDetails.recipientstate;
                ReceiptState.tributeZip = tributeGift.tributeDetails.recipientpostal;
                ReceiptState.tributeMessage = tributeGift.tributeDetails.recipientMessage;
                ReceiptState.showTributeSuccessModal = true;
                store.dispatch(setReceiptData(ReceiptState));
                return true;
            } else {
                throw response.message;
            }

        } catch (error) {

            console.log("An error occurred during submitting tribute gift data. Error: ", error.message);
            throw error.message;

        }
    }

}

export default DMC;

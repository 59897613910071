import React, { Component } from 'react';
import ScriptTag from "react-script-tag";
import { renderString } from "../../lib";
import { Alert } from "react-bootstrap";
import {connect} from "react-redux"
import { initComponentSocket } from '../../Redux/actions/ComponentActions';
import { initVariableSocket } from "../../Redux/actions/VariableActions";

class ComponentViewer extends Component {

    async componentDidMount() {
        const { componentId, initComponentSocket, initVariableSocket } = this.props;

        console.log('component id', componentId);

        await initVariableSocket(componentId);
        await initComponentSocket(componentId);
    }

    render() {
        console.log('render');
        const { componentId, componentState } = this.props;

        if (componentState.state[componentId] && (typeof componentState.state[componentId] === 'object' && Object.keys(componentState.state[componentId]).length) && componentState.isDeleted !== 1) {
            return (
                <>
                    <div dangerouslySetInnerHTML={{ __html: renderString(componentState.state[componentId].html || '', componentState.state[componentId].variables) }}></div>
                    <style dangerouslySetInnerHTML={{ __html: renderString(componentState.state[componentId].css || '', componentState.state[componentId].variables) }}></style>
                    <ScriptTag>{renderString(componentState.state[componentId].javascript || '', componentState.state[componentId].variables)}</ScriptTag>
                </>
            );
        } else {
            if (componentState.isLoaded) {
                return (
                    <Alert variant='danger'>This component has no content</Alert>
                );
            } else {
                return null;
            }

        }

    }

}

const mapStateToProps = state => ({
    componentState: state.Component
});

const mapActionsToProps = {
    initComponentSocket,
    initVariableSocket
}

export default connect(mapStateToProps, mapActionsToProps)(ComponentViewer);
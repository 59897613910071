import React, { Component } from 'react';
import FormField from "./FormField";
import { setFormValue } from "../Redux/actions/FormValueActions";
import {connect} from 'react-redux';
import { storedClientClear } from "../Redux/actions/StoredClientActions";

class FormStep extends Component{

    componentDidMount() {
        const stepData = this.props.FormRenderState.pages[this.props.index].components;
        // array-callback-return
        const customFields = stepData.filter( field => {
            if(field.custom)
            {
                return field;
            }else{
                return null;
            }
        }).map(function(fieldObject, index){
            return `dmc_custom_field_${fieldObject.name}`;
        });

        this.props.setFormValue('customFields', customFields);
    }

    render(){
        const stepData = this.props.FormRenderState.pages[this.props.index].components;

        return (<div className='row donation-form-field' data-parent-index={this.props.index}>
            {stepData.map((step, index) => (
                <FormField key={index} field={step} parentIndex={this.props.index}/>
            ))}
        </div>);
    }

}

const mapStateToProps = (state) => ({
    FormRenderState: state.FormRender
});

const mapActionsToProps = {
    setFormValue
};  

export default connect(mapStateToProps, mapActionsToProps)(FormStep);
import React, { Component } from 'react';
import { getParameterByName, isFullWidth, getIdByPageName } from "../../lib";
import ComponentViewer from "./ComponentViewer";
import DonationForm from "./DonationForm";
import ScriptTag from "react-script-tag";
import { Alert } from "react-bootstrap";
import { connect } from 'react-redux';
import { initPageSocket } from "../../Redux/actions/PageActions";
import { initVariableSocket } from "../../Redux/actions/VariableActions";


class Page extends Component {


    constructor(props){
        super(props)
        this.state = {
            pageId: "",
        }
    }
  


    async componentDidMount() {
        const { initPageSocket, initVariableSocket } = this.props;
        const pageName = await getIdByPageName();
        await initVariableSocket();
        this.setState({pageId:pageName}) 
        await initPageSocket(this.state.pageId);
    }

    render() {
        const { PageState, FormRenderState } = this.props;     
        const PageId = this.state.pageId;

        const state = PageState.state[PageId];
        if (!state) {
            return null;
        }
        const { config } = state;
        if (PageState.state[PageId] && (typeof PageState.state[PageId] === 'object' && Object.keys(PageState.state[PageId]).length) && PageState.isDeleted !== 1) {
            console.log('component ids', config.headerComponent, config.sidebarComponent, config.footerComponent);

            const headerFullWidth = isFullWidth('headerFullWidth') ? 'container-fluid donation-form-header' : 'container donation-form-header';
            const footerFullWidth = isFullWidth('footerFullWidth') ? 'container-fluid donation-form-footer' : 'container donation-form-foter';
            const formSidebarFullWidth = isFullWidth('formSidebarFullWidth') ? 'container-fluid donation-form-body' : 'container donation-form-body';
            
            const afterHeaderFullWidth = isFullWidth('afterHeaderFullWidth') ? `container-fluid after-donation-form-header ${getParameterByName('receipt') ? 'thankyou-page' : ''}` : `container after-donation-form-header ${getParameterByName('receipt') ? 'thankyou-page' : ''}`;
            const beforeFooterFullWidth = isFullWidth('beforeFooterFullWidth') ? `container-fluid before-donation-form-footer ${getParameterByName('receipt') ? 'thankyou-page' : ''}` : `container before-donation-form-foter ${getParameterByName('receipt') ? 'thankyou-page' : ''}`;

            const showSidebarLeft = (config.sidebarLocation === 'left' && config.sidebarComponent);
            const showSidebarRight = config.sidebarLocation === 'right' && config.sidebarComponent;
            const showSidebarDefault = config.sidebarLocation !== 'right' && config.sidebarLocation !== 'left' && config.sidebarComponent;

            return (
                <>
                    <div className={headerFullWidth}>
                        <div className='row' id='header'>
                            <div className='col-md-12 header-main-div'>{config.headerComponent ? <ComponentViewer componentId={config.headerComponent} /> : null}</div>
                        </div>
                    </div>

                    <div className={afterHeaderFullWidth}>
                        <div className='row' id='after-header'>
                            <div className='col-md-12 after-header-main-div'>{config.afterHeaderComponent ? <ComponentViewer componentId={config.afterHeaderComponent} /> : null}</div>
                        </div>
                    </div>

                    <div className={formSidebarFullWidth}>
                        {!config.sidebarComponent && <div className={'col-md-12 donation_form ' + (getParameterByName('receipt') ? 'donation_receipt' : '')} id='donation_form'>
                            <DonationForm formId={config.form} />
                        </div>}
                        {showSidebarLeft ? <div className={`row d_page current_step_${typeof FormRenderState.activeStep !== undefined ? FormRenderState.activeStep : 'form'}`}>
                            <div className='col-md-4 d_sidebar' id='sidebar'>
                                <ComponentViewer componentId={config.sidebarComponent} />
                            </div>
                            <div className={'col-md-8 donation_form d_form ' + (getParameterByName('receipt') ? 'donation_receipt' : '')} id='donation_form'>
                                <DonationForm formId={config.form} />
                            </div>
                        </div> : null}
                        {showSidebarRight ? <div className={`row d_page current_step_${typeof FormRenderState.activeStep !== undefined ? FormRenderState.activeStep : 'form'}`}>
                            <div className={'col-md-8 donation_form d_form ' + (getParameterByName('receipt') ? 'donation_receipt' : '')} id='donation_form'>
                                <DonationForm formId={config.form} />
                            </div>
                            <div className={'col-md-4 donation_form d_page d_sidebar' + (getParameterByName('receipt') ? 'donation_receipt' : '')} id='sidebar'>
                                <ComponentViewer componentId={config.sidebarComponent} />
                            </div>
                        </div> : null}
                        {showSidebarDefault ? <div className='row'>
                            <div className={'col-md-12 donation_form ' + (getParameterByName('receipt') ? 'donation_receipt' : '')} id='donation_form'>
                                <DonationForm formId={config.form} />
                            </div>
                        </div> : null}
                    </div>

                    <div className={beforeFooterFullWidth}>
                        <div className='row' id='before-footer'>
                            <div className='col-md-12'>{config.beforeFooterComponent ? <ComponentViewer componentId={config.beforeFooterComponent} /> : null}</div>
                        </div>
                    </div>

                    <div className={footerFullWidth}>
                        <div className='row' id='footer'>
                            <div className='col-md-12'>{config.footerComponent ? <ComponentViewer componentId={config.footerComponent} /> : null}</div>
                        </div>
                    </div>

                    <style dangerouslySetInnerHTML={{ __html: state.css || '' }} />
                    <ScriptTag>{state.javascript || ''}</ScriptTag>
                </>
            );
        } else {
            if (PageState.isLoaded) {
                return (
                    <Alert variant='danger'>This page has no content</Alert>
                );
            } else {
                return null;
            }

        }
    }

}

const mapStateToProps = state => ({
    PageState: state.Page,
    FormRenderState: state.FormRender
})

const mapActionsToProps = {
    initPageSocket,
    initVariableSocket
}

export default connect(mapStateToProps, mapActionsToProps)(Page);

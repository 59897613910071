import React from 'react';
import { BootstrappedGiftArrayButton as GiftArrayButton } from '../BaseFields/GiftArrayButton';
import BaseField, { BaseFieldActionsToProps, BaseFieldStateToProps } from '../BaseFields/BaseField'
import { getParameterByName } from "../../lib";
import { connect } from "react-redux";
import { getDonorSummary, setInvalidAmount } from "../../Redux/actions/FormRenderActions";
import qs from 'qs';
import axios from 'axios';
import { formatQuery, parseCEL } from 'react-querybuilder';
import _ from 'lodash';
import { getGAID } from '../../GlobalFunction/GlobalFunction';

class GiftArray extends BaseField {

    constructor(props) {
        super(props);
        this.state = {
            invalidAmount: false,
            isLoadedButtons: false,
            appliedRuleAction: ""
        };
    }

    getRuleFieldValue = (summaryData, data, key) => {
        if (data.hasOwnProperty(key)) {
            for (let i = 0; i < data[key].length; i++) {
                if (data[key][i].hasOwnProperty(key)) {
                    this.getRuleFieldValue(summaryData, data[key][i], key);
                } else {
                    let field = data[key][i].field;
                    switch (field) {
                        case 'returningDonor': 
                                data[key][i].field = summaryData.isSustainer;
                                data[key][i].value = data[key][i].value.toLowerCase() === 'true' ? true : false
                            break;
                        case 'giftCount':
                                data[key][i].field = parseInt(summaryData.giftCount);
                                data[key][i].value = parseInt(data[key][i].value)
                            break;
                        case 'operatingSystem':
                                data[key][i].field = `"${window.navigator.userAgentData.platform.toLowerCase()}"`;
                                if(!data[key][i].field) {
                                    data[key][i].field = 'macos'
                                }
                                data[key][i].value = data[key][i].value.toLowerCase(); //MacIntel
                            break;
                        case 'avgGiftAmt':
                                data[key][i].field = parseInt(summaryData.avgGiftAmt);
                                data[key][i].value = parseInt(data[key][i].value)
                            break;
                        case 'maxGiftAmt':
                                data[key][i].field = parseInt(summaryData.maxGiftAmt);
                                data[key][i].value = parseInt(data[key][i].value)
                            break;
                        case 'pageViews':
                                data[key][i].field = parseInt(summaryData.pageViews);
                                data[key][i].value = parseInt(data[key][i].value)
                            break;
                        case 'twzi':
                                data[key][i].field = summaryData.twzi;
                                data[key][i].value = data[key][i].value.toLowerCase() === 'true' ? true : false
                            break;
                    }
                }
            }
        }
    }

    async componentDidMount() {
        console.log('Step 1: Check DIS is enabled globally: ', this.props.GlobalSettings.disSettings);
        if(this.props.GlobalSettings.disSettings === '1') {
            console.log('Step 1: Client level DIS setting is ON');

            console.log('Step 2: Check DIS is enabled on form: ', this.props.FormRenderState.DISSetting);
            if(this.props.FormRenderState.DISSetting === true) {
                console.log('Step 2: Form level DIS setting is ON');
                console.log('Step 3: Fetch getGAID(): ');
                let gaid = await getGAID();
                console.log('Step 3: gaid fetch getGAID(): ', gaid);
                let summaryData = await getDonorSummary(gaid);
                console.log('Step 3: Fetched donor summary from lookup', summaryData);
                
                console.log('Step 3: Applied Rule on form: ', this.props.FormRenderState.DISRules.length)
                if(summaryData && this.props.FormRenderState.DISRules.length) {
                    console.log('Step 4: Got the successful data from summary api and has atleast on rule added on form');
                    
                    const protocol = process.env.NODE_ENV === 'development' ? 'http://' : 'https://';
                    const data = {
                        rule_ids: this.props.FormRenderState.DISRules
                    };
                    const requestData = await axios.post(`${protocol}${process.env.REACT_APP_API_HOST}/rules/getByIds?cl=${getParameterByName('cl')}`, qs.stringify(data));                    
                    requestData.data.sort((a, b) => this.props.FormRenderState.DISRules.indexOf(a.id) - this.props.FormRenderState.DISRules.indexOf(b.id));                    
                    
                    let appliedRule = '';
                    
                    await requestData.data.find((item) => {
                        let rule_state = JSON.parse(item.rule_state);
                        let jsonTree = parseCEL(rule_state.expression);
                        
                        this.getRuleFieldValue(summaryData, jsonTree, 'rules');

                        if(eval(formatQuery(jsonTree, 'cel')) && !appliedRule) {
                            console.log('Step 5: Matched rule: ', item.name, '=> ', rule_state.expression);
                            console.log('appliedRule condition: ', item);
                            appliedRule = item;
                        }
                    });
    
                    
                    if(appliedRule) {
                        console.log('appliedRule action: ', JSON.parse(appliedRule.rule_state).action);
                        this.setState( { appliedRuleAction:  JSON.parse(appliedRule.rule_state).action} );                    
                    } else {
                        console.log('Step 6: No rule matched');
                    }
                } else {
                    console.log('Step 7: Either no data found from api or no rule has been applied to form');
                }                
            }
        }

        this.setState( { selection: {}, isLoadedButtons: true });
    }

    componentWillReceiveProps(nextProps) {
        const { invalidAmount } = this.state;
        if(invalidAmount !== nextProps.FormRenderState.invalidAmount)
        {
            this.setState({invalidAmount: nextProps.FormRenderState.invalidAmount});
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const superUpdate = super.shouldComponentUpdate(nextProps, nextState, nextContext);
        if (superUpdate) {
            return true;
        }
        if (nextProps.FormValueState.recurring_gift !== this.props.FormValueState.recurring_gift) {
            return true;
        }
        return false;
    }

    getOptions() {
        const options = {};

        this.props.options.forEach((option) => {
            options[option.field] = option.values || option.value || option.default;
        });

        return options;
    }

    getSingleGiftData() {
        const options = this.getOptions();
        const { setFormValue } = this.props;

        let selection = [];
        let activeBtn = false;

        if (getParameterByName('amt')) {
            selection = getParameterByName('amt').split(',').map((value, index) => {
                let label = '$' + value;
                let copyText = '';
                if (this.props.FormValueState.donationArrayBtn === value.toString()) {
                    activeBtn = true;
                }
                if (options.options[index]) {
                    label = options.options[index].amountText;
                    label = label.replace(/\{gift.amount}/g, value);
                    copyText = options.options[index].copyText || "";
                }
                return {
                    value: value.toString(),
                    label: label,
                    prefix_text: '',
                    suffix_text: '',
                    copyText: copyText,
                    active: (this.props.FormValueState.donationArrayBtn === value.toString())
                }
            });
        } else {
            let newGiftArray = [];
            options.options.map((value, index) => {
                let donationAmt = (typeof value.amount !== 'undefined' && value.amount !== null) ? value.amount : 0
                newGiftArray.push(this.checkForRule(parseFloat(donationAmt), index));

            });
            newGiftArray = this.getUniqueAmount(newGiftArray);
            
            selection = options.options.map((value, index) => {
                let donationAmt = newGiftArray[index];
                
                if (this.props.FormValueState.donationArrayBtn === donationAmt.toString()) {
                    activeBtn = true;
                }

                return {
                    value: donationAmt.toString(),
                    label: value.amountText.indexOf('{gift.amount}') > -1 ? value.amountText.replace(/\{gift.amount}/g, donationAmt) : donationAmt,
                    prefix_text: value.prefix_text,
                    suffix_text: value.suffix_text,
                    copyText: value.copyText || "",
                    active: (this.props.FormValueState.donationArrayBtn === donationAmt.toString())
                }
            });
        }
        selection.otherCopyText = typeof options.singleOtherCopyText !== 'undefined' ? options.singleOtherCopyText : '';
        if (!activeBtn) {
            setFormValue('giftArrayOther', this.props.FormValueState.donationArrayBtn);
            setFormValue('giftArrayActiveIndex', 99);
        }
        else {
            setFormValue('giftArrayOther', '');
            setFormValue('giftArrayActiveIndex', '');
        }
        return selection;
    }

    getRecurringGiftData() {
        const options = this.getOptions();
        const { setFormValue } = this.props;

        let selection = [];
        var activeBtn = false;

        if (getParameterByName('amtmonth')) {
            selection = getParameterByName('amtmonth').split(',').map((value, index) => {
                let label = '$' + value;
                let copyText = '';
                if (this.props.FormValueState.donationArrayBtn === value.toString()) {
                    activeBtn = true;
                }

                if (options.recurringOptions[index]) {
                    label = options.recurringOptions[index].amountText;
                    label = label.replace(/\{gift.amount}/g, value);
                    copyText = options.recurringOptions[index].copyText || "";
                }
                return {
                    value: value.toString(),
                    label: label,
                    prefix_text: '',
                    suffix_text: '',
                    copyText: copyText,
                    active: (this.props.FormValueState.donationArrayBtn === value.toString())
                }
            })
        } else {
            let newGiftArray = [];
            options.recurringOptions.map((value, index) => {
                let donationAmt = (typeof value.amount !== 'undefined' && value.amount !== null) ? value.amount : 0
                newGiftArray.push(this.checkForRule(parseFloat(donationAmt), index));

            });
            newGiftArray = this.getUniqueAmount(newGiftArray);

            selection = options.recurringOptions.map((value, index) => {
                let donationAmt = newGiftArray[index];

                if (this.props.FormValueState.donationArrayBtn === donationAmt.toString()) {
                    activeBtn = true;
                }

                return {
                    value: donationAmt.toString(),
                    label: value.amountText.indexOf('{gift.amount}') > -1 ? value.amountText.replace(/\{gift.amount}/g, donationAmt) : donationAmt,
                    prefix_text: value.prefix_text,
                    suffix_text: value.suffix_text,
                    copyText: value.copyText || "",
                    active: (this.props.FormValueState.donationArrayBtn === donationAmt.toString())
                }
            });
        }
        selection.otherCopyText = typeof options.recurringOtherCopyText !== 'undefined' ? options.recurringOtherCopyText : '';
        if (!activeBtn) {
            setFormValue('giftArrayOther', this.props.FormValueState.donationArrayBtn);
            setFormValue('giftArrayActiveIndex', 99);
        }
        else {
            setFormValue('giftArrayOther', '');
            setFormValue('giftArrayActiveIndex', '');
        }
        return selection;
    }

    checkForRule(donationAmt, index) {
        const { FormValueState } = this.props;
        if(this.state.appliedRuleAction) {
            let action = this.state.appliedRuleAction;
            
            if(Object.keys(action).length) {
                if(action.valueType === 'percent') {
                    let calculatedAmount = donationAmt * (parseFloat(action.donationValue.donationAmountPercent || 0) / 100);
                    console.log('calculatedAmount: ', calculatedAmount);                
                    if(action.vary === 'increase') {
                        donationAmt = donationAmt + calculatedAmount;
                    } else {
                        donationAmt = donationAmt - calculatedAmount;
                    }
                    console.log('donationAmt: ', donationAmt);
                    donationAmt = Math.ceil(donationAmt / parseInt(action.multiplier)) * parseInt(action.multiplier);
                } else {
                    let donationArr = (typeof FormValueState.recurring_gift !== 'undefined' && FormValueState.recurring_gift === 'recurring') ? action.donationValue.recurringDonationAmount.split(",") : action.donationValue.singleDonationAmount.split(",");
                    donationAmt = parseFloat(donationArr[index]) || donationAmt;
                }
            }
        }

        return donationAmt;
    }

    getUniqueAmount (arr) {
        let set = new Set();
        let duplicates = new Set();
      
        arr.forEach((item) => {
            if (set.has(item)) {
                duplicates.add(item);
            }
            set.add(item);
        });
      
        if (duplicates.size > 0) {
            duplicates.forEach((item) => {
            let index = arr.indexOf(item);
            arr[index] += parseInt(this.state.appliedRuleAction.multiplier) || 0;
            });
            this.getUniqueAmount(arr);
        }
        return _.sortBy(arr);
    }

    render() {
        const { FormValueState } = this.props;
        const options = this.getOptions();

        // set default render single gift data if recurring_gift is undefined
        const selection = (typeof FormValueState.recurring_gift !== 'undefined' && FormValueState.recurring_gift === 'recurring') ? this.getRecurringGiftData() : this.getSingleGiftData();
        return (
            <div className={this.getTemplateClasses(['col-12']) + (this.state.invalidAmount ? ' error-invalid-amount' : '')} style={this.getGeneratedStyles()}>
                <div className="container">
                    {!this.state.isLoadedButtons ?  
                            (<div className="d-flex justify-content-left mt-4">
                                <div className="spinner-border" role="status" style={{ width: "3rem", height: "3rem" }}>
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>)
                        : (<GiftArrayButton {...this.props} name={options.name} optionsArray={this.getOptionsArray()} optionItems={selection} />)
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    ...BaseFieldStateToProps(state),
    FormValueState: state.FormValue,
    FormRenderState: state.FormRender,
    GlobalSettings: state.GlobalSettings
})

const mapActionsToProps = {
    ...BaseFieldActionsToProps,
    setInvalidAmount
}

export default connect(mapStateToProps, mapActionsToProps)(GiftArray);

import BaseSocket from "./BaseSocket";
import { getParameterByName } from "../lib";

const PageSocket = {
    sockets: [],
    init: (pageId) => {
        if (!BaseSocket.socket) {
            BaseSocket.init();
        }
        if (PageSocket.sockets[pageId]) {
            return PageSocket.sockets[pageId];
        } else {
            PageSocket.sockets[pageId] = BaseSocket.socket.subscribe('page:' + pageId + "|" + getParameterByName('cl'));
        }
        return PageSocket.sockets[pageId];
    }
};

export default PageSocket;
import React from "react";
import BaseField, {BaseFieldActionsToProps, BaseFieldStateToProps} from "../BaseFields/BaseField";
import {connect} from "react-redux";
import { renderString } from "../../lib";

class CustomHTML extends BaseField{

    state = {
        options:""
    }
    componentWillReceiveProps(nextProps) {
        if(this.props.options !== nextProps.options){
            this.setState({options:nextProps.options})
        }
    }

    render(){
         this.state.options = this.getOptionsArray();
        return (
            <div className={this.getTemplateClasses()} style={this.getGeneratedStyles()} dangerouslySetInnerHTML={{__html: renderString(this.state.options.html, this.props.FormRenderState.variables)}} />
        );
    }

}

const mapStateToProps = (state) => ({
    ...BaseFieldStateToProps(state),
    FormRenderState: state.FormRender,
})

const mapActionsToProps = {
    ...BaseFieldActionsToProps
}

export default connect(mapStateToProps, mapActionsToProps)(CustomHTML);
import React from "react";
import { renderString } from "../../lib";

import {
    FormGroup,
    FormLabel,
    FormControl
} from "react-bootstrap";
import BaseField, {BaseFieldActionsToProps, BaseFieldStateToProps} from "./BaseField";
import {connect} from "react-redux";

class TextArea extends BaseField {

    cursor;

    componentDidMount() {
        // This code will set the custom field value by default blank,
        // So it will pass for transaction without interact with custom field
        const { FormValueState } = this.props;
        const options = this.getOptionsArray();
        let fieldName = options.name;
        if (options.custom) {
            fieldName = `dmc_custom_field_${options.name}`;
        }
        this.triggerOnFieldLoadInForm(typeof FormValueState[fieldName] !== 'undefined' ? FormValueState[fieldName] : "");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.target) {
            this.state.target.selectionStart = this.cursor;
            this.state.target.selectionEnd = this.cursor;
        }
    }

    render() {
        const {FormValueState} = this.props;

        const errorMessage = this.getRenderedValidationMessage();
        let options = this.getOptionsArray();
        let fieldName = options.name;
        if (options.custom) {
            fieldName = `dmc_custom_field_${options.name}`;
        }
        const props = {
            type: this.props.type || 'text',
            onBlur: (event) => this.onChange(event),
            defaultValue: this.props.value || renderString(FormValueState[fieldName], this.props.FormRenderState.variables),
            name: renderString(fieldName, this.props.FormRenderState.variables),
            placeholder: renderString(options.placeholder, this.props.FormRenderState.variables),
            isInvalid: errorMessage !== '',
            as: 'textarea',
            rows: 2
        };

        const randomNumber = Math.abs(Math.random() * Number.MAX_SAFE_INTEGER).toFixed(0);

        return (
            <div className={this.getTemplateClasses()} style={this.getGeneratedStyles()}>
                <FormGroup>
                    <FormLabel htmlFor={`textarea-${randomNumber}`} className={typeof options.validators !== 'undefined' ? options.validators.required ? 'required required-field' : '' : ''}>{renderString(options.label, this.props.FormRenderState.variables)}</FormLabel>
                    <FormControl
                        id={`textarea-${randomNumber}`}
                        {...props}
                        onChange={(event) => {
                            this.cursor = event.target.selectionStart;
                            this.setState({
                                target: event.target
                            });
                            if (props.onChange) {
                                props.onChange(event);
                            }
                        }}
                    />
                    <div style={{ color: '#F00' }} className="donation-form-field-error">{errorMessage}</div>
                </FormGroup>
            </div>
        );
    }

}

export const TextAreaStateToProps = (state) => ({
    ...BaseFieldStateToProps(state),
    FormRenderState: state.FormRender,
    FormValueState: state.FormValue
})

export const TextAreaActionsToProps = {
    ...BaseFieldActionsToProps
}

export const BootstrappedTextArea = connect(TextAreaStateToProps, TextAreaActionsToProps)(TextArea);
export default TextArea;

import {FORM_VALIDATION_SET_CAN_SUBMIT, FORM_VALIDATION_SET_ITEM, FORM_VALIDATION_SET_VALIDATOR, FORM_VALIDATION_SET_INVALIDELEMENTS} from "../actionTypes";

const initialState = {
    items: {},
    validators: {},
    canSubmit: true,
    submissionError: '',
    invalidElements: [],
};

export default function(state = initialState, action){
    switch (action.type){
        case FORM_VALIDATION_SET_ITEM:
            const newItems = {...state.items};

            newItems[action.payload.key] = action.payload.value;

            return {
                ...state,
                items: newItems
            }
        case FORM_VALIDATION_SET_CAN_SUBMIT:
            return {
                ...state,
                canSubmit: action.payload
            }
        case FORM_VALIDATION_SET_VALIDATOR:
            const validators = {...state.validators};
            validators[action.payload.key] = action.payload.value;
            return {
                ...state,
                validators
            }
        case FORM_VALIDATION_SET_INVALIDELEMENTS:
            return {
                ...state,
                invalidElements: action.payload
            }
        default:
            return state;
    }
}

import Textbox, {TextboxActionsToProps, TextboxStateToProps} from "../BaseFields/Textbox";
import {connect} from "react-redux";

class TributeRecipientLastName extends Textbox{

}

const mapStateToProps = (state) => ({
    ...TextboxStateToProps(state)
})

const mapActionsToProps = {
    ...TextboxActionsToProps
}

export default connect(mapStateToProps, mapActionsToProps)(TributeRecipientLastName);
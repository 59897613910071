import React, { Component } from 'react';
import { client, paypalCheckout } from 'braintree-web'
import {connect} from 'react-redux';
import {setCanSubmit} from "../../Redux/actions/FormValidationActions";
import {setFormValue} from "../../Redux/actions/FormValueActions";

class BraintreePaypalForm extends Component {

    state = {
        braintreeClient: null,
        paypalToken: null,
        showError: false
    };

    componentDidMount() {
        const abstraction = window.rkd.form.getAbstractionLayer();
        abstraction.registerSubmitTrigger('braintree_paypal', () => this.submit_braintreePaypal())
        this.setState({
            braintreeClient: client.create({
                authorization: this.props.token
            }, (clientErr, clientInstance) => {
                if (clientErr) {
                    console.error('Error creating client', clientErr);
                    return;
                }
                paypalCheckout.create({
                    client: clientInstance
                }, (paypalCheckoutErr, paypalCheckoutInstance) => {
                    if (paypalCheckoutErr) {
                        console.error('Error creating PayPal Checkout', paypalCheckoutErr);
                        return;
                    }

                    paypalCheckoutInstance.loadPayPalSDK({
                        vault: true
                    }, () => {
                        paypal.Buttons({ // eslint-disable-line no-undef
                            createBillingAgreement: () => {
                                return paypalCheckoutInstance.createPayment({
                                    flow: 'vault',
                                    enableShippingAddress: false
                                });
                            },
                            onApprove: (data, actions) => {
                                return paypalCheckoutInstance.tokenizePayment(data, (err, payload) => {
                                    this.setState({
                                        paypalToken: payload
                                    });

                                    // reason: it take time to load token into the state before submit the payment
                                    setTimeout(() => { window.rkd.form.submitForm(); }, 100);
                                });
                            },
                            onCancel: (data) => {
                                console.log('Paypal payment cancelled', JSON.stringify(data));
                            },
                            onError: (err) => {
                                console.error('Paypal Error', err)
                            }
                        }).render('#paypal-checkout');
                    });
                });
            })
        });
    }

    componentWillUnmount() {
        const abstraction = window.rkd.form.getAbstractionLayer();
        abstraction.deregisterSubmitTrigger('braintree_paypal');
    }

    submit_braintreePaypal() {
        this.setState({ showError: false })
        if (this.state.paypalToken) {
            this.props.setFormValue('paypal_token', this.state.paypalToken.nonce);
        } else {
            this.setState({ showError: true });
        }
    }

    render() {
        const { paypalToken, showError } = this.state;

        return (<>
            {showError && <div className='col-12'>
                <div className='alert alert-danger'>You must complete the PayPal payment flow.</div>
            </div>}
            {!paypalToken && <><br /><div className="braintree-paypal-amount-text" style={{ textAlign: 'center' }}>Your donation will be in the amount of: ${this.props.FormValueState.giftAmount}</div><div id="paypal-checkout" style={{ textAlign: 'center' }}></div></>}
            {paypalToken && <div className='row'>
                <div className='col-12'>
                    {/* <div className='alert alert-success'>We will use the PayPal account '{paypalToken.details.email}' to submit your payment. You still need to complete the form to process your donation.</div> */}
                    <div className='alert alert-success'>We will use the PayPal account '{paypalToken.details.email}' to submit your payment.</div>
                </div>
            </div>}
        </>);
    }

}

const mapStateToProps = state => ({
    FormValueState: state.FormValue
})

const mapActionsToProps = {
    setCanSubmit,
    setFormValue
}

export default connect(mapStateToProps, mapActionsToProps)(BraintreePaypalForm);
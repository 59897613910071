import React from "react";
import { renderString, getParameterByName, isTruthy } from "../../lib";

import {
    FormGroup,
    FormLabel,
    FormCheck
} from "react-bootstrap";
import BaseField, { BaseFieldActionsToProps, BaseFieldStateToProps } from "./BaseField";
import { connect } from "react-redux";

class Checkbox extends BaseField {

    state = {
        checked: false,
        tributeCheckedError: false,
        coverFee: 0
    }

    componentDidMount() {
        const options = this.getOptionsArray();
        const { FormValueState } = this.props;
        let checked = false;
        if (this.props.id === "coverFeeOption" && getParameterByName('coverfee')) {
            const coverChecked = isTruthy(getParameterByName('coverfee'));
            this.setState({
                checked: coverChecked
            }, () => {
                this.props.setFormValue(options.name, coverChecked);
            });
        } else {
            if (typeof FormValueState[options.name] === 'undefined') {
                if (options.default === 'Yes') {
                    checked = true;
                }
            }
            else if (FormValueState[options.name]) {
                checked = true;
            }
            else {
                checked = true;
            }
            this.setState({
                checked
            });
        }

        this.setFieldAndTriggerEvent(options.name, checked);

        // this trigger for uncheck tributeCheckbox if form field not validated
        window.rkd.form.on('internal_change_tributeCheckbox', (event) => {
            this.setState({
                tributeCheckedError: true
            });
            this.setFieldAndTriggerEvent(event.name, false);
        });
    }

    componentWillReceiveProps(nextProps) {

        if (this.props.FormValueState.calculatedCoverFee !== nextProps.FormValueState.calculatedCoverFee) {
            this.setState({ coverFee: typeof nextProps.FormValueState.calculatedCoverFee !== 'undefined' ? nextProps.FormValueState.calculatedCoverFee : 0 });
        }
    }


    onCheck(event) {
        const options = this.getOptionsArray();
        let checked = !this.state.checked;

        // check tributeCheckbox is check or not onCheck Event (also check with the form field not validated) 
        if (options.name === 'tribute' && this.state.tributeCheckedError) {
            this.setState({ tributeCheckedError: false });
            checked = true;
        }

        this.setState({
            checked
        });

        this.setFieldAndTriggerEvent(options.name, checked);
    }

    render() {
        const { FormRenderState } = this.props;
        const errorMessage = this.getRenderedValidationMessage();
        let options = this.getOptionsArray();
        if (this.props.formattedOptions) {
            options = this.props.formattedOptions;
        } else {
            this.props.options.forEach((option) => {
                options[option.field] = option.value || option.default;
            });
        }

        const fieldRand = Math.ceil(Math.random() * 100000);

        return (
            <div className={`${this.getTemplateClasses()} donation-form-checkbox ${typeof options.isHidden !== "undefined" && options.isHidden === "Yes" ? "d-none" : ''}`} style={this.getGeneratedStyles()}>
                <FormGroup>
                    <FormCheck
                        type='checkbox'
                        id={`checkbox_${fieldRand}`}
                        inline
                        className={this.state.checked ? "checkbox-checked" : ""}
                        onChange={(event) => this.onCheck(event)}
                        // checked={this.state.checked}
                        checked={(this.props.id === 'tributeCheckbox' && this.state.tributeCheckedError) ? false
                            : this.state.checked} // this condition only for TributeCheckBox uncheck when form field not validated -- also for the coverFeeOption
                        name={renderString(options.name, FormRenderState.variables)}
                        placeholder={renderString(options.placeholder, FormRenderState.variables)}
                        isInvalid={errorMessage !== ''}
                        style={{ marginRight: 0 }}
                    />
                    <FormLabel htmlFor={`checkbox_${fieldRand}`} className={typeof options.validators !== 'undefined' ? options.validators.required ? 'required required-field' : '' : ''}>{renderString(options.label, FormRenderState.variables)}</FormLabel>
                    <div style={{ color: '#F00' }} className="donation-form-field-error">{errorMessage}</div>
                </FormGroup>
            </div>
        );
    }
}

export const CheckboxStateToProps = (state) => ({
    ...BaseFieldStateToProps(state),
    FormRenderState: state.FormRender
})

export const CheckboxActionsToProps = {
    ...BaseFieldActionsToProps
}

export const BootstrappedCheckbox = connect(CheckboxStateToProps, CheckboxActionsToProps)(Checkbox);
export default Checkbox;

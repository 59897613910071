import React, { useState, useEffect, Fragment } from "react";

// Import Packages.
import { useSelector } from 'react-redux';
import _ from 'lodash'

// Import Lib.
import { getComponentIndex, getComponentOptions } from "../lib";

// Import Components.
import GenericField from "./GenericField";
import PaymentInfoFields from "./PaymentInfoFields";
import GiftArrayBtnField from "./GiftArrayBtnField";
import RecurringGiftField from "./RecurringGiftField";
import ThankYouPageCustomHTML from "./ThankYouPageCustomHTML";


function ReadOnlyFields({ section }) {

    // State
    const [genericFields, setGenericFields] = useState([]);
    const [readOnlyFields, setReadOnlyFields] = useState([]);
    const [componentIndex, setComponentIndex] = useState({});

    // Props.
    const FormRenderState = useSelector(state => state.FormRender);
    const ReceiptState = useSelector(state => state.Receipt);

    useEffect(() => {

        // Constant generic fields.
        const constantGenericFields = [
            'billingCity',
            'billingEmail',
            'billingFirstName',
            'billingLastName',
            'billingPhone',
            'billingState',
            'billingStreetAddress1',
            'billingStreetAddress2',
            'billingZip',
            'salutation',
            'customTextbox',
            'tributeCity',
            'tributeEmail',
            'tributeFirstName',
            'tributeLastName',
            'tributeMessage',
            'tributeRecipientEmail',
            'tributeRecipientFirstName',
            'tributeRecipientLastName',
            'tributeRecipientPhoneNumber',
            'tributeState',
            'tributeStreetAddress1',
            'tributeStreetAddress2',
            'tributeZip',
            'tributeType',
            'tributeNotificationType',
            'anonymousCheckbox',
            'companyMatchingCheckboxPlusName',
            'companyDonorCheckboxPlusName',
            'giftNotes',
            'sourceDropdown'
        ];

        const { confirmationFields } = FormRenderState;
        const sectionComponents = section ? section.components : [];

        // Set readonly fields. [Merge thankYouPage array readonly fields with confirmationFields array]
        const tempReadOnlyFields = _.union(confirmationFields, sectionComponents);
        setReadOnlyFields(tempReadOnlyFields);

        // set generic fields. [Constance generic fields + custom fields set in form]
        setGenericFields([
            ...constantGenericFields,
            ...tempReadOnlyFields.filter((o) => { return o.indexOf("custom_") === 0 })
        ]);

        // Get fields component data.
        setComponentIndex(getComponentIndex(FormRenderState));

    }, [FormRenderState, section]);

    return (
        <>
            {readOnlyFields.map((readonlyField, index) => {
                const component = componentIndex[readonlyField];
                const options = getComponentOptions(component);
                return (
                    < Fragment key={index}>
                        {genericFields.includes(component.id) &&
                            < GenericField
                                component={component}
                                options={options}
                                ReceiptState={ReceiptState}
                            />
                        }
                        {component.id === 'paymentInfo' &&
                            <PaymentInfoFields
                                component={component}
                                options={options}
                                ReceiptState={ReceiptState}
                            />
                        }
                        {component.id === 'giftArrayBtn' &&
                            <GiftArrayBtnField
                                component={component}
                                options={options}
                                ReceiptState={ReceiptState}
                            />
                        }
                        {component.id === 'recurring-gift' &&
                            <RecurringGiftField
                                component={component}
                                options={options}
                                ReceiptState={ReceiptState}
                            />
                        }
                        {component.id === 'thankYouPageCustomHTML' &&
                            < ThankYouPageCustomHTML
                                component={component}
                                options={options}
                                ReceiptState={ReceiptState}
                            />
                        }
                    </Fragment>
                )
            })}

        </>
    );

}

export default ReadOnlyFields;
import { renderString } from "../lib";
import {variablesSetValue} from "../Redux/actions/VariableActions";
import store from "../Redux/store";

window.rkd = window.rkd || {};

window.rkd.variable = window.rkd.variable || {
    set: (key, val) => {
        variablesSetValue(key, val);
    },
    get: (key) => {
        return store.getState().Variable.variables[key] || null;
    },
    setMany: (variables = {}) => {
        const keys = Object.keys(variables);
        keys.forEach((key) => {
            variablesSetValue(key, variables[key]);
        });
    },
    getMany: (keys = []) => {
        const data = {};
        keys.forEach(key => {
            data[key] = store.getState().Variable.variables[key] || null;
        });
        return data;
    },
    renderString: (string = '') => {
        renderString(string);
    }
};
import React from "react";
import { renderString } from "../../lib";

import {
    FormGroup,
    FormControl
} from "react-bootstrap";
import BaseField, {BaseFieldActionsToProps, BaseFieldStateToProps} from "./BaseField";
import {connect} from "react-redux";

class TextboxFromCheckbox extends BaseField{

    cursor;

    componentDidUpdate(prevProps, prevState, snapshot){
        if (this.state.target) {
            this.state.target.selectionStart = this.cursor;
            this.state.target.selectionEnd = this.cursor;
        }
    }

    render(){
        const errorMessage = this.getRenderedValidationMessage();
        let options = this.getOptionsArray();
        let fieldName = options.name;
        if(options.custom){
            fieldName = `dmc_custom_field_${options.name}`;
        }
        const props = {
            type: this.props.type || 'text',
            onBlur: (event) => this.onChange(event),
            defaultValue: this.props.value || renderString(this.props.FormValueState[fieldName], this.props.FormRenderState.variables),
            name: renderString(fieldName, this.props.FormRenderState.variables),
            placeholder: renderString(options.placeholder, this.props.FormRenderState.variables),
            isInvalid: errorMessage !== ''
        };

        if(typeof this.fieldType !== 'undefined'){
            props.as = this.fieldType;
        }

        return (
                <FormGroup>
                    <FormControl
                        {...props}
                        onChange={(event) => {
                            this.cursor = event.target.selectionStart;
                            this.setState({
                                target: event.target
                            });
                            if(props.onChange){
                                props.onChange(event);
                            }
                        }}
                    />
                    <div style={{color: '#F00'}} className="donation-form-field-error">{errorMessage}</div>
                </FormGroup>

        );
    }

}

export const TextboxFromCheckboxStateToProps = (state) => ({
    ...BaseFieldStateToProps(state),
    FormRenderState: state.FormRender,
    FormValueState: state.FormValue
})

export const TextboxFromCheckboxActionsToProps = {
    ...BaseFieldActionsToProps
}

export const BootstrappedTextboxFromCheckbox = connect(TextboxFromCheckboxStateToProps, TextboxFromCheckboxActionsToProps)(TextboxFromCheckbox);
export default TextboxFromCheckbox;

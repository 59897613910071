import Textbox, { TextboxActionsToProps, TextboxStateToProps } from "../BaseFields/Textbox";
import { connect } from "react-redux";

class BillingStreetAddress1 extends Textbox {


}

const mapStateToProps = (state) => ({
    ...TextboxStateToProps(state)
})

const mapActionsToProps = {
    ...TextboxActionsToProps
}

export default connect(mapStateToProps, mapActionsToProps)(BillingStreetAddress1);
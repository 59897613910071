import React, { Component } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { BootstrappedChildField as ChildField } from '../BaseFields/ChildField';
import { connect } from 'react-redux'
import { setCanSubmit } from "../../Redux/actions/FormValidationActions";
import { setFormValue } from "../../Redux/actions/FormValueActions";
import { setPaymentFailure, setError } from "../../Redux/actions/FormRenderActions";

class StripeForm extends Component {

    state = {
        cardErrorMessage: '',
        expirationErrorMessage: '',
        cvvErrorMessage: ''
    }

    componentDidMount() {
        const abstraction = window.rkd.form.getAbstractionLayer();
        abstraction.registerSubmitTrigger('stripe', () => this.submit_stripe())
        this.props.setPaymentFailure(false);
    }

    componentWillUnmount() {
        const abstraction = window.rkd.form.getAbstractionLayer();
        abstraction.deregisterSubmitTrigger('stripe');
    }

    async submit_stripe() {
        if (this.props.storedClientState.valid) {
            return;
        }

        const { stripe, elements } = this.props;
        const { error, token } = await stripe.createToken(elements.getElement(CardNumberElement));

        this.setState({
            cardErrorMessage: '',
            expirationErrorMessage: '',
            cvvErrorMessage: ''
        });

        if (typeof error !== 'undefined') {
            if (['incomplete_number', 'invalid_number', 'card_declined'].includes(error.code)) {
                this.setState({
                    cardErrorMessage: error.message
                });
            }
            if (['incomplete_expiry', 'invalid_expiry_year_past', 'invalid_expiry_month_past'].includes(error.code)) {
                this.setState({
                    expirationErrorMessage: error.message
                });
            }
            if (['incomplete_cvc'].includes(error.code)) {
                this.setState({
                    cvvErrorMessage: error.message
                });
            }
            this.props.setCanSubmit(false);
            this.props.setPaymentFailure(true);
            console.log(error);
        } else {
            console.log(token.card.last4);
            this.props.setFormValue('stripe_card', token.id);
            this.props.setFormValue('last4', token.card.last4);
            this.props.setError('');
            this.props.setPaymentFailure(false);

        }
    }

    render() {
        const { cardErrorMessage, expirationErrorMessage, cvvErrorMessage } = this.state;
        const { formattedOptions } = this.props;

        return (<div className={'row' + (this.props.FormRenderState.paymentFailure ? ' payment-failure' : '')} style={{ paddingTop: 10 }}>
            <ChildField
                templateSize='card_number_template_size'
                templateOffset='card_number_template_offset'
                formattedOptions={{
                    label: formattedOptions.ccLabel || 'Credit Card Number',
                    required: true,
                    ...this.props.formattedOptions
                }}
                error={cardErrorMessage}>
                <CardNumberElement
                    className='form-control'
                    options={{
                        placeholder: formattedOptions.ccPlaceholder || "4111 1111 1111 1111"
                    }}
                />
            </ChildField>
            <ChildField
                templateSize='expiration_template_size'
                templateOffset='expiration_template_offset'
                formattedOptions={{
                    label: formattedOptions.expireLabel || 'Exp. Date',
                    required: true,
                    ...this.props.formattedOptions
                }}
                error={expirationErrorMessage}>
                <CardExpiryElement
                    options={{
                        placeholder: formattedOptions.expirePlaceholder || "MM / YY"
                    }}
                    className='form-control'
                />
            </ChildField>
            <ChildField
                templateSize='cvv_template_size'
                templateOffset='cvv_template_offset'
                formattedOptions={{
                    label: formattedOptions.cvcLabel || 'Security Code',
                    required: true,
                    ...this.props.formattedOptions
                }}
                error={cvvErrorMessage}>
                <CardCvcElement
                    className='form-control'
                    options={{
                        placeholder: formattedOptions.cvcPlaceholder || "CVC"
                    }}
                />
            </ChildField>
        </div>);
    }

}

const mapStateToProps = (state) => ({
    storedClientState: state.StoredClient,
    FormRenderState: state.FormRender,
})

const mapActionsToProps = {
    setCanSubmit,
    setFormValue,
    setPaymentFailure,
    setError
};

export default connect(mapStateToProps, mapActionsToProps)(StripeForm);

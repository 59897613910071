import React from "react";
import { renderString } from "../../lib";

import {
    FormGroup,
    FormLabel,
    FormCheck
} from "react-bootstrap";
import BaseField, { BaseFieldActionsToProps, BaseFieldStateToProps } from "./BaseField";
import TextboxFromCheckbox from "./TextboxFromCheckbox";
import { connect } from "react-redux";

class CheckboxInput extends BaseField {

    state = {
        checked: false,
    }

    componentDidMount() {
        const options = this.getOptionsArray();
        const { FormValueState } = this.props;
        let fieldName = options.name;
        let checked = false;
        if (options.custom) {
            fieldName = `dmc_custom_field_${options.name}`;
        }
        if(typeof this.props.FormValueState[fieldName] === "undefined")
        {
            if(options.default === 'Yes')
            {
                checked = true;
            }
        }
        else if(this.props.FormValueState[fieldName] !== "")
        {
            checked = true;
        }

        this.setState({ checked });
        this.setFieldAndTriggerEvent(fieldName, typeof FormValueState[fieldName] !== 'undefined' ? FormValueState[fieldName] : "");
    }

    onCheck(event) {
        let checkedValue = event.target.checked;
        const options = this.getOptionsArray();
        let fieldName = `dmc_custom_field_${options.name}`;

        if (!checkedValue) {
            this.setFieldAndTriggerEvent(fieldName, '');

        }
        this.setState({ checked: checkedValue });
    }

    render() {
        const { FormRenderState } = this.props;
        let options = this.getOptionsArray();

        if (this.props.formattedOptions) {
            options = this.props.formattedOptions;
        } else {
            this.props.options.forEach((option) => {
                options[option.field] = option.value || option.default;
            });
        }

        const fieldRand = Math.ceil(Math.random() * 100000);

        return (
            <div className={this.getTemplateClasses() + " donation-form-checkbox"} style={this.getGeneratedStyles()}>
                <FormGroup>
                    <FormCheck
                        type='checkbox'
                        id={`checkbox_${fieldRand}`}
                        inline
                        className={this.state.checked ? "checkbox-checked" : ""}
                        onChange={(event) => this.onCheck(event)}
                        checked={this.state.checked}
                        style={{ marginRight: 0 }}
                    />
                    <FormLabel htmlFor={`checkbox_${fieldRand}`} className={this.state.checked && typeof options.validators !== 'undefined' ? options.validators.required ? 'required required-field' : '' : ''}>{renderString(options.label, FormRenderState.variables)}</FormLabel>
                </FormGroup>
                {this.state.checked &&
                    <TextboxFromCheckbox {...this.props} />
                }
            </div>
        );
    }

}

export const CheckboxInputStateToProps = (state) => ({
    ...BaseFieldStateToProps(state),
    FormRenderState: state.FormRender,
    FormValueState: state.FormValue
})

export const CheckboxInputActionsToProps = {
    ...BaseFieldActionsToProps
}

export const BootstrappedCheckboxInput = connect(CheckboxInputStateToProps, CheckboxInputActionsToProps)(CheckboxInput);
export default CheckboxInput;

import BaseSocket from "./BaseSocket";
import {getParameterByName} from "../lib";

const GlobalSettingsSocket = {
    socket: null,
    init: () => {
        if(!BaseSocket.socket){
            BaseSocket.init();
        }
        if(GlobalSettingsSocket.socket){
            return GlobalSettingsSocket.socket;
        } else {
            GlobalSettingsSocket.socket = BaseSocket.socket.subscribe(`globalSettings:${getParameterByName('cl')}`);
        }
        return GlobalSettingsSocket.socket;
    }
};

export default GlobalSettingsSocket;
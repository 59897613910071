import { Component } from "react";

import validators from "../../Libs/validators";
import { isTruthy } from "../../lib";
import { setFormIdValue } from "../../Redux/actions/FormIdValueActions";
import { setFormValue } from "../../Redux/actions/FormValueActions";
import { storedClientRegisterTrigger } from "../../Redux/actions/StoredClientActions";
import { variablesSetFieldValues } from "../../Redux/actions/VariableActions";
import { setValidator, setInvalidElements } from "../../Redux/actions/FormValidationActions";
import store from "../../Redux/store";
import _ from 'lodash'
import { setError, setGeneralErrors } from "../../Redux/actions/FormRenderActions";

class BaseField extends Component {

    constructor(props) {
        super(props);
        const options = this.getOptionsArray();

        store.dispatch(setValidator(options.name, this.validator));

        window.rkd.form.on('internal_event', (event) => this.triggerListener(event));
        if (this.state) {
            this.state.hidden = false;
        } else {
            this.state = {
                hidden: false
            }
        }
        if (this.props.hidden) {
            this.setState({
                hidden: this.props.hidden
            });
        }

        this.processHideOnStoredClient(true);
        storedClientRegisterTrigger(() => {
            this.processHideOnStoredClient();
        });
    }

    // Optimization to help speed up Safari
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.alwaysUpdate) { // The component can choose to override this functionality
            return true;
        }

        const options = this.getOptionsArray();

        if (!_.isEqual(this.state, nextState)) { // If local state is not equal, update
            return true;
        }

        const fieldName = typeof this.props.customType === "undefined" ? options.name : `dmc_custom_field_${options.name}`
        if (this.props.FormValueState[fieldName] !== nextProps.FormValueState[fieldName]) { // If the value changed, update
            return true;
        }

        return false;
    }

    processHideOnStoredClient(fromConstructor = false) {
        const options = this.getOptionsArray();
        let storedClient = false;

        if (((typeof options.hideOnStoredClient !== 'undefined' && isTruthy(options.hideOnStoredClient)) ||
            (typeof this.hideOnStoredClient !== 'undefined' && isTruthy(this.hideOnStoredClient))) &&
            this.props.StoredClient.valid) {
            storedClient = true;
        }

        if (fromConstructor) {
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.storedClient = storedClient;
        } else {
            this.setState({
                storedClient: storedClient
            });
        }
    }

    triggerListener(event) {
        const options = this.getOptionsArray();
        if (options.triggers && options.triggers.includes(event.triggerIndex)) {
            if (['hide', 'show'].includes(event.action)) {
                this.setState({
                    hidden: event.action === 'hide'
                })
            }
            this.processHideOnStoredClient();
        }
    }

    // This function will set the custom field value by default blank,
    // So it will pass for transaction without interact with custom field
    triggerOnFieldLoadInForm = (value = "") => {
        const options = this.getOptionsArray();
        if (options.custom) {
            let fieldName = options.custom ? `dmc_custom_field_${options.name}` : options.name;
            this.setFieldAndTriggerEvent(fieldName, value);
        }

    };

    onChange = (event) => {
        if (this.props.onChange) {
            this.props.onChange(event);
        } else {
            const { FormValueState, setFormValue } = this.props;
            const options = this.getOptionsArray();

            let fieldName = options.name;
            if (options.custom) {
                fieldName = `dmc_custom_field_${options.name}`;
                if (!FormValueState.customFields) {
                    setFormValue('customFields', []);
                }
                if (!FormValueState.customFields.includes(fieldName)) {
                    const customFields = [...FormValueState.customFields];
                    customFields.push(fieldName);
                    setFormValue('customFields', customFields);
                }
            }
            this.setFieldAndTriggerEvent(fieldName, event.target.value);
        }

        if (typeof this.state === 'undefined' || typeof this.state.changed === 'undefined' || !this.state.changed) {
            this.setState({
                changed: true
            })
        }
    };

    async setFieldAndTriggerEvent(name, value) {
        const options = this.getOptionsArray();

        const fieldValues = { ...this.props.VariableState.fieldValues };

        await this.props.setFormValue(name, value);
        if (value === '') {
            delete fieldValues['field_' + name];
        } else {
            fieldValues['field_' + name] = value;
        }

        this.props.variablesSetFieldValues(fieldValues);
        this.props.setFormIdValue(options.id, value);

        window.rkd.form.triggerEvent('change', {
            name,
            value
        });

        window.rkd.form.triggerEvent('internal_change', {
            field_id: options.id,
            field_value: value
        });
    }

    getGeneratedStyles() {
        const styles = {};
        if (this.state.hidden || this.state.storedClient) {
            styles.display = 'none';
        }
        return styles;
    }

    getTemplateClasses(defaultClasses = []) {

        const options = this.getOptionsArray();
        const classArray = defaultClasses || [];

        let templateSize = options.template_size;
        let templateOffset = options.template_offset;
        if (this.props.templateSize) {
            templateSize = options[this.props.templateSize];
        }

        if (this.props.templateOffset) {
            templateOffset = options[this.props.templateOffset];
        }


        if (typeof templateSize !== 'undefined') {
            Object.keys(templateSize).forEach((size) => {
                switch (size) {
                    case 'xs':
                        classArray.push(`col-${templateSize[size]}`);
                        break;
                    default:
                        classArray.push(`col-${size}-${templateSize[size]}`);
                        break;
                }
            })
        }

        if (typeof templateOffset !== 'undefined') {
            Object.keys(templateOffset).forEach((size) => {
                if (parseInt(templateOffset[size]) !== 0) {
                    switch (size) {
                        case 'xs':
                            classArray.push(`offset-${templateOffset[size]}`);
                            break;
                        default:
                            classArray.push(`offset-${size}-${templateOffset[size]}`);
                            break;
                    }
                }
            })
        }

        if (this.props.id) {
            classArray.push(this.props.id);
        }

        return classArray.join(' ');

    }

    buildValidator = () => {
        const options = this.getOptionsArray();
        if (options.validators && options.validators.length !== 0) {
            if (typeof this.state.validatorString !== 'undefined') {
                return this.state.validatorString;
            }
            const validatorParts = [];
            const { validators } = options;

            if (validators.required) {
                validatorParts.push('required')
            }
            if (validators.phone) {
                validatorParts.push('phone')
            }
            if (validators.email) {
                validatorParts.push('email')
            }
            if (validators.postal) {
                validatorParts.push('postal')
            }
            if (validators.alphaOnly) {
                validatorParts.push('alpha')
            }
            if (validators.numericOnly) {
                validatorParts.push('numeric')
            }
            if (validators.minLength) {
                if (typeof validators.minChars !== 'undefined' && validators.minChars !== 0) {
                    validatorParts.push(`minLength,${validators.minChars}`)
                } else {
                    validatorParts.push(`minLength,1`)
                }
            }
            if (validators.maxLength) {
                if (typeof validators.maxChars !== 'undefined' && validators.maxChars !== 0) {
                    validatorParts.push(`maxLength,${validators.maxChars}`)
                } else {
                    validatorParts.push(`maxLength,60`)
                }
            }
            if (validators.allowAccent) {
                validatorParts.push('allowAccent')
            }

            if (validators.allowHyphenApostrophe) {
                validatorParts.push('allowHyphenApostrophe')
            }
            const validatorString = validatorParts.join('|');
            this.setState({
                validatorString
            })
            return validatorString;
        } else if (typeof window.rkd.form.platforms[window.rkd.form.platform].validators[options.name] !== 'undefined') {
            if (typeof window.rkd.form.platforms[window.rkd.form.platform].validators[options.name] === 'function') {
                return window.rkd.form.platforms[window.rkd.form.platform].validators[options.name]();
            } else {
                return window.rkd.form.platforms[window.rkd.form.platform].validators[options.name];
            }
        } else {
            return '';
        }
    }

    isValid = () => this.getValidationMessage() === ''

    validator = () => {
        this.setState({
            changed: true
        })
        return this.getValidationMessage() === '';
    }

    getValidationMessage = () => {
        // Ignore validation If field is hidden
        const FormRenderState = store.getState().FormRender;
        const options = this.getOptionsArray();
        if (typeof this.state.hidden === 'undefined' || this.state.hidden || document.getElementsByName(options.custom ? 'dmc_custom_field_' + options.name : options.name).length === 0) {
            return '';
        }

        //Below condition validating that if step/section is hidden using trigger 
        //than don't validate the form fields which are exist into that step/section.
        if(this.props.parentIndex && (FormRenderState.skippedSteps.includes(this.props.parentIndex))) {
            return '';
        }

        const validatorList = this.buildValidator();
        if (validatorList !== '') {
            const fieldName = typeof this.props.customType === "undefined" ? options.name : `dmc_custom_field_${options.name}`;
            let invalidElements = this.props.FormValidationState.invalidElements;
            if(validators(this.props.FormValueState[fieldName], options, validatorList) !== "")
            {
                if(!invalidElements.includes(fieldName))
                {
                    invalidElements.push(fieldName);
                }
                store.dispatch(setInvalidElements(invalidElements));
                return validators(this.props.FormValueState[fieldName], options, validatorList);
            }
            else
            {
                const index = invalidElements.indexOf(fieldName);
                if (index > -1) {
                    invalidElements.splice(index, 1);
                }

                if(invalidElements.length === 0)
                {
                    store.dispatch(setError(""));
                    store.dispatch(setGeneralErrors(false));    
                }
                store.dispatch(setInvalidElements(invalidElements));
            }
        }

        return '';
    }

    getRenderedValidationMessage = () => this.state.changed ? this.getValidationMessage() : ''

    getOptionsArray = () => {
        let options = {};
        if (this.props.formattedOptions) {
            options = this.props.formattedOptions;
        } else {
            this.props.options.forEach((option) => {
                options[option.field] = option.field === 'placeholder' ? option.value : option.value || option.default;
            });
        }
        options.id = this.props.id;
        options.custom = (typeof this.props.custom !== 'undefined' && this.props.custom) ? true : false;
        return options;
    }

}

export const BaseFieldStateToProps = (state) => ({
    FormValidationState: state.FormValidation,
    FormValueState: state.FormValue,
    VariableState: state.Variable,
    StoredClient: state.StoredClient
})

export const BaseFieldActionsToProps = {
    setFormIdValue,
    setFormValue,
    variablesSetFieldValues,
    setValidator,
    setInvalidElements,
    setError
}

export default BaseField;

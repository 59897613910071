import {
    COMPONENT_INIT_SOCKET
} from "../actionTypes";


const initialState = {
    state: [],
    requestData: [],
    isDeleted: 0,
    isLoaded: false,
    random: 0
};

export default function(state = initialState, action){
    switch (action.type){
        case COMPONENT_INIT_SOCKET:
            const newState = {...state};
            newState.state[action.payload.componentId] = action.payload.state;
            newState.isLoaded = action.payload.isLoaded;
            return {
                ...newState,
                random: Math.random() // Just to force the components to re-render
            }
        default:
            return state;
    }
}
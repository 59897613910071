import {GLOBAL_SETTINGS_INIT, GLOBAL_SETTINGS_SET_PAYMENTPROVIDERS, GLOBAL_SETTINGS_SET_ERRORNATTEMPTS} from "../actionTypes";

const initialState = {
    globalSettingsState: {},
    socket: null,
    paymentProviders:{preview:{}, live:{}},
    lightboxIfDonationErrorAfterNAttempts: { enabled: false, counter: '1', attempts: '3', name: 'Lightbox-if-Donation-Error-after-n-attempts', index: -1, nLigthboxes:[], nCases:[], xCloseModalButtons:['rkd-2312-close-button', 'rkd-2313-close-button']},
    requestData: null,
};

export default function(state = initialState, action){
    switch (action.type){
        case GLOBAL_SETTINGS_INIT:
            return {
                ...state,
                ...action.payload
            }
        case GLOBAL_SETTINGS_SET_PAYMENTPROVIDERS:
            return {
                ...state,
                paymentProviders: action.payload
            }
        case GLOBAL_SETTINGS_SET_ERRORNATTEMPTS:
            return {
                ...state,
                lightboxIfDonationErrorAfterNAttempts: action.payload
            }
        default:
        return state;
    }
}
import trim from "validator/es/lib/trim";
import isEmail from "validator/es/lib/isEmail";
import isMobilePhone from "validator/es/lib/isMobilePhone";
import isPostalCode from "validator/es/lib/isPostalCode";
import isEmpty from "validator/es/lib/isEmpty";
import { getErrorMessage } from "../lib";

export default (value, options, validators) => {
    // console.log("validator", {
    //     value,
    //     type: typeof value,
    //     options,
    //     validators
    // });


    value = typeof value === "undefined" ? undefined : trim(value + '');
    const validatorList = validators.split('|');
    let i = 0;

    let isAccentAllow = validatorList.includes('allowAccent');
    let isHyphenApostropheAllow = validatorList.includes('allowHyphenApostrophe');

    while (i < validatorList.length) {
        const validatorParams = validatorList[i].split(',');
        let error = '';
        switch (validatorParams[0].toLowerCase()) {
            case 'email':
                error = email({
                    value,
                    options
                })
                break;
            case 'phone':
                console.log('validators: ', value);
                value = !validatorList.includes('required') && value === undefined ? '' : value;
                error = phone({
                    value,
                    options,
                    min: validatorParams[1],
                    max: validatorParams[2]
                })
                break;
            case 'alpha':
                error = alpha({
                    value,
                    options,
                    isAccentAllow,
                    isHyphenApostropheAllow
                })
                break;
            case 'numeric':
                error = numeric({
                    value,
                    options,
                    isAccentAllow
                })
                break;
            case 'postal':
                error = postal({
                    value,
                    options,
                    min: validatorParams[1],
                    max: validatorParams[2]
                })
                break;
            case 'required':
                error = required({
                    value,
                    options
                })
                break;
            case 'minlength':
                error = minLength({
                    value,
                    options,
                    min: validatorParams[1]
                })
                break;
            case 'maxlength':
                error = maxLength({
                    value,
                    options,
                    max: validatorParams[1]
                })
                break;
            case 'betweenlength':
                error = betweenLength({
                    value,
                    options,
                    min: validatorParams[1],
                    max: validatorParams[2]
                })
                break;
            default:

        }
        if (error !== '') {
            return error;
        }
        i++;
    }
    return '';
}

export const email = (params) => {
    if (typeof params.value === 'undefined' || (!isEmpty(params.value) && !isEmail(params.value))) {
        return getErrorMessage('must be a valid email address.', params);
    }
    return '';
}

const phone = (params) => {
    if (typeof params.value === 'undefined' || (!isEmpty(params.value) && !isMobilePhone(params.value, ['en-US', 'en-CA']))) {
        return getErrorMessage('must be a valid phone number.', params);
    }
    return '';
}

const alpha = (params) => {

    let regEx = /^[a-zA-Z ]+$/;

    if(params.isAccentAllow && params.isHyphenApostropheAllow) {
        regEx = /^[a-zA-Z \u00A0-\u024F '-]+$/;
    } else if(params.isAccentAllow) {
        regEx = /^[a-zA-Z \u00A0-\u024F]+$/;
    } else if(params.isHyphenApostropheAllow) {
        regEx = /^[a-zA-Z '-]+$/;
    }

    console.log("Final RegEx= ", regEx);
    
    if (typeof params.value === 'undefined' || (!isEmpty(params.value) && !params.value.match(regEx))) {
        return getErrorMessage('cannot contain numbers.', params);
    }
    return '';
}

const numeric = (params) => {
    let exp = !params.isAccentAllow ? /^[0-9 ]+$/ : /^[0-9 \u00A0-\u024F]+$/;
    if (typeof params.value === 'undefined' || (!isEmpty(params.value) && !params.value.match(exp))) {
        return getErrorMessage('can only contain numbers.', params);
    }
    return '';
}

const postal = (params) => {
    if (typeof params.value === 'undefined' || (!isEmpty(params.value) && !isPostalCode(params.value, 'US') && !isPostalCode(params.value, 'CA'))) {
        return getErrorMessage('must be a valid postal code.', params);
    }
    return '';
}

const required = (params) => {
    if (typeof params.value === 'undefined' || isEmpty(params.value)) {
        return getErrorMessage('is required.', params, true);
    }
    return '';
}

const minLength = (params) => {
    if (typeof params.value !== 'undefined' && (!isEmpty(params.value) && params.value.length < parseInt(params.min))) {
        return getErrorMessage(`must be more than ${params.min} characters.`, params);
    }
    return '';
}

const maxLength = (params) => {
    if (typeof params.value !== 'undefined' && (!isEmpty(params.value) && params.value.length > parseInt(params.max))) {
        return getErrorMessage(`must be less than ${params.max} characters.`, params);
    }
    return '';
}

const betweenLength = (params) => {
    const minLengthError = minLength(params);
    const maxLengthError = maxLength(params);

    if (minLengthError !== '') {
        return minLengthError;
    }

    if (maxLengthError !== '') {
        return maxLengthError;
    }

    return '';
}

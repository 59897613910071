import React, { Component } from 'react';
import { PlaidLink } from 'react-plaid-link';
import { getParameterByName } from "../../lib";
import Spinner from "react-bootstrap/Spinner";
import isEmail from "validator/es/lib/isEmail";
import {connect} from 'react-redux';
import {setCanSubmit} from "../../Redux/actions/FormValidationActions";
import {setFormValue} from "../../Redux/actions/FormValueActions";

class StripeACHPayForm extends Component {

    state = {
        achToken: '',
        accountLinked: false
    };

    getACHToken = async () => {
        const {FormRenderState, FormValueState} = this.props;
        console.log("Inside the Plaid ACH Token");
        if(!this.state.achToken){
            if (FormValueState.billingEmail) {
                const client = getParameterByName("cl");
                const settings = FormRenderState.paymentProviders.ach.name;
                const abstraction = window.rkd.form.getAbstractionLayer();
                const token = await abstraction.loadACHToken(client, FormValueState.billingEmail, settings);
                this.setState({ achToken: token });
            } else {
                this.props.setCanSubmit(false);
            }
        }
    }

    handleOnSuccess = (public_token, metadata) => {
        const {setFormValue} = this.props;
        setFormValue('stripe_ach_token', public_token);
        setFormValue('stripe_ach_account_id', metadata.account_id);
        this.setState({
            accountLinked: true
        })
    }

    handleOnExit = (err, metadata) => { }

    render() {
        const {FormValueState} = this.props;
        const {accountLinked, achToken} = this.state;

        if(typeof FormValueState.billingEmail !== 'undefined' && isEmail(FormValueState.billingEmail)){
            if(accountLinked){
                return (
                    <div className='col-12'>
                        <div className='alert alert-success'>We will use your bank account to process this donation.</div>
                    </div>
                );
            } else {
                this.getACHToken();
                return (<>
                    <div className='col-12'>
                        {achToken === '' && <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>}
                        {achToken !== '' &&
                        <PlaidLink
                            token={achToken}
                            onExit={this.handleOnExit}
                            onSuccess={this.handleOnSuccess}
                            style={{ margin: '2% 20%', fontSize: '20px', color: '#fff', outline: 'none', background: '#28a745', borderColor:'#28a745', width:'55%', border: '2px solid rgb(93 93 93 / 1)', borderRadius: '6px' }}
                        >
                            Connect a bank account
                        </PlaidLink>
                        }
                    </div>
                </>);
            }
        } else {
            return (
                <div className='col-12'>
                    <div className='alert alert-danger'>You must fill out the billing email field to use ACH.</div>
                </div>
            );
        }
    }

}

const mapStateToProps = state => ({
    FormRenderState: state.FormRender,
    FormValueState: state.FormValue
});

const mapActionsToProps = {
    setCanSubmit,
    setFormValue
}

export default connect(mapStateToProps, mapActionsToProps)(StripeACHPayForm);

import React from "react";

function GenericField({ component, options, ReceiptState }) {
    const { id: componentID } = component;
    const { label: fieldLabel, name, custom: isCustomField } = options;
    const { data: ReceiptData } = ReceiptState;
    let fieldValue = !isCustomField ? ReceiptData[name] : (ReceiptData?.customFields ? ReceiptData.customFields[name] : null);
    fieldValue = typeof fieldValue === "string" ? fieldValue.trim() : fieldValue;
    const styles = {
        parentDiv: {
            display: fieldValue ? "flex" : "none",
            padding: 10
        },
        labelDiv: {
            fontWeight: 'bold'
        }
    };
    return (
        <div className={"row TYP_" + componentID} style={styles.parentDiv}>
            <div className={"col-6 TYC_Label_" + componentID} style={styles.labelDiv}>
                {fieldLabel}
            </div>
            <div className={"col-6 TYC_Value_" + componentID}>
                {fieldValue}
            </div>
        </div>
    );
}

export default GenericField;
export const getGAID = async() => {
    return new Promise((resolve, reject) => {
        let gaid = '';
        let gaIntervalCount = 0;

        try {
            const waitGA = setInterval(() => {
                gaIntervalCount++;

                if (window.ga && window.ga.loaded) {
                    window.ga((tracker) => {
                        const trackerList = window.ga.getAll();

                        if (trackerList && trackerList.length !== 0) {
                            gaid = trackerList[0].get("clientId");
                            clearInterval(waitGA);
                            console.log(`FB - getGAID() - gaid: ${gaid}`);
                            resolve(gaid);
                        }else{
                            resolve(null);
                        }
                    });
                }

                if (gaIntervalCount > 50) {
                    console.log("FB - getGAID() - Timeout: Exceeded maximum attempts.");
                    clearInterval(waitGA);
                    resolve(null);
                }
            }, 200);
        } catch (error) {
            console.error(`FB - getGAID() - Error: ${error.message}`);
            reject(error);
        }
    });
};

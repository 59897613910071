import {FORM_VALIDATION_SET_CAN_SUBMIT, FORM_VALIDATION_SET_ITEM, FORM_VALIDATION_SET_VALIDATOR, FORM_VALIDATION_SET_INVALIDELEMENTS} from "../actionTypes";

export function setValidationInvalid(key, message){
    return {
        type: FORM_VALIDATION_SET_ITEM,
        payload: {
            item: key,
            value: {
                valid: true,
                message: message
            }
        }
    }
}

export function setValidationValid(key) {
    return {
        type: FORM_VALIDATION_SET_ITEM,
        payload: {
            item: key,
            value: {
                valid: true,
                message: ''
            }
        }
    }
}

export function setCanSubmit(canSubmit) {
    return {
        type: FORM_VALIDATION_SET_CAN_SUBMIT,
        payload: canSubmit
    }
}

export function setValidator(key, value){
    return {
        type: FORM_VALIDATION_SET_VALIDATOR,
        payload: {
            key, value
        }
    }
}

export function setInvalidElements(invalidElements){
    return {
        type: FORM_VALIDATION_SET_INVALIDELEMENTS,
        payload: invalidElements
    }
}

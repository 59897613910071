import React, { Component } from 'react';
import DonationForm from "./Parts/DonationForm";
import ComponentViewer from "./Parts/ComponentViewer"
import { BrowserRouter, HashRouter, Route } from "react-router-dom";
import { getParameterByName } from '../lib'
import Page from "./Parts/Page";
import {connect} from 'react-redux'

class MainView extends Component{

    render() {
        const routes = (
            <>
                <Route path='/form'>
                    <DonationForm />
                </Route>
                <Route path='/component'>
                    <ComponentViewer componentId={getParameterByName('c')} />
                </Route>
                <Route path='/page'>
                    <Page />
                </Route>
            </>
        );

        if(window.rkdFormEmbeded){
            return (
                <HashRouter>
                    {routes}
                </HashRouter>
            );
        }else{
            return(
                <BrowserRouter>
                    {routes}
                </BrowserRouter>
            );
        }
    }

}

export default connect()(MainView);